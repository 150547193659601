import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedInfo } from './shared-info';



@Injectable()
export class DashboadDataService {

  constructor(
    public sharedInfo: SharedInfo,
    private http: HttpClient) {
  }


  getDashboardTotalizer(): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.serviceUrl + '/rpt/dashboardTotalizer');
  }



}
