

import { Component, Output, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';

import { DocumentService } from 'src/app/dashboard-sign/views/document/document.service';
import { DomainNotification } from '../../../../models/domainNotification';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpRequest, HttpClient, HttpEventType } from '@angular/common/http';
import { switchMap, debounceTime, tap, finalize, distinctUntilChanged, catchError, map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { fail } from 'assert';
import { UserResumeViewModel } from 'src/app/models/user-model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { methodOptions, SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';



@Component({
  selector: 'app-signer-add-edit',
  templateUrl: './signer-add-edit.component.html',
  styleUrls: ['./signer-add-edit.component.scss']
})
export class SignerAddEditComponent implements OnInit {


  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onCancelClick = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onSaved = new EventEmitter<any>();

  @Input()
  document: any;


  public formGroug: FormGroup;
  formErrors: DomainNotification[] = [];

  public isLoading = false;

  signerUser: UserResumeViewModel = new UserResumeViewModel();

  searching = false;
  searchFailed = false;

  subjectUsers: Subject<any> = new Subject();
  isLoadingUsers = false;
  filteredUsers: any[] = [];



  methodOptions = methodOptions;
  method: SignatureMethod = SignatureMethod.DigitalCertificate;



  constructor(private http: HttpClient, public dataService: DocumentService, private formBuilder: FormBuilder) {



    this.formGroug = this.formBuilder.group({
      file: [null],
      fileName: [''],
      userIdSign: [''],
    });


  }

  ngOnInit(): void {
    // this.fileInput.nativeElement.click();

    this.subjectUsers
    .pipe(debounceTime(800))
    .subscribe((value) => {
      if (value.length >= 2) {
        this.isLoadingUsers = true;
        this.dataService.getUsersResume(value).subscribe(ret => {
          this.isLoadingUsers = false;
          this.filteredUsers = ret
        }, err => { this.isLoadingUsers = false });
      }
    });

  }

  getUsers(event: any) {
    this.subjectUsers.next(event.target.value);
    // var inp = String.fromCharCode(event.keyCode);
    // if (/[a-zA-Z0-9-_ ]/.test(inp)) {
    //   let searchTerm = '';
    //   searchTerm += event.target.value;
    //   this.subjectUsers.next(searchTerm);
    // }
  }

  userSelected(event: MatAutocompleteSelectedEvent ) {
    //this.data.personId = event.option.value.id;
  }

  userDisplayFn(item: UserResumeViewModel) {
    if (item) { return item.name; }
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      
    }
  }

  close() {
    this.onCancelClick.emit(null);
  }


  docUploaded(event) {
    console.log('event: ', event);
  }


  addSigner(){

    if(this.document == null) {
      this.onSaved.emit({user: this.signerUser, method: this.method});
      return;
    }

    var data = {
      method: this.method,
      publicDocumentId: [ this.document.id ],
      Signers: [
        {
          userId: this.signerUser.id,
          userCPF: this.signerUser.cpf,
          userName: this.signerUser.name,
          userEmail: this.signerUser.email,
          userTitle: this.signerUser.signTitle
        }
      ],
    };

    this.dataService.insertSigner(data).subscribe(ret=>{

      this.onSaved.emit(ret.data);

    }, fail => {
      if (fail.error.errors) {
        this.formErrors = fail.error.errors;
      }
    });

  }

}
