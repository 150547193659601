<nav class="app-sidebar">

  <section class="sidebar-header" [style.width.px]="navWidth">
    <!-- <span class="logo-icon material-icons">view_compact</span> -->

    <div class="v-center">
      <img class="logo-img" src="{{ isExpanded ? 'assets/img/logo_small.png' : 'assets/img/logo_small_min.png' }}"
        alt="">
    </div>

    <!-- s
    <div>
      <img class="logo-img"
        src="{{ ['11','31'].indexOf(AppConfig.colorOption) >= 0 || AppConfig.theme == 'dark' || AppConfig.theme == 'gray' ? 'assets/images/logo_small_white.svg' : 'assets/images/logo_small.svg' }}"
        alt="">
    </div> -->

    <!-- <a [routerLink]="['/']" class="brand">{{AppConfig.brand}}</a> -->

    <!-- <a href="javascript:;" class="collapsednav-toggler" (click)="toggleCollapsedNav()">
      <i class="material-icons" *ngIf="this.AppConfig.navCollapsed">radio_button_unchecked</i>
      <i class="material-icons" *ngIf="!this.AppConfig.navCollapsed">radio_button_checked</i>
    </a> -->

  </section>

  <section class="sidebar-content">

    <mat-nav-list>

      <!-- <div class="menu-item">
        <a mat-list-item [routerLink]="['/home']">
          <span class="menu-label" *ngIf="isExpanded">Dashboard</span>
          <mat-icon mat-list-icon class="dash-icon">widgets</mat-icon>
        </a>
      </div> -->


      <div class="menu-item" *ngFor="let menu of menulist" [ngClass]="{'activeRoute': menu.activeRoute}"
        [ngStyle]="{'background-color': menu.selected == true || activeRoute ? backLinkColorActive : ''}">

        <mat-list-item (click)="navigate(menu)" class="parent"
          [ngStyle]="{'color': menu.selected == true || menu.activeRoute == true ? linkColorActive : ''}">
          <span class="top-menu-label" *ngIf="isExpanded">{{menu.label}}</span>
          <mat-icon mat-list-icon class="dash-icon">{{menu.icon}}</mat-icon>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : menu.selected}"
            *ngIf="isExpanded && menu.itens.length > 0">expand_more</mat-icon>
        </mat-list-item>

        <div class="submenu" [ngClass]="{'expanded' : menu.selected}">
          <div *ngIf="menu.selected">
            <mat-list-item class="submenu-item" *ngFor="let menuItem of menu.itens" (click)="navigate(menuItem)">
              <i class="material-icons menu-button">keyboard_arrow_right</i>
              <span class="menu-label" *ngIf="isExpanded">{{menuItem.label}}</span>
            </mat-list-item>
          </div>

        </div>

      </div>


    </mat-nav-list>


  </section>

  <section class="sidebar-footer">

    <mat-nav-list>
      <a mat-list-item target="_blank" [href]="AppConfig.productLink">
        <mat-icon mat-list-icon style="font-size: 22px !important;">help</mat-icon>
        <span class="nav-text" *ngIf="isExpanded">Ajuda <span style="font-size: 9px; margin-left: 30px;"> v{{environment.VERSION}}</span></span>
      </a>


    </mat-nav-list>


  </section>
</nav>