
export enum PrepareDocumentMode {
    Normal = 0,
    DivideInOneDocumentPerPage = 1
}

export enum SignatureMethod {
    DigitalCertificate = 0,
    Eletronic = 1
}


export const methodOptions = [
    {
        method: SignatureMethod.DigitalCertificate,
        icon: 'verified'
    },
    {
        method: SignatureMethod.Eletronic,
        icon: 'rate_review'
    },
];






export class PublicDocument {
    urlDocument:            string;
    fileNameDocument:       string;
    base64Img:              null;
    urlPreparedDocument:    string;
    urlPublicDocument:      null;
    fileNameSignedDocument: null;
    base64ImgSigned:        null;
    signatureComplete:      boolean;
    allowedSignatureId:     null;
    signatures:             Signature[];
    erpId:                  string;
    userId:                 string;
    dateUploaded:           string;
    lastSignature:          null;
    totalSigners:           number;
    totalSignatures:        number;
    datePublish:            null;
    requestedByEmail:       boolean;
    publisherUserName:      null;
    validateChars:          string;
    name:                   string;
    md5:                    string;
    printProtocolPage:      boolean;
    id:                     string;
}

export class Signature {
    id:             string;
    userId:         string;
    userName:       string;
    userEmail:      string;
    signTitle:      null | string;
    documentNumber: string;
    dateSigned:     null;
    method:         SignatureMethod;
    jsonFields:     string;
}
