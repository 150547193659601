import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardPageRoutingModule } from './dashboard-routing.module';

import { DashboardPage } from './dashboard.page';
//import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
//import { SharedModule } from '../shared-modules';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../../app/material.module';
import { SharedModule } from '../shared-modules';
import { HomePageComponent } from './home-page/home-page.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { ViewComponent } from './views/view/view.component';
import { ProductDataService } from './views/stock/product-add-edit/product.service';
import { GroupDataService } from './views/stock/group-add-edit/group.service';
import { StockMovementDataService } from './views/stock/product-stock-add-edit/stock-mov.service';
import { OrderDataService } from './views/commercial/order-add-edit/order.service';
import { UserPermissionService } from './views/user/user-permission/user-permission.service';
import { PersonDataService } from './views/catalog/person/person-add-edit/person.service';
import { CityDataService } from './views/catalog/city/city.service';
import { GroupAddEditComponent } from './views/stock/group-add-edit/group-add-edit.component';
import { ProductAddEditComponent } from './views/stock/product-add-edit/product-add-edit.component';
import { StockMovementAddEditComponent } from './views/stock/product-stock-add-edit/stock-mov-add-edit.component';
import { OrderAddEditComponent } from './views/commercial/order-add-edit/order-add-edit.component';
import { PersonAddEditComponent } from './views/catalog/person/person-add-edit/person-add-edit.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HeaderComponent } from './header/header.component';
import { ModalOverlayComponent } from '../comp/modal-overlay/modal-overlay.component';
import { FormEditDirective } from '../comp/modal-overlay/form-container.directive';
import { InvoiceDataService } from './views/financial/invoice/invoice.service'
import { ReceivableDataService } from './views/financial/receivable/receivable.service'
import { TaskProcessService } from './views/process/taskprocess/taskprocess.service'
import { TimelineModule } from "angular2-timeline";
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from '../services/configs/customCurrencyMaskConfig';
import { ViewReceivableComponent } from './views/financial/receivable/view-receivable/view-receivable.component';
import { ViewTaskProcessComponent } from './views/process/taskprocess/view-taskprocess/view-taskprocess.component';
import { AddEditReceivableComponent } from './views/financial/receivable/add-edit-receivable/add-edit-receivable.component';
import { AddEditTaskProcessComponent } from './views/process/taskprocess/add-edit-taskprocess/add-edit-taskprocess.component';
import { OrderPaymentModalComponent } from '../comp/order-payment-modal/order-payment-modal.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';

import { UserPermissionEditComponent } from './views/user/user-permission/userPermission.component';

import { SendCertificateComponent } from './profile-page/send-certificate/send-certificate.component';

import { ChangeUserClientComponent } from './views/user/change-userclient/change-userclient.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  imports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    DashboardPageRoutingModule,
    SharedModule,
    MaterialModule,
    TimelineModule,

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [
    DashboardPage, 
    HomePageComponent,
    SidenavMenuComponent,
    ViewComponent,
    ModalOverlayComponent,
    FormEditDirective,

    GroupAddEditComponent,
    ProductAddEditComponent,
    StockMovementAddEditComponent,
    OrderAddEditComponent,

    PersonAddEditComponent,
    UserPermissionEditComponent,

    HeaderComponent,


    ViewReceivableComponent,
    ViewTaskProcessComponent,

    AddEditReceivableComponent,
    AddEditTaskProcessComponent,
    ProfilePageComponent,

    SendCertificateComponent,
    ChangeUserClientComponent,

    


  ],

  entryComponents: [
    OrderPaymentModalComponent, SendCertificateComponent, ChangeUserClientComponent
  ],

  providers:[
    ProductDataService,
    GroupDataService,
    StockMovementDataService,
    OrderDataService,
    PersonDataService,
    CityDataService,
    InvoiceDataService,
    ReceivableDataService,
    TaskProcessService,
    UserPermissionService
  ]
})
export class DashboardPageModule {}
