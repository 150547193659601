
export interface UserRequirementData {
    active: boolean;
    module: ClaimRequirementModule;
    childs: RequirementChild[];
}

export interface RequirementChild {
    active: boolean;
    type: ClaimRequirement | null;
    actions: RequirementAction[];
}

export interface RequirementAction {
    active: boolean;
    action: RequirementValue | null;
}




export enum ClaimRequirementModule {
    Catalog = 0,
    Stock = 1,
    Commercial = 2,
    Financial = 3,
    Signature = 4,
    Process = 5,
    Adm = 99
}

export enum ClaimRequirement {
    Person = 0,
    Product = 1,
    ProductGroup = 2,
    StockMov = 3,
    Order = 4,
    DebitCenter = 5,
    Invoice = 6,
    Receivable = 7,
    PublicDocument = 8,
    Taskprocess = 9,
    NFetax = 50
}

export enum RequirementValue {
    Read = 1,
    Write = 2,
    Delete = 3
}