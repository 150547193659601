import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from '../../../../services/shared-info';
import { UserService } from '../../../../services/user-service';
import { Observable } from 'rxjs';
import * as moment from 'moment';


let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class InvoiceDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('invoice', 
      { 
        startDate: new Date(startDate.setMonth(startDate.getMonth()-1)),
        endDate:  endDate,
      }, sharedInfo, userService, http, { add: false, fetch: true, timeLimeMode: true });

    this.routerTitle = 'Faturas de Receitas/Despesas';
  }


  getColums() {
    return [
      { columnDef: 'code', header: 'Código', cell: (row) => `${row.code}` },
      { columnDef: 'operationDate', header: 'Data', cell: (row) => `${row.operationDate}` },
      { columnDef: 'personBilledName', header: 'Pessoa', cell: (row) => `${row.personBilledName}%` },

      { columnDef: 'operation', header: 'Operação', cell: (row) => `${row.operation}%` },
      
      { columnDef: 'source', header: 'Origem', cell: (row) => `${row.source}%` },

      { columnDef: 'totalInstallments', header: 'Parcelas', cell: (row) => `${row.totalInstallments}%` },
      { columnDef: 'totalValue', header: 'Valor Total', cell: (row) => `${row.totalValue}%` },

     
      
    ];;
  }

}
