import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user-service';
import { MenuItem, MenuSubItem } from '../../models/menu-model';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedInfo } from '../../services/shared-info';
import { environment } from 'src/environments/environment';
import { ClaimModel } from 'src/app/models/user-model';
import { ClaimRequirementModule, ClaimRequirement } from 'src/app/models/claim-requirements-models';
import { LoginDataService } from 'src/app/services/login-service';


@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})

export class SidenavMenuComponent implements OnInit {

  loading = false;

  environment = environment;

  public AppConfig: any;
  menulist: MenuItem[] = [];

  @Input()
  isExpanded: boolean = false;

  @Input()
  navWidth: number = 220;

  @Output()
  public onOptionClicked = new EventEmitter<any>();

  linkColor: string = "#a1a1a1";
  linkColorActive: string = "#fafafa";

  backLinkColorActive: string = "rgba(0, 0, 0, 0.05)";

  backLinkColorActiveSubItem = "rgba(0, 0, 0, 0.08)";

  constructor(public userService: UserService,
    private loginDataService: LoginDataService,
    public translate: TranslateService,
    private router: Router,
    public sharedInfo: SharedInfo) {

      this.AppConfig = sharedInfo.AppConfig;

      this.router.events.subscribe(event => {

        if (event instanceof NavigationEnd) {
          this.AppConfig = this.sharedInfo.AppConfig;
          this.setColorItem(event.url);
        }
        else {
          return;
        }
  
        this.setColorlink();
      });
  }

  navigate(menu){
    if(menu.routerLink){
      this.router.navigate([menu.routerLink]);
      this.onOptionClicked.emit(menu);
    } else {
      this.toggleOptionMenu(menu);
    }
    
  }

  setColorlink(){
    if(['31','32','33','34','35','36'].indexOf(this.AppConfig.colorOption) > -1){
      this.linkColor = "rgba(0, 0, 0, 0.87)";
      this.linkColorActive = "#2196F3";
      this.backLinkColorActive = "rgba(0, 0, 0, 0.05)";
      this.backLinkColorActiveSubItem = "rgba(0, 0, 0, 0.08)";
    }
    else {
      this.linkColor = "#a1a1a1";
      this.linkColorActive = "#fafafa";
      this.backLinkColorActive = "#212529";
      this.backLinkColorActiveSubItem = "rgb(79 79 79 / 50%)";
    }
  }

  setColorItem(url: string) {

    setTimeout(() => {
      
      this.menulist.forEach(element => {

        element.activeRoute = (url == element.routerLink);
        element.selected = (url == element.routerLink);

        element.itens.forEach(subItem => {
          if (url == subItem.routerLink) {
            element.activeRoute = true;
            element.selected = true;
            subItem.activeRoute = true;
            subItem.selected = true;
          } else {
            subItem.activeRoute = false;
          }
        });
  
      });
    }, 200);

    
  }

  toggleOptionMenu(item){
    var selected = !item.selected;

    this.menulist.forEach(element => {
      element.selected = false;
    });

    if(item.itens.length > 0){
      item.selected = selected;
    } else {
      item.selected = true;
    }
  }

  ngOnInit() {
      setTimeout(async () => {
      
      if (this.userService.userLoged) {

        this.menulist.push(this.setMenuRotes(new MenuItem("dashboard","view")));

        var pdv = new MenuItem("app","pdv", "apps", "", "Aplicativos");
        pdv.itens.push(this.setMenuItemRotes(new MenuSubItem("pdv", "view", "shopping_cart", "pdv/home", "Acessar PDV")));
        pdv.itens.push(this.setMenuItemRotes(new MenuSubItem("signature", "view", "task", "signature/document", "Assinar Documentos")));
        this.menulist.push(this.setMenuRotes(pdv));


        var userClaims = (await this.getUserClaims());
        if(!userClaims) {
          userClaims = JSON.parse(JSON.stringify(this.userService.userLoged.claims)) as ClaimModel[] ;
        }

        var RQC = userClaims.find(i=>i.type == 'RQC');

        var claims: MenuItem[] = [];

        if (RQC) {
          var permissions = JSON.parse(RQC.value);

          permissions.forEach(module => {
            if (module.M == ClaimRequirementModule.Catalog
              || module.M == ClaimRequirementModule.Stock
              || module.M == ClaimRequirementModule.Commercial
              || module.M == ClaimRequirementModule.Financial
              || module.M == ClaimRequirementModule.Process) {
                var cl:MenuItem = new MenuItem(module.M, '');
                cl.itens = [];

                module.C.forEach(child => {
                  var meItem = new MenuSubItem(child.T, '');
                  cl.itens.push(this.setMenuItemRotes(meItem));
                });

                this.menulist.push(this.setMenuRotes(cl))

            }
          });

        }


  
        console.log(this.menulist);

        this.setColorItem(this.router.url);

      }

    }, 200);
  }

  getUserClaims(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.loginDataService.getUserClaims('').subscribe(response => {
        this.loading = false;
        if (response && response.success == true) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      }, err => { 
        this.loading = false;
        resolve(null);
       });
    });
  }



  setMenuRotes(item: MenuItem) {
    var typeValue = item.type;

    switch (typeValue) {

      case 'dashboard':
      {
        item.icon = 'widgets';
        item.label = 'Dashboard';
        item.routerLink = '/home';
        break;
      }

      case ClaimRequirementModule.Process:
        {
          item.icon = 'checklist';
          item.label = this.translate.instant('process.view');
          break;
        }


      case ClaimRequirementModule.Commercial:
        item.icon = 'insert_invitation';
        item.label = this.translate.instant('commercial.view')
        break;

      case ClaimRequirementModule.Catalog:
        item.icon = 'folder_open';
        item.label = this.translate.instant('catalog.view')
        break;

      case ClaimRequirementModule.Stock:
        item.icon = 'devices';
        item.label = this.translate.instant('stock.view')
        break;

      case ClaimRequirementModule.Financial:
        item.icon = 'attach_money';
        item.label = this.translate.instant('financial.view')
        break;


      default:
        break;
    }

    return item;
  }


  setMenuItemRotes(item: MenuSubItem) {
    var typeValue = item.type;

    switch (typeValue) {

      case ClaimRequirement.Taskprocess:
      {
        item.icon = '';
        item.label = this.translate.instant('process.taskprocess');
        item.routerLink = '/process/taskprocess';
        break;
      }

          
      case ClaimRequirement.Person:
        {
          item.icon = '';
          item.label = this.translate.instant('catalog.persons');
          item.routerLink = '/app/person';
          break;
        }

      case ClaimRequirement.Product:
        {
          item.icon = '';
          item.label = this.translate.instant('stock.products');
          item.routerLink = '/app/product';
          break;
        }

      case ClaimRequirement.ProductGroup:
        {
          item.icon = '';
          item.label = this.translate.instant('stock.groups');
          item.routerLink = '/app/productGroup';
          break;
        }
      case ClaimRequirement.StockMov:
        {
          item.icon = '';
          item.label = this.translate.instant('stock.products_stock_mov');
          item.routerLink = '/app/stockMovement';
          break;
        }

      case ClaimRequirement.Order:
        {
          item.icon = '';
          item.label = this.translate.instant('commercial.orders');
          item.routerLink = '/app/order';
          break;
        }

      case ClaimRequirement.Receivable:
        {
          item.icon = '';
          item.label = this.translate.instant('financial.receivable');
          item.routerLink = '/invoice/receivable';
          break;
        }

      case ClaimRequirement.Invoice:
        {
          item.icon = '';
          item.label = this.translate.instant('financial.invoice');
          item.routerLink = '/app/invoice';
          break;
        }

      default:
        break;
    }

    return item;
  }

}
