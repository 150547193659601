import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignFileComponent } from '../sign-file/sign-file.component';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user-service';
import { SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import { SignFileEletronicComponent } from '../sign-file-eletronic/sign-file-eletronic.component';


@Component({
  selector: 'app-details-document',
  templateUrl: './details-document.component.html',
  styleUrls: ['./details-document.component.scss'],
  animations: [SharedAnimations]
})
export class DetailsDocumentComponent implements OnInit {

  docId = '';
  documentData: any;
  currentUserNotSigned = false;

  base64Img: any;

  base64ImgSigned: any;

  // tslint:disable-next-line:max-line-length
  urlDocument;
  urlPublicDocument;

  displayedColumns = ['documentNumber', 'dateSigned', 'method', 'userName', 'cmd'];

  @ViewChild('#frameDoc') frameDoc;


  constructor(public dataService: DocumentService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    public modalService: NgbModal,
    public dialog: MatDialog,) {




    this.docId = this.route.snapshot.params['id'] || '';

    this.loadData();


    this.route
      .queryParams
      .subscribe(params => {
        // const docId = params['id'] || '';

      });


  }


  loadData() {
    this.dataService.getPortalSignDetails(this.docId).subscribe((doc) => {
      this.documentData = doc.data || {};
      this.requestSignature();
    });
  }



  requestSignature() {
    if ((this.documentData.signatures as Array<any>).find(i => i.userId === this.dataService.userService.userLoged.id) == null) {
      this.currentUserNotSigned = true;
    }

    this.base64Img = 'data:image/jpeg;base64,' + this.documentData.base64Img;

    this.base64ImgSigned = 'data:image/jpeg;base64,' + this.documentData.base64ImgSigned;


    this.urlDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentData.urlDocument);

    if (this.documentData.urlPublicDocument !== '') {
      this.urlPublicDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentData.urlPublicDocument);
    }

  }

  openLink(url) {
    // this.router.navigateByUrl(url);
    window.open(url, '_blank');
  }


  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return blob;
  }

  ngOnInit() {

    this.userService.userLoged.certificateFileName

  }



  signDocumentOnServer(userSigInfo) {
    this.dataService.signDocumentOnLine(userSigInfo.userId, this.documentData.id, userSigInfo.id, null, '', SignatureMethod.DigitalCertificate).subscribe(ret => {
      if (ret.success === true) {
        this.documentData = ret.data;

        this.requestSignature();

      }
    }, error => {

      const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.key === '') {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }


    });
  }


  publishDocument() {
    this.dataService.publishDocumentOnLine(this.documentData.id).subscribe(ret => {
      if (ret.success === true) {
        this.loadData();

      }
    }, error => {

      const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.key === '') {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }


    });
  }


  deleteDocument() {

    this.dataService.deleteDocumentOnLine(this.documentData.id).subscribe(ret => {
      if (ret.success === true) {
        this.router.navigateByUrl('/signature/document');

      }
    }, error => {

      const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.value) {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível excluir', 'error');
      }


    });



  }

  addSigner(userSigInfo) {
    this.dialog.open(userSigInfo);
  }

  onAddSigner(dataFromAPI) {
    console.log('dataInsertedFromAPI:', dataFromAPI);
    this.loadData();
    this.dialog.closeAll();
  }

  removeSigner(id) {
    this.dataService.deleteSigner(this.documentData.id, id).subscribe(ret => {
      this.loadData();
    });
  }

  signDoc(userSigInfo) {

    var documentList = [
      {
        erpId: this.documentData.erpId,
        publicDocumentId: this.documentData.id,
        documentName: this.documentData.name,
        signatureId: userSigInfo.id,
        userId: userSigInfo.userId,
        documentNumber: userSigInfo.documentNumber,
        email: userSigInfo.email,
        printProtocolPage: this.documentData.printProtocolPage
      }];


      if(userSigInfo.method == SignatureMethod.DigitalCertificate) {

        if (this.userService.userLoged.certificateFileName && userSigInfo.id == this.userService.userLoged.id) {
          this.signDocumentOnServer(userSigInfo);
        }
        else {
          this.signDocument(documentList);
        }

      } else {
        this.signFileEletronicComponent(documentList);
      }




  }

  signDocument(documentList) {
    const modalRef = this.modalService.open(SignFileComponent, { size: 'lg', backdrop: 'static' });

    modalRef.result.then((result) => { }, (reason) => { });

    modalRef.componentInstance.documentList = documentList;
    modalRef.componentInstance.onSaved.subscribe((resp) => {
      this.loadData();
      modalRef.close();
    });
    modalRef.componentInstance.onCancelClick.subscribe(() => {
      modalRef.close();
    });
  }


  signFileEletronicComponent(documentList) {
    const modalRef = this.modalService.open(SignFileEletronicComponent, { size: 'lg', backdrop: 'static' });
    
    modalRef.result.then((result) => { }, (reason) => { });

    modalRef.componentInstance.documentList = documentList;
    modalRef.componentInstance.onSaved.subscribe((resp) => {
      this.loadData();
      modalRef.close();
    });
    modalRef.componentInstance.onCancelClick.subscribe(() => {
      modalRef.close();
    });
  }



  sendEmailDoc(sendDocumentDialog) {
    this.dialog.open(sendDocumentDialog);
  }

  onSendDocumentSigned(event) {
    Swal.fire('Pronto...', 'E-mail enviado com sucesso!', 'success');
    this.loadData();
    this.dialog.closeAll();
  }

  sendRequestSignatures() {

    this.dataService.requestSignatureFromEmail(this.documentData.id, null, '', 'Assinar Documento', '').subscribe(ret => {

      if (ret.success === true) {
        Swal.fire('Feito', 'As solicitações foram enviadas por E-mail<BR>', 'success');
      }

    }, fail => {

    });

  }



}
