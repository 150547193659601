import { Pipe, PipeTransform } from '@angular/core';
import { DomainNotification } from '../models/domainNotification';


@Pipe({ name: 'findErrors' })
export class NotificationPipe implements PipeTransform {
  transform(categories: DomainNotification[], searchKey: string): any {

    if (searchKey == null) { return null; }

    if (searchKey) {

      searchKey = (searchKey || '').toLowerCase();

      const retItem = categories.find(i => (i.key || '').toLowerCase() == searchKey);

      // console.log('searchKey_Ret:', retItem);

      return retItem != null ? retItem.value : null;
    }

    // console.log('searchKey:', searchKey);
    // return categories.filter(function (category) {
    //   return category.key.toLowerCase().indexOf(searchKey.toLowerCase()) > -1;
    // })




  }
}
