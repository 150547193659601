import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticateUserModel } from 'src/app/models/user-model';
import { LoginDataService } from 'src/app/services/login-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  loading: boolean = false;
  userData: AuthenticateUserModel;
  

  constructor(public loginDataService: LoginDataService, public userService: UserService,  public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.loadUserData();
   

  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  loadUserData() {
    this.loading = true;
    this.loginDataService.getUserData(this.userService.userLoged.id).subscribe(response => {
      this.loading = false;
      if(response && response.success == true){
        this.userData = response.data;
      }

    }, err=>{ this.loading = false; });
  }


  onSendCertificateFile(event) {
    this.loadUserData();
  }

  saveMainData(){

    var body = {
      id: this.userData.id,
      email: this.userData.email,
      name: this.userData.name,
      cpf: this.userData.cpf,
      phone: this.userData.phone,
      signTitle: this.userData.signTitle,
    };
    
    this.loading = true;
    this.loginDataService.updateMainUserData(body).subscribe(response => {
      this.loading = false;
      if(response && response.success == true){
        this.userData = response.data;
      }

    }, err=>{ this.loading = false; });

  }

}
