import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LocalStoreService } from '../services/local-store.service';

@Component({
  selector: 'signature-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardSignaturePage implements OnInit, OnDestroy {

  @ViewChild('snav') snav;
  
  navOpened: boolean = true;
  navWidth: number = 220;
  navSmallFixe: boolean = false;

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  private _mobileQueryListener: () => void;

  constructor(public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public localStore: LocalStoreService) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    this._mobileQueryListener = () => {
      
      if (this.mobileQuery.matches == true) {
        this.navOpened = false;
      } else {
        this.navOpened = true;
      }

      changeDetectorRef.detectChanges();
      console.log('mobileQuery.matches ', this.mobileQuery.matches);
    }

    this.mobileQuery.addListener(this._mobileQueryListener);


    //Load Config
    this.navSmallFixe = localStore.getItem('navSmallFixe');
  }

  onOptionClicked(menu){
    console.log('onOptionClicked', menu);
    if(this.mobileQuery.matches == true){
      this.snav.toggle();
    }
  }

  entering: boolean = false;
  sidenavMouseEnter() {
    if(this.entering == true) return;
    this.entering = true;
    this.mouseLeaved = false;
    setTimeout(()=>{
      this.entering = false;
      if(this.mouseLeaved == true) return;
      if (this.mobileQuery.matches == false){
        if (this.navWidth < 220) {
          this.navWidth = 220;
        }
      }
    }, 500);
  }

  mouseLeaved: boolean = false;
  outing: boolean = false;
  sidenavMouseLeave() {
    this.mouseLeaved = true;
    if(this.outing == true) return;
    this.outing = true;   
    setTimeout(()=>{
      this.outing = false;
      if (this.mobileQuery.matches == false){
        if (this.navWidth >= 220 && this.navSmallFixe) {
          this.navWidth = 75;
        }
      }
    }, 300);
  }

  toggleSmallFixe(){
    this.navSmallFixe = !this.navSmallFixe;
    this.localStore.setItem('navSmallFixe', this.navSmallFixe);
    this.updateVariables();
    this.changeDetectorRef.detectChanges();
    //window.location.reload();
  }

  updateVariables() {
    
    if (this.navSmallFixe) {
      this.navWidth = 75;
    } else {
      this.navWidth = 220;
    }
  }



  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    if (this.mobileQuery.matches == true) {
      this.navOpened = false;
    } else {
      if(this.navSmallFixe)
      {
        this.navWidth = 75;
      }
      this.navOpened = true;
    }
  }

}
