import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DomainNotification } from 'src/app/models/domainNotification';
import { UserResumeViewModel } from 'src/app/models/user-model';
import { LoginDataService } from 'src/app/services/login-service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-send-certificate',
  templateUrl: './send-certificate.component.html',
  styleUrls: ['./send-certificate.component.scss'],
  animations: [SharedAnimations]
})
export class SendCertificateComponent  implements AfterViewInit, OnInit {

  @Output()
  public onCancelClick = new EventEmitter<any>();
  @Output()
  public onSaved = new EventEmitter<any>();


  @ViewChild('fileInput') fileInput;
  @ViewChild('txtSignerUser') txtSignerUser;

  public file: any;
  public fileName: string;
  public fileSize: number;

  public progress: number;
  public message: string;

  public formGroug: FormGroup;
  formErrors: DomainNotification[] = [];


  loading = false;


  MatcherName = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'Name'); } };
  MatcherFirstDateOfTask = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'FirstDateOfTask'); } };

  constructor(
    private dataService: LoginDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,) {

      this.formGroug = this.formBuilder.group({
        file: [null],
        fileName: [''],
        password: [''],
      });

  }
 


  ngOnInit(){

    
    
  }

  ngAfterViewInit(): void {
   

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.txtSignerUser.nativeElement.focus();
      }
      else {
        this.txtSignerUser.nativeElement.focus();
      }

    }, 150);

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

 


  
  changeFile(files) {
    console.log(files);

    if (files.length > 0) {
      this.file = files[0];
      this.fileName = files[0].name;
      this.fileSize = files[0].size; //// (files[0].size / 1000) + ' KB';

      console.log(' this.fileSize: ',  this.fileSize);

      this.formGroug.controls['fileName'].setValue(this.fileName);
    }
  }


  userSelected(event: MatAutocompleteSelectedEvent ) {
    
    //this.data.personId = event.option.value.id;
    
  }
  

  close() {
    this.onCancelClick.emit(null);
    this.dialog.closeAll();
  }


  docUploaded(event) {
    console.log('event: ', event);
  }



  submit() {


    this.formErrors = [];
    if (this.fileSize > 5000000) {
      Swal.fire('Oops...', 'O Arquivo Não deveria ser maior que 5MB!', 'error');
      return;
    }


    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('password', this.formGroug.controls['password'].value);

    console.log('this.file', this.file);


    const uploadReq = new HttpRequest('POST', this.sharedInfo.authUrl + '/user/uploadCertificate', formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {

      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);

      } else if (event.type === HttpEventType.Response) {
        const retObj = JSON.parse(JSON.stringify(event.body));
        if (retObj.success === true) {
          //localStorage.setItem('dnErp.xxx', JSON.stringify(event.body));
          this.onSaved.emit(retObj);
          this.dialog.closeAll();
        }
      }

    }, fail => {
      console.log('errors', fail)
      if (fail.error.errors) {
        this.formErrors = fail.error.errors;
        console.log('formErrors', this.formErrors)
      }
    });





  }


  

  

}
