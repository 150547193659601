import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from '../../../../services/shared-info';
import { UserService } from '../../../../services/user-service';
import { Observable } from 'rxjs';
import { FilterInstalmentCommandDataType, PayInstalmentCommand } from './receivable.models'
import * as moment from 'moment';
import { FinOperationType } from 'src/app/enums/enums';


let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class ReceivableDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('PaymentInstalment', 
      { 
        startDate: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
        endDate:  new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0),
        dataType: FilterInstalmentCommandDataType.DueDate,
        operation: FinOperationType.Credit
      }, sharedInfo, userService, http, { add: false, fetch: true, tableMode : true });

    this.routerTitle = 'Contas a Receber';
  }

  payInstalments(cmd: PayInstalmentCommand): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PaymentInstalment/PayInstalments', cmd);
  }


  getColums() {
    return [
      { columnDef: 'invoiceNumber', header: 'Código', cell: (row) => `${row.invoiceNumber}` },
      { columnDef: 'dueDate', header: 'Data Vencimento', cell: (row) => `${row.dueDate}` },
      { columnDef: 'personBilledName', header: 'Pessoa', cell: (row) => `${row.personBilledName}%` },

      
      { columnDef: 'origin', header: 'Origem', cell: (row) => `${row.origin}%` },

      { columnDef: 'totalInstallments', header: 'Parcelas', cell: (row) => `${row.totalInstallments}%` },
      { columnDef: 'value', header: 'Valor', cell: (row) => `${row.value}%` },

     
      
    ];;
  }

}
