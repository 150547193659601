// authenticated = true,
// created = dataCriacao.ToString("yyyy-MM-dd HH:mm:ss"),
// expiration = dataExpiracao.ToString("yyyy-MM-dd HH:mm:ss"),
// accessToken = token,
// user = new {
//     name = userResult.Name,
//     email = userResult.Email,
//     avatarUrl = userResult.AvatarUrl,
// },
// claims = userResult.Claims,
// message = "OK"



export class UserResumeViewModel {
  id = '';
  name = '';
  cpf = '';
  email = '';
  signTitle = '';

}



export class AuthenticateUserModel {

  id: string;
  erpId: string | null;
  mainCNPJ: string;
  dataBaseName: string;
  hasDataBase: boolean;

  name: string;
  email: string;
  username: string;
  cpf: string;
  phone: string;
  signTitle: string;
  certificateExpiresDate: string | null;
  certificateFileName: string;
  tokenNotConfirmed: boolean;
  activated: boolean;
  avatarUrl: string;

  public claims: ClaimModel[] = [];


  constructor() {
  }

}

export class ClaimModel {
  public type: string;
  public value: string;
  public showInMenu: boolean;
}


export class UsuarioModel {

  public id: string;
  public nome: string;
  public email: string;
  public cpf: string;

  public avatarUrl: string;

  public entidadeId: Number;
  public entidadeNome: string;

  constructor() {
  }

}
