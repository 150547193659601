<section class="container-fluid with-maxwidth chapter">

  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <span class="active">Histórico de Vendas</span>
    </li>
  </ul>



  <!-- <div *ngIf="isMobile === true && appService.options?.timeLimeMode == false">
  
      <div class="card card-table-Mobile"
        [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
        <mat-nav-list>
          <mat-list-item *ngFor="let item of data">
            <a matLine href="javascript:;" (click)="openEdit(item.id)">
              <span class="link-caption">{{ item.name }}</span>
            </a>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-nav-list>
      </div>
  
  
      <div class="fab-buttons">
        <button mat-fab color="primary" class="add-button" (click)='addItem(); $event.stopPropagation();'>
          <mat-icon (click)='addItem(); $event.stopPropagation();'>add</mat-icon>
        </button>
      </div>
  
    </div> -->


  <div class="webcomands row">
    <div class="col-md-8">


      <div class="row">

        <div class="col-xs-12 col-md-4">
          <mat-form-field class="full-width">
            <mat-icon matSuffix class="material-icons">search</mat-icon>
            <input matInput #strFindControl placeholder="Buscar" [(ngModel)]="strFind" ngDefaultControl
              (ngModelChange)='fetch()'>
          </mat-form-field>
        </div>


        <div class="col-xs-12 col-md-3">
          <mat-form-field class="period-fields">
            <mat-label>Data Inicial</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange)="fetch()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field class="period-fields">
            <mat-label>Data Final</mat-label>
            <input matInput [matDatepicker]="endDatepicker" [(ngModel)]="endDate" (dateChange)="fetch()">
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
          </mat-form-field>

        </div>

      </div>


    </div>

    <div class="col-md-4">
      <div class="webcomands_buttons">


        <a mat-raised-button (click)='sincData()'>
          <i class="cmd-icon nav-icon i-Reload"></i> Sincronizar
        </a>


        <a mat-raised-button (click)='fetch()' style="margin-left: 5px;">
          <i class="cmd-icon nav-icon i-Reload"></i> Atualizar
        </a>

      </div>

    </div>

  </div>


  <div class="desktop-table-container">

    <mat-card class="card-data-content"
      [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

      <div class="card-table-web">



        <table class="data-table" [dataSource]="data" mat-table matSort matSortActive="dateClosed"
          matSortDirection="desc" matSortDisableClear>


          <ng-container matColumnDef="dateClosed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.dateClosed | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome do Cliente </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.clientName}} </td>
          </ng-container>



          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Valor Total </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.totalValue | currency: 'BRL'}} </td>
          </ng-container>

          <ng-container matColumnDef="statusSinc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Situação </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]>

              <span class="stOrder-delivering">
                <i *ngIf="row.statusSincPdv == 1" class="material-icons">autorenew</i>
                {{row.statusSinc | statusSincPdv}}

                <div  *ngIf="row.sincErrorDetails && row.sincErrorDetails != ''"  style="width: 180px;">
                  <a style="word-break:normal; font-size: 11px; color: rgb(116, 0, 0);" (click)="selectedItem = row; openDialog(errorDetailsModal);">{{ row.stateDetails }}</a>
                </div>

                <div  *ngIf="row.stateDetails && (row.sincErrorDetails == null || row.sincErrorDetails == '')"  style="width: 180px;">
                  <a style="word-break:normal; font-size: 11px; color: rgb(80, 1, 1);" >{{row.stateDetails}}</a>
                </div>
               
              </span>

            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <div style="display: flex; float: right;">
                <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                  <i class="material-icons">edit</i></a>
              </div>
            </td>
          </ng-container> -->


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


      </div>




    </mat-card>

    <mat-card class="card-bottom-data-table">
      <div>



        <!-- <mat-paginator class="tbPaginator" #tbPaginator [length]="resultsLength" [pageSize]="25" style="float: right;"
            [pageSizeOptions]="[5, 10, 25, 50, 100]">
          </mat-paginator> -->

      </div>
    </mat-card>



  </div>




</section>



<ng-template #errorDetailsModal>

  <div mat-dialog-content>

    <div class="st-header">
      <span class="title">Detalhes do erro</span>
    </div> 

    <div class="l1-content-modal ">

      <mat-form-field class="full-width" appearance="outline">
        <textarea matInput placeholder="..." [disabled]="true" rows="20"
            [(ngModel)]="selectedItem.sincErrorDetails"></textarea>
      </mat-form-field>

      <button mat-raised-button class="btn btn-rounded ladda-button m-1" color="primary" style="float: right;" (click)="dialog.closeAll();">
          Fechar
      </button>
  </div>

  </div>
 
</ng-template>
