<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">


        <div class="row">

            <div class="form-group col-md-2">
                <mat-form-field class="full-width" appearance="fill" readOnly>
                    <mat-label>{{ 'stock_mov_add_edit.code' | translate }}</mat-label>
                    <input required matInput type="text" #code [(ngModel)]="data.mainData.code" [disabled]="true">
                    <!-- <mat-icon matSuffix class="material-icons">business</mat-icon> -->
                    <mat-error>
                        {{ formErrors | findErrors: 'code'}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group col-md-4">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'stock_mov_add_edit.movement_type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="data.mainData.movementType" required #txtGroup
                        [disabled]="data.mainData.id != null">
                        <mat-option [value]="0">Entrada</mat-option>
                        <mat-option [value]="1">Saída</mat-option>
                    </mat-select>
                    <mat-error>
                        {{ formErrors | findErrors: 'movementType'}}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="row">
            <div class="form-group col-md-12">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'stock_mov_add_edit.details' | translate }}</mat-label>
                    <textarea matInput placeholder="..." [(ngModel)]="data.mainData.details" rows="4"
                        [readonly]="data.mainData.id != null"></textarea>
                </mat-form-field>
            </div>
        </div>



        <div class="row" *ngIf="!data.mainData.id">

            <div class="form-group col-md-9">
                <mat-form-field class="full-width">
                    <input matInput #txtDescription placeholder="{{ 'stock_mov_add_edit.product_find' | translate }}"
                        [matAutocomplete]="auto" [(ngModel)]="productFind" (keyup)="getProducts($event)"
                        style="font-size: 18px;">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="itemFindDisplayFn"
                    (optionSelected)="productSelected($event)">
                    <mat-option *ngIf="isLoadingProd" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingProd">
                        <mat-option *ngFor="let item of filteredProducts" [value]="item">
                            <span>{{ item.code }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

            <div class="form-group col-md-3">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'stock_mov_add_edit.quantity_insert' | translate }}</mat-label>
                    <input matInput type="numeric" #txtQuantity (keydown)="onQuantityKeyDown($event)" currencyMask
                        min="00" [options]="{ align: 'left', precision: 0 }" [(ngModel)]="quantityInsert"
                        style="font-size: 18px;">
                </mat-form-field>
            </div>

        </div>







        <div class="card card-white card-itens" #tableItens
            [ngStyle]="{ 'height' : data.mainData.id ? 'calc(100vh - 460px)' : 'calc(100vh - 500px)'}">


            <table class="table-itens" [dataSource]="itensDataSource" mat-table>

                <!-- <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef  disableClear> Item </th>
          <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.number}} </td>
        </ng-container> -->

                <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef disableClear> Código </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.productCode}} </td>
                </ng-container>

                <ng-container matColumnDef="productDescription">
                    <th mat-header-cell *matHeaderCellDef disableClear> Descrição do Produto </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.productDescription}} </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef disableClear> Quantidade </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.quantity}} </td>
                </ng-container>

                <ng-container matColumnDef="cmd">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-raised-button class="btn-sm btn_cmd" (click)="deleteItem(row)" [@myfadeIn]
                            *ngIf="!data.mainData.id"><i class="material-icons">delete_forever</i></button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="itensColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: itensColumns;" class="element-row"></tr>
            </table>

        </div>

        <div class="text-right" *ngIf="deviceService.isMobile() == false">
            <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()"
                *ngIf="data.mainData.id">Fechar</button>
            <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()"
                *ngIf="!data.mainData.id">Cancelar</button>
            <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                *ngIf="!data.mainData.id" (click)="submit();">Salvar</button>
        </div>

    </mat-card>
</div>