<div class="dash-container">


  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">

    <mat-sidenav #snav [class.mat-sidenav-is-mobile]="mobileQuery.matches" class="sidenav-menu app-sidebar"
      [style.width.px]="navWidth" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="navOpened"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" (mouseenter)="sidenavMouseEnter()"
      (mouseleave)="sidenavMouseLeave()">

      <signature-sidenav-menu [isExpanded]="(navWidth >= 220 ? true : false)" [navWidth]="navWidth"
        (onOptionClicked)="onOptionClicked($event)">
        </signature-sidenav-menu>

        <!--Adicionar outras opções do menu aqui:-->

        <!-- 
        <mat-nav-list>
          <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a>
        </mat-nav-list>
        -->

    </mat-sidenav>

    <mat-sidenav-content [style.marginLeft.px]="mobileQuery.matches ? 0 : (navSmallFixe ? 75 : navWidth)">
      <!-- <mat-toolbar color="primary" class="dash-toolbar"> -->
      <mat-toolbar class="dash-toolbar" [class.dash-is-mobile]="mobileQuery.matches">
        <button mat-icon-button (click)="navWidth = 220; snav.toggle()" *ngIf="mobileQuery.matches">
          <mat-icon>menu</mat-icon>
        </button>

        <button mat-icon-button (click)="toggleSmallFixe()" *ngIf="mobileQuery.matches == false">
          <mat-icon>menu</mat-icon>
        </button>

        <signature-header></signature-header>

      </mat-toolbar>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>


</div>