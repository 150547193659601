import { Type, EventEmitter } from '@angular/core';

export class AdItem<ServiceType> {

  public onCreated: EventEmitter<any> = new EventEmitter<any>();
  public onCommandExecuted: EventEmitter<any> = new EventEmitter<any>();
  public data: any;
  public title: string;
  public commands: CommandItem[] = [];
  public moreCommands: CommandItem[] = [];

  public service:ServiceType;

  constructor(public component: Type<any>, _service:ServiceType) {
    this.service = _service;
  }
}


export class CommandItem {
  cmd:string;
  icon:string;
  label:string;
}
