<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">

        <div class="row">

            <div class="col-md-2">
                <mat-form-field class="full-width" appearance="fill" readOnly>
                    <mat-label>{{ 'order_add_edit.code' | translate }}</mat-label>
                    <input required matInput type="text" #code [(ngModel)]="data.mainData.code" [disabled]="true">
                    <!-- <mat-icon matSuffix class="material-icons">business</mat-icon> -->
                    <mat-error>
                        {{ formErrors | findErrors: 'code'}}
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="col-md-10">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.client' | translate }}</mat-label>
                    <input matInput required #txtFindClient [matAutocomplete]="auto"
                        [(ngModel)]="data.mainData.clientResume" (keyup)="getClients($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn"
                    (optionSelected)="clientSelected($event)">
                    <mat-option *ngIf="isLoadingClients" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let item of filteredClients" [value]="item">
                            <span>{{ item.cnpjcpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

            <!-- <div class="col-md-4">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.movement_type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="data.mainData.movementType" required #txtGroup>
                        <mat-option value="0">Entrada</mat-option>
                        <mat-option value="1">Saída</mat-option>
                    </mat-select>
                    <mat-error>
                        {{ formErrors | findErrors: 'movementType'}}
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.details' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.mainData.details" (keyup)="discountValueKeyUp($event)"
                        [readonly]="(data.mainData.statusDelivery >= 10)">
                    <mat-error>
                        {{ formErrors | findErrors: 'details'}}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>



        <div class="row" *ngIf="!data.mainData.statusDelivery || data.mainData.statusDelivery < 10">

            <div class="col-md-9">
                <mat-form-field class="full-width">
                    <input matInput #txtFindProd placeholder="{{ 'order_add_edit.product_find' | translate }}"
                        [matAutocomplete]="acbProd" [(ngModel)]="productFind" (keyup)="getProducts($event)"
                        style="font-size: 18px;">
                </mat-form-field>

                <mat-autocomplete #acbProd="matAutocomplete" [displayWith]="itemFindDisplayFn"
                    (optionSelected)="productSelected($event)">
                    <mat-option *ngIf="isLoadingProd" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingProd">
                        <mat-option *ngFor="let item of filteredProducts" [value]="item">
                            <span>{{ item.code }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

            <div class="col-md-3">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'order_add_edit.quantity_insert' | translate }}</mat-label>
                    <input matInput type="numeric" #txtQuantity (keydown)="onQuantityKeyDown($event)" currencyMask
                        min="00" [options]="{ align: 'left', precision: 0 }" [(ngModel)]="quantityInsert"
                        style="font-size: 18px;">
                </mat-form-field>
            </div>

        </div>








        <div class="card card-white card-itens" #tableItens
            [ngStyle]="{ 'height' : (!data.mainData.statusDelivery || data.mainData.statusDelivery < 10) ? 'calc(100vh - 460px)' : 'calc(100vh - 400px)'}">


            <table class="table-itens" [dataSource]="itensDataSource" mat-table>

                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef disableClear> Item </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.number}} </td>
                </ng-container>

                <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef disableClear> Código </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.productCode}} </td>
                </ng-container>

                <ng-container matColumnDef="productDescription">
                    <th mat-header-cell *matHeaderCellDef disableClear> Descrição do Produto </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.productDescription}} </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef disableClear> Quantidade </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.quantity}} </td>
                </ng-container>

                <ng-container matColumnDef="unitValue">
                    <th mat-header-cell *matHeaderCellDef disableClear> Vl. Unit. </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.unitValue}} </td>
                </ng-container>

                <ng-container matColumnDef="totalValue">
                    <th mat-header-cell *matHeaderCellDef disableClear> Vl. Total </th>
                    <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.totalValue}} </td>
                </ng-container>


                <ng-container matColumnDef="cmd">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-raised-button class="btn-sm btn_delete" (click)="deleteItem(row)" [@myfadeIn]
                            *ngIf="!data.mainData.statusDelivery || data.mainData.statusDelivery < 10"><i
                                class="material-icons">delete_forever</i></button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="itensColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: itensColumns;" class="element-row"></tr>
            </table>

        </div>

        <div class="row">


            <div class="col-md-2">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'order_add_edit.subTotalValue' | translate }}</mat-label>
                    <input matInput type="text" currencyMask [(ngModel)]="data.mainData.subTotalValue"
                        style="font-size: 22px;" readonly>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'order_add_edit.discountValue' | translate }}</mat-label>
                    <input matInput type="text" currencyMask [(ngModel)]="data.mainData.discountValue"
                        style="color: rebeccapurple; font-size: 22px;" (keyup)="discountValueKeyUp($event)">
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'order_add_edit.totalValue' | translate }}</mat-label>
                    <input matInput type="text" currencyMask [(ngModel)]="data.mainData.totalValue"
                        style="color: darkblue; font-size: 22px;" readonly>
                </mat-form-field>
            </div>


            <div class="col-md-6">
                <div class="btn-commands">

                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded ladda-button m-1" color="primary"
                        #btnPrint [disabled]="loading" *ngIf="data.mainData?.id" (click)="printLocal();">
                        <i class="material-icons">print</i><br>Imprimir
                    </button>

                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded ladda-button m-1" color="primary"
                        #btnOk [disabled]="loading" *ngIf="!data.mainData.id" (click)="submit();">
                        <i class="material-icons">save</i><br>Salvar
                    </button>

                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded btn-link ladda-button m-1"
                        [disabled]="loading" *ngIf="data.mainData.id && data.mainData.statusDelivery < 10"
                        (click)="clearFilters(); openDialogWithTemplateRef(setStatusDialog)">
                        <i class="material-icons">skip_next</i><br>Avançar Etapa
                    </button>


                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded btn-link ladda-button m-1" [disabled]="loading"
                    *ngIf="data.mainData.id && data.mainData.statusDelivery < 10 && data.mainData.payed == false"
                    (click)="clearFilters(); addPayment();">
                    <i class="material-icons">attach_money</i><br>Pagamento
                    </button>

                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded btn-link ladda-button m-1"
                        color="accent" [disabled]="loading"
                        *ngIf="data.mainData.id && data.mainData.statusDelivery < 10 && data.mainData.payed == true"
                        (click)="clearFilters(); openDialogWithTemplateRef(setInvoiceDialog)">
                        <i class="material-icons">attach_money</i><br>Fechar Pedido
                    </button>

                </div>
            </div>
        </div>

        <!-- <div class="text-right" *ngIf="deviceService.isMobile() == false">
            <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
            <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                (click)="submit();">Salvar</button>
        </div> -->

    </mat-card>
</div>


<ng-template #setStatusDialog>
    <div class="setStatusDialog">

        <span class="title">Avançar Etapa do Pedido</span>
        <hr>

        <div class="row">

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Status do Pedido</mat-label>
                    <mat-select matInput [(ngModel)]="data.mainData.statusDelivery">
                        <mat-option [value]=1>Preparando</mat-option>
                        <mat-option [value]=2>Saiu Para Entrega</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.transporter' | translate }}</mat-label>
                    <input matInput required #txtFindClient [matAutocomplete]="auto"
                        [(ngModel)]="data.mainData.transporterResume" (keyup)="getClients($event, 'isShipping')">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn"
                    (optionSelected)="transpSelected($event)">
                    <mat-option *ngIf="isLoadingClients" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let item of filteredClients" [value]="item">
                            <span>{{ item.cnpjcpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>




        </div>

        <button mat-raised-button class="btn btn-rounded ladda-button m-1" color="primary" style="float: right;"
            [disabled]="loading || data.mainData.statusDelivery < 1" (click)="setStatus()">
            Salvar <i class="material-icons">save</i>
        </button>
    </div>
</ng-template>



<ng-template #setInvoiceDialog>
    <div class="setStatusDialog">

        <span class="title">Fechar Pedido</span>
        <hr>

        <div class="row">

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.person_billed' | translate }}</mat-label>
                    <input matInput required #txtFindClient [matAutocomplete]="auto" [(ngModel)]="personBilled"
                        (keyup)="getClients($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn"
                    (optionSelected)="personBilledSelected($event)">
                    <mat-option *ngIf="isLoadingClients" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let item of filteredClients" [value]="item">
                            <span>{{ item.cnpjcpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

        </div>



        <button mat-raised-button class="btn btn-rounded ladda-button m-1" color="accent" style="float: right;"
            [disabled]="loading || data.mainData.statusDelivery > 10" (click)="generateInvoice()">
            Faturar <i class="material-icons">save</i>
        </button>
    </div>
</ng-template>