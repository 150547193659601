    export enum InvoicePaymentType
    {
        Dinheiro = 1,
        Cheque = 2,
        CartaoCredito = 3,
        CartaoDebito = 4,
        CreditoLoja = 5,
        ValeAlimentacao = 10,
        ValeRefeicao = 11,
        ValePresente = 12,
        ValeCombustivel = 13,
        DuplicataMercantil = 14,
        BoletoBancario = 15,
        SemPagamento = 90,
        Outros = 99
    }

    export const PaymentsActives = [
        { option: 0, key: InvoicePaymentType.Dinheiro},
        { option: 1, key: InvoicePaymentType.CartaoCredito},
        { option: 2, key: InvoicePaymentType.CartaoDebito},
        { option: 3, key: InvoicePaymentType.BoletoBancario},
        { option: 4, key: InvoicePaymentType.Cheque},
    ]