import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StockMovement, ProductItemMovement, StockMovementModel } from './stock-mov-model';
import { StockMovementDataService } from './stock-mov.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { ProductDataService } from '../product-add-edit/product.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, startWith } from 'rxjs/operators';
import { Product } from '../product-add-edit/product-model';
import Swal from 'sweetalert2';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/services/user-service';
import { MatTableDataSource } from '@angular/material/table';



@Component({
  selector: 'app-stock-mov-add-edit',
  templateUrl: './stock-mov-add-edit.component.html',
  styleUrls: ['./stock-mov-add-edit.component.scss'],
  animations: [SharedAnimations]
})
export class StockMovementAddEditComponent implements AfterViewInit, OnInit, AdComponent {

  title: string = '';
  localStName = 'currentAddStockMovement';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: StockMovementModel;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtQuantity') txtQuantity;
  @ViewChild('txtDescription') txtDescription;
  @ViewChild('tableItens') private tableItens: ElementRef;

  itensColumns = ['productCode', 'productDescription', 'quantity', 'cmd'];

  loading = false;
  formErrors: DomainNotification[] = [];

  isLoadingProd = false;
  productFind: Product;
  quantityInsert: number = 1;
  filteredProducts: any[] = [];
  subject: Subject<any> = new Subject();

  itensDataSource = new MatTableDataSource<ProductItemMovement>();

  constructor(
    private dataService: StockMovementDataService,
    public productDataService: ProductDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,
    public userService: UserService,
    private fb: FormBuilder,) {
  }


  ngOnInit() {
    this.localStName = this.userService.selectedUnit.id + this.localStName;
    if (this.data == null) {
      this.data = new StockMovementModel();
      this.data.mainData = new StockMovement();
      this.data.mainData.unityId = this.userService.selectedUnit.id;
      this.title = 'Movimento de Estoque';

      var currentAddStockMovement = localStorage.getItem(this.localStName);
      if (currentAddStockMovement != null) {
        var lastItemAdded = <StockMovementModel>JSON.parse(currentAddStockMovement);
        if (lastItemAdded.mainData.unityId == this.userService.selectedUnit.id) {
          this.data = lastItemAdded;
        }
      }

      this.dataService.getNewSequencialCode().subscribe(resp => {
        if (resp.success === true) {
          this.data.mainData.code = resp.data.code;
          this.data.mainData.seqCode = resp.data.seqCode;
        }
      });

    }
    else {
      this.title = 'Movimento de Estoque';
    }

    this.sharedInfo.onChangePopUpTitle.emit(this.title);

    this.itensDataSource.data = this.data.itens;

    console.log('this.data', this.data);

    setTimeout(() => {
      if (this.deviceService.isMobile() == false) {
        this.txtDescription?.nativeElement.focus();
        this.scrollToBottom();
      }
    }, 150);


    this.subject
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingProd = true;
          this.productDataService.findData(value).subscribe(ret => {
            this.isLoadingProd = false;
            this.filteredProducts = ret.data.itens
          }, err => { this.isLoadingProd = false });
        }
      });

  }

  getProducts(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)){
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subject.next(searchTerm);
    }
  }

  itemFindDisplayFn(item: Product) {
    if (item) { return item.code + ' - ' + item.name; }
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
  }


  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  onQuantityKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      console.log('entered');
      this.addItem();
    }
  }
  
  productSelected(event) {
    this.txtQuantity.nativeElement.focus();
    this.txtQuantity.nativeElement.select();
  }

  addItem() {
    if (this.productFind != null) {
      if (this.data.itens == null) {
        this.data.itens = [];
      }
      var addItem = new ProductItemMovement();
      addItem.productCode = this.productFind.code;
      addItem.productId = this.productFind.id;
      addItem.productDescription = this.productFind.name;
      addItem.quantity = this.quantityInsert;
      addItem.number = this.setNumbersOfItens();
      this.data.itens.push(addItem);
      this.itensDataSource.data = this.data.itens;
      this.saveLocalStorage();
      this.productFind = null;
      this.filteredProducts = [];
      this.quantityInsert = 1;
      this.txtDescription?.nativeElement.focus();
      this.scrollToBottom();
    }
  }

  deleteItem(item) {
    this.data.itens.splice(this.data.itens.indexOf(item), 1);
    this.setNumbersOfItens();
    this.itensDataSource.data = this.data.itens;
    this.saveLocalStorage();
  }

  setNumbersOfItens(): number {
    var num = 1;
    this.data.itens.forEach(item => {
      item.number = num;
      num++;
    });
    return num;
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStName, JSON.stringify(this.data));
    console.log('this.data', this.data);
  }

  close() {
    this.onCancelClick.emit(null);
  }

  scrollToBottom(): void {
    try {
        this.tableItens.nativeElement.scrollTop = this.tableItens.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  submit() {
    this.formErrors = [];


    if (this.data.mainData.id) {
      this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Um movimento de estoque não pode ser alterado!' })
      return;
    }

    if (this.data.mainData.movementType == null) {
      this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Informe p Tipo de Movimento!' })
      return;
    }

    this.loading = true;
    this.dataService.insertMovement({ mainData: this.data.mainData, itens: this.data.itens }).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
        localStorage.removeItem(this.localStName);
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });
  }

}
