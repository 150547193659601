import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from "@aspnet/signalr";
import { HubConnectionState} from '@aspnet/signalr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { ChartModel } from '../_interfaces/chartmodel.model';
import Swal from 'sweetalert2';
import { CommandType, LocalCommand } from '../models/local-command'
import { UserService } from './user-service';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    //public data: ChartModel[];

    public localUrlRtc = 'http://localhost:5099/pdvrtc';
    public connectedClients = [];

    public onInfoState = new EventEmitter<any>();

    private hubConnection: signalR.HubConnection;

    constructor(private ngxLoader: NgxUiLoaderService, private router: Router, private userService: UserService,) {

        // var checkConnInterval = setInterval(() => {

        //     if (this.hubConnection == null) {
        //         this.startConnection();
        //     } else if (this.hubConnection.state == HubConnectionState.Disconnected) {
        //         this.startConnection();
        //     }

        // }, 10000);

    }

    public startConnection(): Promise<any> {

        return new Promise((resolve, reject) => {

            if (this.hubConnection != null && this.hubConnection.state == HubConnectionState.Connected) {
                resolve("OK");
            } else {

                var options = {
                    // transport: signalR.HttpTransportType.ServerSentEvents,
                    // logging: signalR.LogLevel.Trace,
                    // accessToken: function () {
                    //     return this.userService.tokenData;
                    // },
                    accessTokenFactory: () => `${this.userService.tokenData}`
                };

                this.hubConnection = new signalR.HubConnectionBuilder()
                    .withUrl(this.localUrlRtc, options)
                    .build();

                this.hubConnection
                    .start()
                    .then(() => {
                        console.log('Connection started');
                        this.addCallbackerror();
                        this.addStateListener();
                        resolve("OK");
                    })
                    .catch(err => {
                        console.log('Error while starting connection: ' + err);
                        reject();
                    });
            }


        });


    }

    public state(): HubConnectionState {
        return this.hubConnection.state;
    }

    public addStateListener = () => {

        try {
            this.hubConnection.off('infoPdvState');
        } catch (error) { }

        this.hubConnection.on('infoPdvState', (data) => {
            this.onInfoState.emit(JSON.parse(data));
            console.log('infoPdvState', data);
        });
    }

    public addPdvConnectListener = () => {

        try {
            this.hubConnection.off('connectedclients');
        } catch (error) { }

        this.hubConnection.on('connectedclients', (data) => {
            this.connectedClients.push(data);
            console.log('connectedclients', this.connectedClients);
        });
    }

    public addCallbackerror = () => {

        try {
            this.hubConnection.off('callbackerror');
        } catch (error) { }

        this.hubConnection.on('callbackerror', (data) => {
            console.log('callbackerror', data);

            try { this.ngxLoader.stop(); } catch (error) { }

            var errorMessage = '';
            if(data.message) {
                errorMessage = data.message + '</BR>';
            }

            if(data.errors){
                data.errors.forEach(element => {
                    errorMessage += ((element.value || element.Value) + '</BR>');
                });
            }

            if(errorMessage != '') {
                Swal.fire('Ops', errorMessage, 'error');
            }

            if(data.versionError === true) {
                this.router.navigate(['app/home']);
            }
        });
    }

    public pdvConnect(userName) {
        this.hubConnection.send("pdvconnect", userName);
    }

    public sendCommand(cmd: LocalCommand, waitReturn : boolean, methodName: string = "executecmd"): Promise<any> {
        return new Promise((callback, error) => {

            if(waitReturn)
                try { this.ngxLoader.start(); } catch (error) { }

            this.startConnection().then(connOK => {

                this.hubConnection.send(methodName.toLowerCase(), JSON.stringify(cmd)).then(sendOK => {

                    var callbmet = CommandType[cmd.commandType].toString().toLowerCase();

                    this.hubConnection.off(callbmet);

                    if(waitReturn === true) {
                        this.hubConnection.on(callbmet, ret => {
                            callback(ret);
                            try { this.ngxLoader.stop(); } catch (error) { }
                        });
                        
                    } else {

                        try { this.ngxLoader.stop(); } catch (error) { }
                        this.hubConnection.on(callbmet, callback);

                    }


                }, sendError => {
                    console.log(sendError);
                    try { this.ngxLoader.stop(); } catch (error) { }
                    Swal.fire('Ops', 'Falha ao enviar comando para o Plugin local!', 'error');
                    error();
                });

            }, connError => {
                try { this.ngxLoader.stop(); } catch (error) { }
                console.log('Falha ao conectar no Plugin local!');
                //Swal.fire('Ops', 'Falha ao conectar no Plugin local!', 'error');
                error();
            });
        });
    }

}