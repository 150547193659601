import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HubConnectionState } from '@aspnet/signalr';
import { CommandType, LocalCommand } from 'src/app/models/local-command';
import { SharedInfo } from 'src/app/services/shared-info';
import { SignalRService } from 'src/app/services/SignalRService';
import { UserService } from 'src/app/services/user-service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';

@Component({
  selector: 'app-local-orders',
  templateUrl: './local-orders.component.html',
  styleUrls: ['./local-orders.component.scss'],
  animations: [SharedAnimations]
})
export class LocalOrdersComponent implements OnInit, OnDestroy{

  data: any[] = [];

  selectedItem: any;

  displayedColumns = ['dateClosed', 'clientName', 'totalValue', 'statusSinc'];

  strFind: string;
  startDate: Date = new Date();
  endDate: Date = new Date();

  statusSub: any;


  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    private signalRService: SignalRService,
    private dialog: MatDialog,) { 

      setTimeout(() => {


        this.signalRService.addStateListener();
  
  
        this.statusSub = this.signalRService.onInfoState.subscribe(status => {
  
          console.log('status', status);

          if(status && status.newOrderClosed != null) {
            console.log('status.newOrderClosed', status.newOrderClosed);
            this.data = [status.newOrderClosed, ...this.data];
          }
  
          if (status && status.orderSynchronized != null) {
  
            this.data.forEach(element => {
              if (element.id == status.orderSynchronized.id) {
                Object.assign(element, status.orderSynchronized);
                console.log('Order Localized...');
              }
            });
          }
  
        });
  
      }, 1000);

    }

  ngOnInit(): void {

    this.startDate = new Date(this.startDate.setMonth(this.startDate.getMonth() - 1));

    this.fetch();

    // try {
    //   this.statusSub = this.signalRService.onInfoState.unsubscribe();
    // } catch (error) { }


    // var checkConnInterval = setInterval(() => {

    //   if (this.signalRService.state() == HubConnectionState.Disconnected) {
    //     this.signalRService.startConnection();
    //   } 

    // }, 2000);


  }

  ngOnDestroy() {
    this.statusSub.unsubscribe();
  }


  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }


  fetch() {

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.GetOrders, { UnityId: this.userService.selectedUnit.id, start: this.startDate, end: this.endDate }), true).then(
        callBack => {

          console.log('GetOrders:..', callBack);

          this.data = JSON.parse(callBack);

          // if(callBack == "{}") {
          //   this.newSale();
          //   return;
          // }

        }, error => {

        });
  }


  sincData() {
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.UploadData, { UnityId: this.userService.selectedUnit.id }), true).then(
        callBack => {

          console.log('UploadData result:', callBack);

          //this.data = JSON.parse(callBack);

          //this.fetch();

          // if(callBack == "{}") {
          //   this.newSale();
          //   return;
          // }

        }, error => {

        });
  }


}
