import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

// var TransferWebpackPlugin = require('transfer-webpack-plugin');

// module.exports = {
//   plugins: [
//     new TransferWebpackPlugin([
//       { from: 'node_modules\ng2-pdfjs-viewer\pdfjs', to: path.join(__dirname, 'assets') }
//     ])
//   ]
// };


if (environment.production) {
  enableProdMode();
}


if (environment.hmr) {
  if (module['hot']) {
    const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if (environment.production && 'serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(active =>
        !active && navigator.serviceWorker.register('/ngsw-worker.js')).catch(console.error);
    }
  }).catch(err => console.log(err));
}