import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { DashboardPdvPageModule } from './dashboard-pdv/dashboard.module';
import { SharedModule } from './shared-modules'
import { SimpleNotificationsModule } from 'angular2-notifications';
import { UserService } from './services/user-service';
import { SharedInfo } from './services/shared-info';
import { LoginDataService } from './services/login-service';
import { Interceptor } from './services/interceptor.module';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCurrencyModule } from "ngx-currency";
import { ngxUiLoaderConfig } from './services/configs/nxuiConfig';
import { customCurrencyMaskConfig } from './services/configs/customCurrencyMaskConfig';
import { AuthGuard } from './services/AuthGuard';
import { AuthGuardLocal } from './services/AuthGuardLocal';
import { LocalStoreService } from './services/local-store.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './services/configs/dutch-paginator-intl';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimelineModule } from "angular2-timeline";
import { SignalRService } from "./services/SignalRService";
import { PageSharedFns } from './services/pageSharedFunctions';
import { DashboadDataService } from 'src/app/services/dashboad-service';
import { DashboardSignaturePageModule } from './dashboard-sign/dashboard.module';
import { ResizableDraggableComponent } from './comp/resizable-draggable/resizable-draggable.component';

registerLocaleData(ptBr)

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    AppComponent,
    PageSharedFns,
    //ResizableDraggableComponent
  ],
  imports: [
    BrowserModule,
    Interceptor,
    AppRoutingModule,
    BrowserAnimationsModule,

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SimpleNotificationsModule.forRoot(),

    NgxDatatableModule,


    
    DashboardPageModule,
    DashboardPdvPageModule,
    DashboardSignaturePageModule,
    SharedModule,
    NgbModule,
    TimelineModule,

    
  ],
  providers: [
    AuthGuard,
    AuthGuardLocal,
    LocalStoreService,
    UserService,
    SharedInfo,
    LoginDataService,
    DeviceDetectorService,
    SignalRService,

    DashboadDataService,

    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
