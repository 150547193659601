import { ProductGroup } from "../group-add-edit/group-model";

export class StockMovement {
    public id;
    public erpId;
    public userId;
    public unityId;
    public userName;
    public movementType: number;
    public movementOrigin: number;
    public code;
    public seqCode: number = 0;
    public date;
    public details;
}


export class ProductItemMovement {
    public id;
    public number;
    public productId;
    public productCode;
    public productDescription = '';
    public quantity: number = 0;
}



export class StockMovementModel {
    public mainData: StockMovement;

    public itens: ProductItemMovement[] = [];
}