<div class="md-content-modal l1-content-modal">

    <div class="card-header">
        <span class="title">Enviar documento por E-mail</span>
    </div>

    <div class="card-body">


        <div class="p-24 m-0">
            <div class="form-group col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>E-mail</mat-label>
                    <input matInput required #txtSignerUser [matAutocomplete]="auto" [(ngModel)]="mailTo"
                        (input)="getUsers($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="userSelected($event)">
                    <mat-option *ngIf="isLoadingUsers" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingUsers">
                        <mat-option *ngFor="let item of filteredOptions" [value]="item">
                            <span>{{ item }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>




            <div class="form-group col-md-12">
                <mat-form-field class="full-width">
                    <mat-label>Assunto:</mat-label>
                    <input required matInput type="text" [(ngModel)]="subject">
                    <mat-error>
                        {{ formErrors | findErrors: 'subject'}}
                    </mat-error>
                </mat-form-field>
            </div>



            <div class="form-group col-md-12">
                <mat-form-field class="full-width">
                    <mat-label>Mensagem:</mat-label>
                    <textarea matInput placeholder="..." [(ngModel)]="message" rows="6"></textarea>
                    <mat-error>
                        {{ formErrors | findErrors: 'message'}}
                    </mat-error>
                </mat-form-field>
            </div>




        </div>

    </div>


    <div class="bottom-cmd card-footer">
        <div class="space-between">
            <div>
                <button type="button" class="btn btn-default ripple m-l-20 " (click)="close()">Cancelar</button>
            </div>
            <div></div>
            <div>
                <button type="button" class="btn btn-primary ripple light m-l-20 " (click)="submit();"
                    [attr.disabled]="mailTo == '' || subject == '' || message == '' || isLoading == true ? '' : null">Enviar</button>
            </div>
        </div>
    </div>



</div>