<div class="md-content-modal">

    <div class="card-header">
        <span class="title">Adicionar Signatário</span>
    </div>

    <div class="card-body">


        <div class="p-24 m-0">


            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'document_add.main_signer' | translate }}</mat-label>
                    <input matInput required #txtSignerUser [matAutocomplete]="auto" [(ngModel)]="signerUser"
                        (input)="getUsers($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="userDisplayFn"
                    (optionSelected)="userSelected($event)">
                    <mat-option *ngIf="isLoadingUsers" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingUsers">
                        <mat-option *ngFor="let item of filteredUsers" [value]="item">
                            <span>{{ item.cpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

            <div class="col-md-12">

                <mat-label>{{ 'document_add.method' | translate }}</mat-label>
                <div align="start">
                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded ladda-button m-1"
                        [ngStyle]="{'background-color': method == mt.method ? 'rgb(63 215 141)' : 'rgb(245 245 245)'}"
                        *ngFor="let mt of methodOptions" (click)="method = mt.method;">
                        <i class="material-icons">{{ mt.icon }} </i><br>
                        {{ 'document_add.method_' + mt.method | translate }}
                    </button>
                </div>



                <!-- <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'document_add.method' | translate }}</mat-label>
                    <mat-select [(ngModel)]="method" required>
                        <mat-option [value]="mt.method" *ngFor="let mt of methodOptions" >{{ 'document_add.method_' + mt.method | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>


        </div>

    </div>


    <div class="bottom-cmd card-footer">
        <div class="space-between">
          <div>
            <button mat-raised-button (click)="onCancelClick.emit()">Cancelar</button>
          </div>
          <div></div>
          <div>
            <button mat-raised-button color="primary" #btnOk [disabled]="!signerUser?.id" style="margin-left: 10px;"
            (click)="addSigner();">Salvar</button>
          </div>
        </div>
    </div>



</div>