import { Injectable, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdItem } from '../models/ad-item';
import { NotifierMessage } from '../models/notifier-message';

@Injectable()
export class SharedInfo {

    public localAPIurl = 'https://localhost:5005';

    // public serviceUrl = 'http://192.168.50.99/dnerp/operating';
    // public authUrl = 'http://192.168.50.99/dnerp/core';
    // public signUrl = 'http://localhost:5003';

    public serviceUrl = 'https://apiv2.dotneterp.com.br/operating';
    public authUrl = 'https://apiv2.dotneterp.com.br/core';
    public signUrl = 'https://apiv2.dotneterp.com.br/signatureAPI';


    @Output() public onSetComponent = new EventEmitter<AdItem<any>>();
    @Output() public onChangePopUpTitle = new EventEmitter<string>();

    public onNotifierError = new EventEmitter<NotifierMessage>();

    
    public AppConfig: any;



    constructor() {


        var stConfig = localStorage.getItem('AppConfig');
        if (stConfig) {
            this.AppConfig = JSON.parse(stConfig);
        }
        else {
            this.AppConfig = {
                brand: 'Dot-Net ERP',
                layoutBoxed: false,               // true, false
                navCollapsed: true,              // true, false
                navBehind: false,                 // true, false
                fixedHeader: true,                // true, false
                sidebarWidth: 'small',           // small, middle, large
                theme: 'light',                   // light, gray, dark
                colorOption: '21',                // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
                AutoCloseMobileNav: true,         // true, false. Automatically close sidenav on route change (Mobile only)
                productLink: 'https://www.dotnetsistemas.com.br',
                offLineMode: false
            };

            localStorage.setItem('AppConfig', JSON.stringify(this.AppConfig));
        }



    }

    saveConfig(){
        localStorage.setItem('AppConfig', JSON.stringify(this.AppConfig));
    }
}

