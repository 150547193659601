import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { ProductGroup } from './group-model';


@Injectable()
export class GroupDataService extends GenericDataService {

  public memoryList: ProductGroup[] = [];

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('productGroup', {}, sharedInfo, userService, http);
      this.routerTitle = 'Cadastro / Grupos';
  }

  getInMemoryGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.memoryList.length > 0) {
        resolve(this.memoryList);
      }
      else {
        this.getData({ page: 1, take: 50000, sortColName: "name", sortDirection: "asc" }).subscribe(response => {
          if (response.success === true) {
            this.memoryList = response.data.itens;
            resolve(this.memoryList);
          }
          else {
            resolve([]);
          }
        });
      }
    });

  }

  getColums() {
    return [
      { columnDef: 'name', header: 'Nome do Grupo', cell: (row) => `${row.name}` },
    ];;
  }

}
