import { Address } from "../city/adress-model";

export class Person {
    id;
    erpId: string;
    insertedDate: string;
    type: PersonType;
    companyName: string;
    name: string;
    cnpjcpf: string;
    rg: string;
    passport: string;
    freeStateReg: boolean;
    stateReg: string;
    cityReg: string;
    suframaReg: string;
    details: string;
    birthDate: string | null;
    creditLimit: number | null;
    isClient: boolean;
    isShipping: boolean;
    isProvider: boolean;
    isSalesman: boolean;
    isStudent: boolean;

    phone: string | null;
    email: string | null;

    adresses: Address[];
    
    animateChangeUnity: boolean = false;
}


export enum PersonType {
    Null = 0,
    Physics = 1,
    Company = 2,
    Foreign = 3
}