export class MenuItem {

    type: any;
    value: string;

    label: string;
    routerLink: any;
    icon: string;
    itens: MenuSubItem[] = [];

    selected: boolean = false;
    activeRoute: boolean = false;


    constructor(type, value, icon = "", link = "", label = "") {
        this.type = type;
        this.value = value;
        this.icon = icon;
        this.routerLink = link;
        this.label = label;
        this.itens = [];
    }
}


export class MenuSubItem {

    type: any;
    value: string;

    label: string;
    routerLink: any;
    icon: string;

    selected: boolean = false;
    activeRoute: boolean = false;

    constructor(type, value, icon = "", link = "", label = "") {
        this.type = type;
        this.value = value;
        this.icon = icon;
        this.routerLink = link;
        this.label = label;

    }
}