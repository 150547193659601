import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable } from 'rxjs';


@Injectable()
export class ProductDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('product', {}, sharedInfo, userService, http);

    this.routerTitle = 'Cadastro / Produtos';
  }


  getColums() {
    return [
      { columnDef: 'code', header: 'Código', cell: (row) => `${row.code}` },
      { columnDef: 'name', header: 'Descrição do Produto', cell: (row) => `${row.name}` },
      { columnDef: 'ncm', header: 'NCM', cell: (row) => `${row.ncm}%` },
      { columnDef: 'stock', header: 'Estoque', cell: (row) => `${row.stock}%` },
      { columnDef: 'price', header: 'Preço', cell: (row) => `${row.price}%` }
    ];;
  }

  getNewSequencialCode(): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.serviceUrl + '/' + this.tableName + '/getsequencialcode');
  }

}
