<div class="md-content-modal l1-content-modal">




  <div class="card-header">
    <b>Selecione o certificado digital:</b>
  </div>




  <div class="card-body">





    <div class="p-24 m-0">






      <div *ngIf="filesSigned.length <= 0 || !filesSigned">






        <div *ngIf="showMsgDownloadPlugIn" class="alert alert-danger border-danger">

          <button class="close" type="button">
            <i class="icofont icofont-close-line-circled"></i>
          </button>

          <p>

            <strong _ngcontent-c7="">Falha ao acessar o plugin de assinaturas!</strong>
            <!-- <code _ngcontent-c7="">Siga o procedimento abaixo:</code> -->
            <br>
            Para realizar assinaturas é necessário instalar o Plug-In local de acesso ao certificado!

          </p>

          <br>

          <a href="http://plsign.dotneterp.com.br/" target="_blank" class="btn btn-info">Baixar o Plug-In</a>

        </div>


        <div [style.max-width]="'100%'" style="height:  calc(100vh - 250px); display: grid; overflow-y: auto;">
          <div *ngFor="let cert of localCertificates">

            <mat-card style="padding: 8px; margin: 10px; margin-bottom: 15px; background-color: rgb(209, 248, 230);"
              [ngStyle]="{'background-color': cert.suggestion === true ? 'rgb(97, 236, 171)' : 'rgb(209, 240, 248)'}">
              <a href="javascript:" (click)="signDocument(cert);" style="color: dimgray;">
                <p>{{ cert.name }}</p>
                <h6>{{ cert.issName }}</h6>

              </a>
            </mat-card>




          </div>

        </div>




      </div>






      <!-- <iframe [src]='urlFileSignedSecurityTrust' *ngIf="urlFileSigned != ''" width="100%" height="500" frameborder="0"
          webkitallowfullscreen mozallowfullscreen allowfullscreen>
        </iframe> -->

      <!-- <pdf-viewer *ngIf="urlFileSigned != ''" [src]="localSrc" [zoom]="1" width="100%" height="500"></pdf-viewer> -->


      <div [style.max-width]="'100%'" style="height:  calc(100vh - 250px); overflow-y: auto;"
        *ngIf="filesSigned && filesSigned.length > 0">


        <div *ngFor="let item of filesSigned" class="alert alert-success">

          <p>
            Documento {{ item.document.documentName }} Assinado com sucesso!
            <mat-icon *ngIf="item.sended == true">done_all</mat-icon>
          </p>
          <br>
          <button mat-raised-button (click)="preview(item)">Visualizar Arquivo</button>


        </div>



      </div>


    </div>

  </div>

  


  <div class="bottom-cmd card-footer">

    <div class="text-right">

      <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
        {{progress}}%
      </span>
  
      <button mat-raised-button (click)="close()">Cancelar</button>
      <button mat-raised-button color="primary" *ngIf="allFilesSigned == true" (click)="uploadFileSigned();" style="margin-left: 10px;">
        Enviar
        ao Servidor</button>
    </div>


  </div>



</div>