import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit, HostListener, ElementRef, TemplateRef, OnDestroy } from '@angular/core';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Order, OrderItem, OrderModel, InsertInvoiceOrderModel, OrderPayCommand, LocalOrderPayment } from './order-model';
import { OrderDataService } from './order.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user-service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductDataService } from '../../stock/product-add-edit/product.service';
import { Product } from '../../stock/product-add-edit/product-model';
import { PersonDataService } from '../../catalog/person/person-add-edit/person.service';
import { Person } from "../../catalog/person/person-model";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Keys } from '@swimlane/ngx-datatable';
import { PersonFilterModel } from '../../catalog/person/person-filter';
import { SignalRService } from 'src/app/services/SignalRService';
import { HubConnectionState } from '@aspnet/signalr';
import { CommandType, LocalCommand } from 'src/app/models/local-command';
import { OrderPaymentModalComponent } from 'src/app/comp/order-payment-modal/order-payment-modal.component';


@Component({
  selector: 'app-order-add-edit',
  templateUrl: './order-add-edit.component.html',
  styleUrls: ['./order-add-edit.component.scss'],
  animations: [SharedAnimations]
})
export class OrderAddEditComponent implements AfterViewInit, OnInit, OnDestroy, AdComponent {

  title: string = '';
  localStName = 'currentAddOrder';

  keyUpSubscription: Subscription;
  public onKeyUpEvent: EventEmitter<KeyboardEvent>;
  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() tableName: string;
  @Input() data: OrderModel;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtQuantity') txtQuantity;
  @ViewChild('txtFindProd') txtFindProd;
  @ViewChild('txtFindClient') txtFindClient;
  @ViewChild('tableItens') private tableItens: ElementRef;
  @ViewChild('setStatusDialog') setStatusDialog: TemplateRef<any>;

  itensColumns = ['number', 'productCode', 'productDescription', 'quantity', 'unitValue', 'totalValue', 'cmd'];

  loading = false;
  formErrors: DomainNotification[] = [];

  isLoadingProd = false;
  productFind: Product;
  quantityInsert: number = 1;
  filteredProducts: any[] = [];
  subjectProducts: Subject<any> = new Subject();

  isLoadingClients = false;
  filteredClients: any[] = [];

  personSearchModes: PersonFilterModel = new PersonFilterModel();

  subjectClients: Subject<any> = new Subject();

  itensDataSource = new MatTableDataSource<OrderItem>();

  setStatusMode: boolean = false;

  personBilled: Person;
  invoideCmd: InsertInvoiceOrderModel;

  setPaymentDialogRef: MatDialogRef<OrderPaymentModalComponent>;

  constructor(
    private dataService: OrderDataService,
    public productDataService: ProductDataService,
    public personDataService: PersonDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,
    public userService: UserService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public signalRService: SignalRService) {
  }


  ngOnInit() {

    this.localStName = this.userService.selectedUnit.id + this.localStName;
    if (this.data == null) {
      this.data = new OrderModel();
      this.data.mainData = new Order();
      this.data.mainData.unityId = this.userService.selectedUnit.id;
      this.title = 'Pedido de Venda';
      var currentAddStockMovement = localStorage.getItem(this.localStName);
      if (currentAddStockMovement != null) {
        var lastItemAdded = <OrderModel>JSON.parse(currentAddStockMovement);
        if (lastItemAdded.mainData.unityId == this.userService.selectedUnit.id) {
          this.data = lastItemAdded;
        }
      }

      this.dataService.getNewSequencialCode().subscribe(resp => {
        if (resp.success === true) {
          this.data.mainData.code = resp.data.code;
          this.data.mainData.seqCode = resp.data.seqCode;
        }
      });

      setTimeout(() => {
        if (this.deviceService.isMobile() == false) {
          this.txtFindClient.nativeElement.focus();
        }
      }, 150);

    }
    else {
      this.title = 'Pedido de Venda';

      setTimeout(() => {
        if (this.deviceService.isMobile() == false) {
          this.txtFindProd?.nativeElement.focus();
          this.scrollToBottom();
        }
      }, 150);
    }

    this.sharedInfo.onChangePopUpTitle.emit(this.title);
    this.itensDataSource.data = this.data.itens;

    this.subjectProducts
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingProd = true;
          this.productDataService.findData(value).subscribe(ret => {
            this.isLoadingProd = false;
            this.filteredProducts = ret.data.itens
          }, err => { this.isLoadingProd = false });
        }
      });


    this.subjectClients
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingClients = true;
          this.personSearchModes.strFind = value;
          this.personDataService.findPersonData(this.personSearchModes).subscribe(ret => {
            this.isLoadingClients = false;
            this.filteredClients = ret.data.itens
          }, err => { this.isLoadingClients = false });
        }
      });



    this.onKeyUpEvent = new EventEmitter<KeyboardEvent>();
    this.keyUpSubscription = this.onKeyUpEvent.subscribe((event: KeyboardEvent) => {

      if (this.loading == true) {
        return;
      }

      if (event.keyCode === 13) {

      }

      if (event.code == 'F9') {
        if (this.dialog.openDialogs.length <= 0) {
          if (!this.data.mainData.id) {
            this.submit(false, true);
          }
          else {
            this.openDialogWithTemplateRef(this.setStatusDialog);
          }
        }


      }
    });


    this.invoideCmd = new InsertInvoiceOrderModel();
    this.invoideCmd.firstDueDate = new Date();
    if (this.data.mainData.clientResume) {
      this.invoideCmd.personBilledId = this.data.mainData.clientResume.id;
      this.personBilled = this.data.mainData.clientResume;
    }
  }

  ngOnDestroy() {
    this.keyUpSubscription.unsubscribe();
  }

  clearFilters(){
    this.filteredClients = [];
    this.filteredProducts = [];
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }


  getProducts(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectProducts.next(searchTerm);
    }
  }

  getClients(event: any, mode: string = '') {

    this.personSearchModes = new PersonFilterModel();

    switch (mode) {
      case 'isShipping':
          this.personSearchModes.isShipping = true;
        break;
    
      default:
        break;
    }

    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectClients.next(searchTerm);
    }
  }

  productSelected(event) {
    this.txtQuantity.nativeElement.focus();
    this.txtQuantity.nativeElement.select();
  }

  clientSelected(event) {
    this.data.mainData.clientId = this.data.mainData.clientResume.id;
    setTimeout(() => {
      if (this.data.mainData.id) {
        this.submit(true);
      }
      else {
        this.txtFindProd.nativeElement.focus();
      }


    }, 150);
  }

  transpSelected(event) {
    this.data.mainData.transporterId = this.data.mainData.transporterResume.id;
  }

  personBilledSelected(event) {
    this.invoideCmd.personBilledId = this.personBilled.id;
  }

  clientDisplayFn(item: Person) {
    if (item) { return item.cnpjcpf + ' - ' + item.name; }
  }

  itemFindDisplayFn(item: Product) {
    if (item) { return item.code + ' - ' + item.name; }
  }

  ngAfterViewInit(): void {
    //console.log('idForEdit', this.idForEdit);
  }

  onQuantityKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      console.log('entered');
      this.addItem();
      this.signalRService.pdvConnect(this.userService.userLoged.email); 
    }
  }

  discountValueKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      if (this.data.mainData.id) {
        this.submit(true);
      }
      else {
        this.sumValues();
      }
    }
  }


  addItem() {
    if (this.productFind != null) {
      if (this.data.itens == null) {
        this.data.itens = [];
      }
      var addItem = new OrderItem();
      addItem.productCode = this.productFind.code;
      addItem.productId = this.productFind.id;
      addItem.productDescription = this.productFind.name;
      addItem.quantity = this.quantityInsert;
      addItem.unitValue = this.productFind.price;
      addItem.totalValue = this.productFind.price * this.quantityInsert;
      addItem.un = this.productFind.un;
      addItem.number = this.setNumbersOfItens();

      if (this.data.mainData.id) {
        this.insertItem(addItem);
      }
      else {
        this.data.itens.push(addItem);
        this.itensDataSource.data = this.data.itens;
        this.saveLocalStorage();
        this.clearItemFind();
      }

    }
  }

  clearItemFind() {
    this.productFind = null;
    this.filteredProducts = [];
    this.quantityInsert = 1;
    this.txtFindProd.nativeElement.focus();
    this.sumValues();
    this.scrollToBottom();
  }

  insertItem(item: OrderItem) {
    this.loading = true;
    this.dataService.insertItem({ item: item, orderId: this.data.mainData.id }).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.data = ret.data;
        this.itensDataSource.data = this.data.itens;
        this.saveLocalStorage();
        this.clearItemFind();
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });
  }

  sumValues() {
    var subTotalValue = 0;
    var totalValue = 0;

    this.data.itens.forEach(item => {
      item.totalValue = item.quantity * item.unitValue;
      subTotalValue += item.totalValue;
      totalValue += item.totalValue;
    });

    this.data.mainData.subTotalValue = subTotalValue;
    this.data.mainData.totalValue = totalValue - this.data.mainData.discountValue;
  }

  deleteItem(item) {

    if (this.data.mainData.id) {
      this.loading = true;
      this.dataService.deleteItem({ id: item.id, orderId: this.data.mainData.id }).subscribe(ret => {
        this.loading = false;
        if (ret.success === true) {
          this.data = ret.data;
          this.itensDataSource.data = this.data.itens;
          this.saveLocalStorage();
        }
      }, error => {
        this.loading = false;
        const ret = error.error;
        if (ret.errors) {
          this.formErrors = ret.errors;
        }
      });
    } else {
      this.data.itens.splice(this.data.itens.indexOf(item), 1);
      this.setNumbersOfItens();
      this.itensDataSource.data = this.data.itens;
      this.saveLocalStorage();
    }



  }

  printLocal() {
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_PrintDav,
        {
          unity: this.userService.selectedUnit,
          order: this.data.mainData,
          itens: this.data.itens
        }), false);
  }

  setNumbersOfItens(): number {
    var num = 1;
    this.data.itens.forEach(item => {
      item.number = num;
      num++;
    });
    return num;
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStName, JSON.stringify(this.data));
    console.log('this.data', this.data);
  }

  close() {
    this.onCancelClick.emit(null);
  }

  scrollToBottom(): void {
    try {
      this.tableItens.nativeElement.scrollTop = this.tableItens.nativeElement.scrollHeight;
    } catch (err) { }
  }

  submit(saving = false, autoNext = false) {
    this.formErrors = [];

    // if (this.data.movementType == null) {
    //   this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Informe p Tipo de Movimento!' })
    //   return;
    // }

    if (this.data.mainData.statusDelivery >= 10) {
      this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Pedido já está faturado, a edição foi desativada!' })
      return;
    }

    this.loading = true;
    this.dataService.insertUpdateOrder(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        if (saving == true) {
          this.data = ret.data;
          this.itensDataSource.data = this.data.itens;
          this.saveLocalStorage();
        }
        else {
          this.data = ret.data;
          this.itensDataSource.data = this.data.itens;
          localStorage.removeItem(this.localStName);
          this.onSaved.emit(null);
          if (autoNext === true) {
            this.openDialogWithTemplateRef(this.setStatusDialog);
          }
        }
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });
  }

  setStatus() {
    this.loading = true;
    this.dataService.setStatusOrder(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.data = ret.data;
        this.itensDataSource.data = this.data.itens;
        this.data.mainData.clientName = this.data.mainData.clientResume.name;
        this.onSaved.emit({ id: ret.data.mainData.id, data: ret.data });
        this.dialog.closeAll();
        localStorage.removeItem(this.localStName);
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });

  }


  addPayment() {

    if (this.dialog.openDialogs.length <= 0) {

      console.log(this.data.mainData);
      if (this.data.mainData.payed == true) {
        Swal.fire("O'ops", 'O pagamento já foi realizado por completo!')
      } else {

        this.setPaymentDialogRef = this.dialog.open(OrderPaymentModalComponent, {
          //panelClass: 'mat-dialog-fix',
          disableClose: false,
          data: { order: this.data.mainData }
        });

        this.setPaymentDialogRef.componentInstance.onSetPayment.subscribe(cmd => {
          
          if (cmd && cmd.payment.value > 0 && cmd.payment.paymentType >= 0) {

            this.loading = true;

            this.dataService.insertOrderPayment(this.data.mainData.id, cmd).subscribe(resp=>{
              this.loading = false;
              if (resp.success === true) {
                this.data = resp.data;
                this.setPaymentDialogRef.close();
              }
            }, error => {
              this.loading = false;
              const ret = error.error;
              if (ret.errors) {
                this.formErrors = ret.errors;
              }
            });
          }

        });

      }

      //this.saveOrderLocal();

    }
    
  }

  generateInvoice() {

    this.invoideCmd.ordersId = [this.data.mainData.id];
    this.invoideCmd.userId = this.userService.userLoged.id;
    this.invoideCmd.unityId = this.userService.selectedUnit.id;

    this.loading = true;
    this.dataService.generateInvoice(this.invoideCmd).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        // this.data = ret.data;
        // this.itensDataSource.data = this.data.itens;
        this.dialog.closeAll();
        localStorage.removeItem(this.localStName);
        this.onSaved.emit(null);
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });

  }

}
