import { ProductGroup } from "../group-add-edit/group-model";

export class Product {
    public erpId;
    public id;
    public name = '';
    public code = '';
    public seqCode: number = 0;
    public barCode = '';
    public aditionalInfo = '';
    public ncm = '';
    public cest = '';
    public price: number = 0;
    public stock: number = 0;
    public un = '';
    public quantTrib: number = 0;
    public unTrib= '';

    public groupId;
    public group: ProductGroup;

    public animateChangeUnity: boolean = false;

}
