import { Component, OnInit, ComponentFactoryResolver, ViewChild, Renderer2, HostListener, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalOrderPayment, Order, OrderPayCommand } from 'src/app/dashboard/views/commercial/order-add-edit/order-model';
import { InvoicePaymentType, PaymentsActives } from 'src/app/dashboard/views/financial/invoice/invoice-enums';
import { SharedInfo } from 'src/app/services/shared-info';
//import { flipAnimation } from 'angular-animations';

@Component({
  selector: 'app-order-payment-modal',
  templateUrl: './order-payment-modal.component.html',
  styleUrls: ['./order-payment-modal.component.scss']
  // animations: [
  //   flipAnimation(),
  // ]
})
export class OrderPaymentModalComponent implements OnInit {

  appConfig: any;

  paymentsActives = PaymentsActives;

  @Input('order')
  currentOrder: Order;

  @Output() onSetPayment: EventEmitter<OrderPayCommand> = new EventEmitter<OrderPayCommand>();

  payCommand: OrderPayCommand;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public sharedInfo: SharedInfo,
    public dialogRef: MatDialogRef<OrderPaymentModalComponent>) {

    this.currentOrder = data.order;
    this.appConfig = sharedInfo.AppConfig;
  }

  ngOnInit(): void {

    this.payCommand = new OrderPayCommand();
    this.payCommand.orderId = this.currentOrder.id;
    this.payCommand.payment = new LocalOrderPayment();
    this.payCommand.payment.value = this.currentOrder.payRemainingValue;
    this.payCommand.payment.totalInstallments = 1;

  }



  onSelectPaymentType(value: InvoicePaymentType, payInst, payValue) {
    setTimeout(() => {
      if (value != InvoicePaymentType.Dinheiro && value != InvoicePaymentType.CartaoDebito) {

        var now = new Date();

        this.payCommand.payment.firstDueDate = new Date(now.setMonth(now.getMonth() + 1));
        payInst.focus();
        payInst.select();
      } else {
        this.payCommand.payment.firstDueDate = null;
        payValue.focus();
        payValue.select();
      }
    }, 50);
  }

  payInstValueKeyUp(event: KeyboardEvent, nextInput) {
    if (event.keyCode === 13) {
      nextInput.focus();
      nextInput.select();
    }
  }

  paymentValueKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.sendPayment();
    }
  }


  sendPayment() {

    this.onSetPayment.emit(this.payCommand);
  }

  // @HostListener('window:keyup', ['$event'])
  // onKeyUp(event: KeyboardEvent) {
  //   // console.log('Key:', event.key);
  //   // console.log('this.onKeyUpEvent:', this.onKeyUpEvent);
  //   // if ( event.keyCode === 27) { // when ESC is pressed
  //   //   if(this.dialog.openDialogs.length <=0)
  //   //     this.closeModal();
  //   // }
  // }

}
