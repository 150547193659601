<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">


        <div>


            <div class="row">

                <div class="form-group col-md-2">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'product-add-edit.code' | translate }}</mat-label>
                        <input required matInput type="text" #code [(ngModel)]="data.code">
                        <!-- <mat-icon matSuffix class="material-icons">business</mat-icon> -->
                        <mat-error>
                            {{ formErrors | findErrors: 'code'}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-7">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'product-add-edit.group' | translate }}</mat-label>
                        <mat-select [(ngModel)]="data.groupId" required #txtGroup>
                            <mat-option *ngFor="let item of groupDataService.memoryList" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ formErrors | findErrors: 'groupId'}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'product-add-edit.barCode' | translate }}</mat-label>
                        <input matInput type="text" #barCode [(ngModel)]="data.barCode">
                        <!-- <mat-icon matSuffix class="material-icons">business</mat-icon> -->
                        <mat-error>
                            {{ formErrors | findErrors: 'barcode'}}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="row">

                <div class="form-group col-md-9">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'product-add-edit.description' | translate }}</mat-label>
                        <input required matInput type="text" #txtName [(ngModel)]="data.name">
                        <mat-error>
                            {{ formErrors | findErrors: 'name'}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'product-add-edit.un' | translate }}</mat-label>
                        <mat-select [(ngModel)]="data.un" required #txtGroup>
                            <mat-option [value]="'UN'">UN</mat-option>
                            <mat-option [value]="'KG'">KG</mat-option>
                        </mat-select>
                        <mat-error>
                            {{ formErrors | findErrors: 'un'}}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="row">

                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'product-add-edit.ncm' | translate }}</mat-label>
                        <input matInput type="text" #ncm [(ngModel)]="data.ncm">
                        <mat-error>
                            {{ formErrors | findErrors: 'ncm'}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-9">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'product-add-edit.aditionalInfo' | translate }}</mat-label>
                        <input matInput type="text" #aditionalInfo [(ngModel)]="data.aditionalInfo">
                        <mat-error>
                            {{ formErrors | findErrors: 'aditionalInfo'}}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="row">

                <div class="form-group col-md-10">

                </div>

                <div class="form-group col-md-2">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'product-add-edit.price' | translate }}</mat-label>
                        <input matInput type="numeric" #price currencyMask style="font-size: 18px;"
                            [(ngModel)]="data.price">
                        <mat-error>
                            {{ formErrors | findErrors: 'price'}}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false">
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                    (click)="submit();">Salvar</button>
            </div>


        </div>



    </mat-card>
</div>