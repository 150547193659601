<!-- [style.marginTop.px]="mobileQuery.matches ? 65 : 0" -->
<section class="container-fluid with-maxwidth chapter">

    <ul class="breadcrumb">
        <li class="breadcrumb-item">
            <span class="active">{{ 'public_sign_page.title' | translate }}</span>
        </li>
    </ul>


    <!-- Visual Mobile -->
    <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">

        <mat-card class="card-data-content document-content"
            [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

            <mat-card-header style="padding: 15px; ">
                <!-- <div mat-card-avatar class="example-header-image"></div> -->

                <mat-card-subtitle>Nome do documento</mat-card-subtitle>
                <mat-icon mat-card-avatar>picture_as_pdf</mat-icon>
                <mat-card-title>{{ documentData.name }}</mat-card-title>


                <mat-card-subtitle>
                    <p *ngIf="documentData.datePublish != null">

                        <span>Publicado em: {{ documentData.datePublish | amFromUtc | amLocal | amDateFormat:
                            'DD-MM-YYYY HH:mm'
                            }}</span>

                        Por: <b>{{ documentData.publisherUserName }}</b>


                    </p>
                </mat-card-subtitle>
            </mat-card-header>

            <hr style="margin: 0px;">

            <mat-card-content style="padding: 15px; margin-top: 0px;">



                <div class="card-body">




                </div>



            </mat-card-content>






        </mat-card>


        <!-- <pdf-viewer [src]="this.documentData.urlDocument" [render-text]="true" [fit-to-page]="true"
            style="display: block; ">
        </pdf-viewer> -->

    </div>



    <!-- Visual Web -->
    <div class="desktop-table-container d-none d-sm-none d-md-block d-lg-block d-xl-block" *ngIf="documentData">

        <mat-card class="card-data-content document-content"
            [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

            <mat-card-header style="padding: 15px; ">
                <!-- <div mat-card-avatar class="example-header-image"></div> -->
                <mat-icon mat-card-avatar>picture_as_pdf</mat-icon>
                <mat-card-title>{{ documentData.name }}</mat-card-title>
                <mat-card-subtitle>
                    <p *ngIf="documentData.datePublish != null">

                        <span>Publicado em: {{ documentData.datePublish | amFromUtc | amLocal | amDateFormat:
                            'DD-MM-YYYY HH:mm'
                            }}</span>

                        Por: <b>{{ documentData.publisherUserName }}</b>


                    </p>
                </mat-card-subtitle>
            </mat-card-header>

            <hr style="margin: 0px;">

            <mat-card-content style="padding: 15px; margin-top: 0px;">


                <div class="card-body">


                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">

                            <div class="card">
                                <div class="card-header p-2" color="txt-primary">

                                    <div class="float-left">
                                        <p class="text-muted f-w-600 f-16">
                                            Lista de Signatários
                                        </p>
                                    </div>



                                    <div class="float-right">
                                        <!-- <button mat-raised-button
                                            (click)="addSigner(addSignerDialog)">Adicionar Signatário</button> -->

                                    </div>



                                </div>


                                <div class="p-20">

                                    <table class="data-table" [dataSource]="documentData.signatures" mat-table>

                                        <ng-container matColumnDef="documentNumber">
                                            <th mat-header-cell *matHeaderCellDef disableClear> Documento </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                {{ signature.documentNumber }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="dateSigned">
                                            <th mat-header-cell *matHeaderCellDef disableClear> Data de Assinatura </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                <span *ngIf="signature.dateSigned">
                                                    {{ signature.dateSigned |
                                                    amFromUtc | amLocal | amDateFormat:
                                                    'DD-MM-YYYY HH:mm' }}
                                                </span>

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="userName">
                                            <th mat-header-cell *matHeaderCellDef disableClear> Nome </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                {{ signature.userName }}
                                            </td>
                                        </ng-container>







                                        <ng-container matColumnDef="cmd">
                                            <th mat-header-cell *matHeaderCellDef disableClear> </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                <div style="display: flex; float: right;">
                                                    <button mat-raised-button color="primary"
                                                        (click)="signDoc(signature)"
                                                        *ngIf="signature.dateSigned == null && documentData.allowedSignatureId == signature.id">
                                                        Assinar Documento
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            class="element-row">
                                        </tr>
                                    </table>








                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">



                        <div class="col-sm-6 col-md-6 col-xs-12">

                            <div class="card">
                                <div class="card-header p-1" color="txt-primary">

                                    <div class="float-left">

                                        <p class="text-muted f-w-600 f-16"> <i
                                                class="icofont icofont-attachment f-20"></i>
                                            <a (click)="openLink(documentData.urlDocument);" (alt)="documentData.name"
                                                style="cursor: pointer;">
                                                Versão Original</a>
                                        </p>
                                    </div>



                                </div>


                                <div class="p-10" style="height: 285px;">



                                    <!-- <a href="javascript:" (click)="openLink(documentData.urlDocument);" target="_blank" (alt)="documentData.name">
          
                              <img class="card-img-top img-fluid img-thumbnail" [src]="base64Img" style="height:280px; object-fit: contain;">
          
                            </a> -->

                                    <iframe [src]='urlDocument' width="100%" height="280" frameborder="0"
                                        *ngIf="documentData.urlDocument" webkitallowfullscreen mozallowfullscreen
                                        allowfullscreen>
                                    </iframe>





                                </div>
                            </div>

                        </div>



                        <div class="col-sm-6 col-md-6 col-xs-12">

                            <div class="card">
                                <div class="card-header p-1" color="txt-primary">

                                    <div class="float-left">

                                        <p class="text-muted f-w-600 f-16"> <i
                                                class="icofont icofont-certificate f-20"></i>
                                            <a href="javascript:" (click)="openLink(documentData.urlPublicDocument);"
                                                style="cursor: pointer;" (alt)="documentData.name">
                                                Versão Assinada</a>
                                        </p>
                                    </div>




                                </div>


                                <div class="p-10" style="height: 285px;">



                                    <!-- <a href="javascript:" (click)="openLink(documentData.urlPublicDocument);" target="_blank" (alt)="documentData.name">
          
                              <img class="card-img-top img-fluid img-thumbnail" [src]="base64ImgSigned" style="height:280px; object-fit: contain;">
          
                            </a> -->


                                    <iframe [src]='urlPublicDocument' width="100%" height="280" frameborder="0"
                                        *ngIf="documentData.urlPublicDocument" webkitallowfullscreen mozallowfullscreen
                                        allowfullscreen>
                                    </iframe>




                                </div>
                            </div>

                        </div>










                    </div>



                </div>





            </mat-card-content>






        </mat-card>



    </div>


    <div class="webcomands" *ngIf="documentData" style="margin-top: 15px;">


        <div class="space-between">


            <div>
                <button mat-raised-button [routerLink]="['/signature/document']">Acessar Portal de Assinaturas</button>
            </div>

            <div></div>

            <div>


            </div>


        </div>

    </div>


</section>