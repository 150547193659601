

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';

import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserResumeViewModel } from 'src/app/models/user-model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginDataService } from 'src/app/services/login-service';



@Component({
  selector: 'change-userclient.component',
  templateUrl: './change-userclient.component.html',
  styleUrls: ['./change-userclient.component.scss']
})
export class ChangeUserClientComponent implements OnInit {


  subjectUsers: Subject<any> = new Subject();
  isLoadingUsers = false;
  filteredUsers: any[] = [];

  clientErpSelected: any;
  userId: any;

  @Output()
  public onSaved = new EventEmitter<any>();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService: LoginDataService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeUserClientComponent>) {
    this.userId = data.userId;
  }

  ngOnInit(): void {
    // this.fileInput.nativeElement.click();

    this.subjectUsers
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingUsers = true;
          this.dataService.getClients(value, true).subscribe(ret => {
            this.isLoadingUsers = false;
            this.filteredUsers = ret.data.itens
          }, err => { this.isLoadingUsers = false });
        }
      });

  }

  getUsers(event: any) {

    this.subjectUsers.next(event.target.value);
    // var inp = String.fromCharCode(event.keyCode);
    // if (/[a-zA-Z0-9-_ ]/.test(inp)) {
    //   let searchTerm = '';
    //   searchTerm += event.target.value;
    //   this.subjectUsers.next(searchTerm);
    // }
  }

  userSelected(event: MatAutocompleteSelectedEvent) {
    this.clientErpSelected = event.option.value;
    console.log(this.clientErpSelected);
  }

  userDisplayFn(item) {
    if (item) { return item.name + ' - ' + item.cnpjcpf; }
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.dialogRef.close();
  }


  save() {
    this.dataService.changeErp({ userId: this.userId, erpId: this.clientErpSelected.erpId }).subscribe(ret => {
      if (ret.success)
        this.onSaved.emit(ret.data);

    }, fail => {

    });

  }

}
