import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user-service';
import { LoginDataService } from './login-service';
import { SignalRService } from '../../app/services/SignalRService';
import { CommandType, LocalCommand } from '../models/local-command';


@Injectable()
export class AuthGuardLocal implements CanActivate {

  constructor(private router: Router, private userService: UserService, private loginDataService: LoginDataService, private signalRService: SignalRService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    // this.signalRService.sendCommand(
    //   new LocalCommand(CommandType.CheckStatus, {
    //     pluginVersion: '1.0.0',
    //     unityId: this.userService.selectedUnit.id
    //   })).then(
    //     callBack => {

    //       console.log('CheckStatus....', callBack);


    //     }, error => {

    //     });




    return true;



  }


}
