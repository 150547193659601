import { Component, OnInit } from '@angular/core';

import { UserService } from '../../services/user-service';

import { SharedInfo } from '../../services/shared-info';
import { UnityModel } from '../../models/plan-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  styles: [],
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
  public AppConfig: any;
  public toggledMenu: boolean = false;

  unities: UnityModel[] = [];
  selectedUnit: UnityModel;
  selectedUnitId;

  constructor(
    public userService: UserService, sharedInfo: SharedInfo,  private router: Router,) {
    this.AppConfig = sharedInfo.AppConfig;

    if (this.userService.planInfo) {
      this.unities = this.userService.planInfo.unities;
    }
    this.userService.onUnityChange.subscribe(unity => {
      this.selectedUnit = unity;
      this.selectedUnitId = unity.id;
    });

  }

  selectUnity(event) {
    this.selectedUnit = this.userService.planInfo.unities.find(o => o.id == this.selectedUnitId);
    this.userService.setUnity(this.selectedUnit);
    //window.location.reload();
  }

  toggledmenu() {
    this.toggledMenu = false;
    setTimeout(() => {
      this.toggledMenu = true;
    }, 1000);
  }

  ngOnInit() {
    setTimeout(() => {
      this.selectedUnit = this.userService.selectedUnit;
      this.selectedUnitId = this.userService.selectedUnit.id;
    }, 50);
  }

  signout() {
    this.userService.logout();
  }

  profile() {
    this.router.navigate(['app/profile']);
  }
}
