
import { FinOperationType, InstalmentStatus, InvoiceOrigin } from '../../../../enums/enums';
import { Person } from '../../catalog/person/person-model';

export class PayInstalmentCommand {
    instalments: PayInstalmentValue[];
    debitCenterId: string;
    accountId: string;
    operationDate: string;
}

export class PayInstalmentValue {
    id: string;
    value: number;
}



export class InstalmentResumeViewModel {
    id: string;
    invoiceId: string;
    personBilledId: string;
    personBilledName: string;
    personResume: Person;
    invoiceNumber: string;
    operation: FinOperationType;
    origin: InvoiceOrigin;
    status: InstalmentStatus;
    operationDate: string;
    dueDate: string;
    totalInstallments: number;
    totalValue: number;
    source: string;
    description: string;
    instGenNumber: number;
    number: number;
    value: number;
}

export enum FilterInstalmentCommandDataType {
    NoFilter = 0,
    OperationDate = 1,
    DueDate = 2
}