import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable } from 'rxjs';
import * as moment from 'moment';

let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class StockMovementDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('stockMovement', 
      { 
        startDate: new Date(startDate.setMonth(startDate.getMonth()-1)),
        endDate:  endDate,
      }, sharedInfo, userService, http);

    this.routerTitle = 'Estoque / Movimentação de Produtos';
  }


  getColums() {
    return [
      { columnDef: 'seqCode', header: 'Código', cell: (row) => `${row.seqCode}` },
      { columnDef: 'date', header: 'Data', cell: (row) => `${row.date}` },
      { columnDef: 'movementType', header: 'Operação', cell: (row) => `${row.movementType}%` },
      { columnDef: 'movementOrigin', header: 'Origem', cell: (row) => `${row.movementOrigin}%` },
      { columnDef: 'userName', header: 'Usuário', cell: (row) => `${row.userName}%` },
    ];;
  }

  getNewSequencialCode(): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.serviceUrl + '/' + this.tableName + '/getsequencialcode?UnityId=' + this.userService.selectedUnit.id);
  }

  insertMovement(data): Observable<any> {
    let roteUrl = '/' + this.tableName + '/insert';
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, data);
  }

}
