<section class="container-fluid with-maxwidth chapter">

  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <span class="active">{{ appService.routerTitle }}</span>
    </li>
  </ul>



  <div *ngIf="isMobile === true && appService.options?.timeLimeMode == false">

    <div class="card card-table-Mobile"
      [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
      <mat-nav-list>
        <mat-list-item *ngFor="let item of data">
          <a matLine href="javascript:;" (click)="openEdit(item.id)">
            <!-- <mat-icon [class]="link.CSSClass"></mat-icon> -->
            <span class="link-caption">{{ item.name }}</span>
          </a>
          <!-- <h4 matline> {{ link.info }} </h4> -->

          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-nav-list>
    </div>


    <div class="fab-buttons">
      <button mat-fab color="primary" class="add-button" (click)='addItem(); $event.stopPropagation();'>
        <mat-icon (click)='addItem(); $event.stopPropagation();'>add</mat-icon>
      </button>
    </div>

  </div>


  <div class="webcomands row">
    <div class="col-md-8">


      <div class="row">

        <div class="col-xs-12 col-md-4">
          <mat-form-field class="full-width">
            <mat-icon matSuffix class="material-icons">search</mat-icon>
            <input matInput #strFindControl placeholder="Buscar" [(ngModel)]="strFind" ngDefaultControl
              (ngModelChange)='updateFilter($event)'>
          </mat-form-field>
        </div>


        <div class="col-xs-12 col-md-3">
          <mat-form-field *ngIf="appService?.filterData?.startDate != null" class="period-fields">
            <mat-label>Data Inicial</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.startDate"
              (dateChange)="fetch(null)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field *ngIf="appService?.filterData?.endDate != null" class="period-fields">
            <mat-label>Data Final</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.endDate"
              (dateChange)="fetch(null)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>






      </div>



      <a data-toggle="tooltip" title="Busca Avançada" class="search-icon  m-r-15"
        (click)="openModal(filterModal, 'md')">
        <i class="nav-icon i-Magnifi-Glass1"></i>
      </a>
    </div>

    <div class="col-md-4">
      <div class="webcomands_buttons">

        <a mat-raised-button (click)='fetch()' *ngIf="appService.options?.fetch == true">
          <i class="cmd-icon nav-icon i-Reload"></i> Atualizar
        </a>

        <a mat-raised-button color="primary" (click)='addItem()' style="margin-left: 5px;"
          *ngIf="appService.options?.add == true">
          <i class="cmd-icon nav-icon i-Add"></i> Novo Cadastro
        </a>

      </div>

    </div>

  </div>

  <div *ngIf="appService.options?.timeLimeMode == true" class="time-line-content">


    <timeline *ngIf="tableName == 'invoice'">

      <timeline-item *ngFor="let item of data">
        <div
          style="background-color: rgb(255, 255, 255);padding: 10px;box-shadow: 3px 3px 15px 3px #6565656b; max-height: 180px;">

          <p>
            <span class="time-line-title">{{ item.origin | invoiceOrigin }} {{ item.source }}</span>
            <br>
            {{ item.operationDate | date }} - {{ item.operationDate | date : 'HH:mm' }}
          </p>

          <!-- <h4>
                    
                  </h4> -->
          <p>
            {{ item.personBilledName }}

          </p>

          <p>
            <b>{{ item.totalValue | currency: 'BRL'}} - {{ item.totalInstallments }}x</b>
          </p>

          <button mat-stroked-button style="float: right; bottom: 35px;">
            Detalhes</button>

        </div>

      </timeline-item>

    </timeline>

  </div>


  <div class="desktop-table-container"
    *ngIf="appService.options?.tableMode == true && (isTablet === true || isDesktopDevice === true)">

    <mat-card class="card-data-content"
      [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

      <div class="card-table-web">


        <table *ngIf="tableName == 'userPermission'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="name" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{ row.name }} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> E-mail </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{ row.email }} </td>
          </ng-container>

          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Empresa </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]>
               {{ row.clientName }} 
              <br> <span style="font-size: 10px; color: #0075ac;" (click)="changeErp(row.id)">{{ row.clientCNPJCPF }}</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <div style="display: flex; float: right;">
                <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                  <i class="material-icons">edit</i></a>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


        <table *ngIf="tableName == 'receivable'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="dueDate" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Fatura </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.invoiceNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data Vencimento </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.dueDate | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="personBilledName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Cliente </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.personBilledName}} </td>
          </ng-container>


          <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Origem </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.origin}} </td>
          </ng-container>


          <ng-container matColumnDef="totalInstallments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Parcelas </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.number}} / {{row.totalInstallments}} </td>
          </ng-container>


          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Valor Total </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.value | currency: 'BRL'}} </td>
          </ng-container>


          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <!-- <div style="display: flex; float: right;">
              <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                <i class="material-icons">edit</i></a>
            </div> -->
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


        <table *ngIf="tableName == 'person'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="name" matSortDirection="asc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="cnpjcpf">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Documento </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.cnpjcpf && row.cnpjcpf.length <=11"> {{row.cnpjcpf | mask: '000.000.000-99'}}</span>
              <span *ngIf="row.cnpjcpf && row.cnpjcpf.length > 11"> {{row.cnpjcpf | mask: '00.000.000/0000-99'}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id)"><i
                  class="material-icons">edit</i></button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>






        <table *ngIf="tableName == 'stockMovement'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="seqCode" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="seqCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Código </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.code}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.date | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="movementType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Tipo de Movimento </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.movementType | movementType}} </td>
          </ng-container>

          <ng-container matColumnDef="movementOrigin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Origem </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.movementOrigin | movementOrigin}} </td>
          </ng-container>


          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Usuário </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.userName}} </td>
          </ng-container>

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id)"><i
                  class="material-icons">info_outline</i></button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>



        <table *ngIf="tableName == 'productGroup'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="name" matSortDirection="asc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome do Grupo </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id)"><i
                  class="material-icons">edit</i></button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>





        <table *ngIf="tableName == 'product'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="name" matSortDirection="asc" matSortDisableClear>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Código </th>
            <td mat-cell *matCellDef="let row"> {{row.code}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Descrição do Produto </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="ncm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> NCM </th>
            <td mat-cell *matCellDef="let row"> {{row.ncm}} </td>
          </ng-container>

          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Estoque </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.stock}} </td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Preço </th>
            <td mat-cell *matCellDef="let row">{{row.price | currency: 'BRL'}}</td>
          </ng-container>

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id)"><i
                  class="material-icons">edit</i></button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>



        <table *ngIf="tableName == 'order'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="seqCode" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="seqCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Código </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.code}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.date | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome do Cliente </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.clientName}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Usuário </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.userName}} </td>
          </ng-container> -->



          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Valor Total </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.totalValue | currency: 'BRL'}} </td>
          </ng-container>

          <ng-container matColumnDef="statusDelivery">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Situação </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]>

              <span *ngIf="row.statusDelivery == 10 && row.statusSincPdv != 1" class="stOrder-billed">
                <i class="material-icons">attach_money</i>
                {{row.statusDelivery | statusDelivery}}

              </span>

              <span *ngIf="row.statusDelivery == 1" class="stOrder-preparing">
                <i class="material-icons">history_toggle_off</i>
                {{row.statusDelivery | statusDelivery}}
              </span>

              <span *ngIf="row.statusDelivery == 2" class="stOrder-delivering">
                <i class="material-icons">local_shipping</i>
                {{row.statusDelivery | statusDelivery}}

              </span>

              <span *ngIf="row.statusSincPdv == 1" class="stOrder-delivering">
                <i class="material-icons">autorenew</i>
                {{row.statusSincPdv | statusSincPdv}}

              </span>


            </td>
          </ng-container>

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <div style="display: flex; float: right;">
                <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                  <i class="material-icons">edit</i></a>

                <!-- <button mat-raised-button class="btn-sm btn_cmd btn_done" (click)="openEdit(row.id)">
                  <i class="material-icons">done_all</i></button> -->
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


        <table *ngIf="tableName == 'invoice'" class="data-table" [dataSource]="data" mat-table matSort
          matSortActive="seqCode" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Código </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.code}} </td>
          </ng-container>

          <ng-container matColumnDef="operationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.operationDate | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="personBilledName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Pessoa </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.personBilledName}} </td>
          </ng-container>


          <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Operação </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.operation | financeOperation}} </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Origem </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.source}} </td>
          </ng-container>


          <ng-container matColumnDef="totalInstallments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Parcelas </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.totalInstallments}} </td>
          </ng-container>


          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Valor Total </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.totalValue | currency: 'BRL'}} </td>
          </ng-container>


          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <div style="display: flex; float: right;">
                <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                  <i class="material-icons">edit</i></a>

                <!-- <button mat-raised-button class="btn-sm btn_cmd btn_done" (click)="openEdit(row.id)">
                        <i class="material-icons">done_all</i></button> -->
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>

      </div>




    </mat-card>

    <mat-card class="card-bottom-data-table">
      <div>

        <div style="float: left; padding: 15px;" *ngIf="tableName == 'person'">
          <div class="row" style="display: flex;">
            <div class="col-md-2">
              <mat-checkbox [(ngModel)]="appService.filterData.isClient" (ngModelChange)="fetch(null)" ngDefaultControl>
                Cliente</mat-checkbox>
            </div>

            <div class="col-md-3">
              <mat-checkbox [(ngModel)]="appService.filterData.isProvider" (ngModelChange)="fetch(null)"
                ngDefaultControl>Fornecedor</mat-checkbox>
            </div>

            <div class="col-md-4">
              <mat-checkbox [(ngModel)]="appService.filterData.isShipping" (ngModelChange)="fetch(null)"
                ngDefaultControl>Transportadora</mat-checkbox>
            </div>

            <div class="col-md-3">
              <mat-checkbox [(ngModel)]="appService.filterData.isSalesman" (ngModelChange)="fetch(null)"
                ngDefaultControl>Vendedor</mat-checkbox>
            </div>

          </div>

        </div>

        <mat-paginator class="tbPaginator" #tbPaginator [length]="resultsLength" [pageSize]="25" style="float: right;"
          [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>

      </div>
    </mat-card>



  </div>

  <!-- Modal Filter template -->
  <ng-template #filterModal let-c="close" let-d="dismiss">
    <button type="button" class="close" (click)="d('Cross click')">&times;</button>
    <div>
      <app-person-filter (setFilter)="setFilter($event);" (okClicked)="d('Ok click')" [filterData]="filterData">
      </app-person-filter>
    </div>
  </ng-template>


</section>