export class LocalCommand {
    constructor(_commandType: CommandType, _commandValue: any) {
        this.commandType = _commandType;
        this.commandValue = JSON.stringify(_commandValue);
    }
    commandType: CommandType;
    commandValue: string;
}

export enum CommandType {

    CheckStatus = 1,

    GenerateInvoice = 10,

    Product_Get = 20,


    Order_Get = 99,
    Order_Save = 100,
    Order_AddItem = 101,
    Order_DeleteItem = 102,
    Order_PrintDav = 105,
    Order_Pay = 109,
    Order_Close = 110,
    Order_Cancel = 111,

    Certificate_Get = 200,
    SignDocument = 201,

    GetOrders = 254,
    GetUnsynchronizedOrders = 255,
    SincData = 256,
    UploadData = 257,
}