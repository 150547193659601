
<div class="md-content-modal">

    <div class="card-header">
        <span class="title">{{ 'document_add.title' | translate }}</span>
    </div>

    <div class="card-body">


        <div class="p-24 m-0">


            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'document_add.main_signer' | translate }}</mat-label>
                    <input matInput required #txtSignerUser [matAutocomplete]="auto" [(ngModel)]="signerUser"
                        (input)="getUsers($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="userDisplayFn"
                    (optionSelected)="userSelected($event)">
                    <mat-option *ngIf="isLoadingUsers" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingUsers">
                        <mat-option *ngFor="let item of filteredUsers" [value]="item">
                            <span>{{ item.cpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>


            <div class="col-md-12" style="padding-bottom: 30px;">

                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let item of signers" [selectable]="true" [removable]="true"
                        (removed)="removeUser(item)">
                        {{item.userName}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <!-- <input
                      placeholder="Buscar pessoas..."
                      #fruitInput
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"> -->
                </mat-chip-list>

            </div>

            <!-- 
            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'document_add.method' | translate }}</mat-label>
                    <mat-select [(ngModel)]="method" required>
                        <mat-option [value]="mt.method" *ngFor="let mt of methodOptions" >{{ 'document_add.method_' + mt.method | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'document_add.prepare_mode' | translate }}</mat-label>
                    <mat-select [(ngModel)]="prepareMode" required>
                        <mat-option [value]="1">{{ 'document_add.prepare_mode_normal' | translate}}</mat-option>
                        <mat-option [value]="2">{{ 'document_add.prepare_mode_divide_page' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <hr>
            <div class="col-md-12">
                <mat-label style="margin-bottom: 12px;">{{ 'document_add.method' | translate }}</mat-label>
                <div align="start">
                    <button mat-raised-button class="btn-cmd-bottom btn btn-rounded ladda-button m-1"
                        [ngStyle]="{'background-color': method == mt.method ? 'rgb(63 215 141)' : 'rgb(245 245 245)'}"
                        *ngFor="let mt of methodOptions" (click)="method = mt.method;">
                        <i class="material-icons">{{ mt.icon }} </i><br>
                        {{ 'document_add.method_' + mt.method | translate }}
                    </button>
                </div>
            </div>
            <hr>

            <div class="col-md-12">
                <mat-slide-toggle color="primary" [(ngModel)]="insertProtocolPage" class="text-muted"
                    style="margin: 20px 0;">
                    <span>Adicionar Página de Protocolo</span>
                </mat-slide-toggle>
            </div>






            <div class="col-md-12">


                <form [formGroup]="formGroug" fxLayout="column" fxFlex>






                    <div class="form-group">
                        <input type="file" #fileInput formControlName="file" (change)="changeFile(fileInput.files)" />

                        <span *ngIf="formErrors | findErrors: 'file'" class="invalid-feedback"> {{ formErrors |
                            findErrors:
                            'file'}}
                        </span>
                    </div>


                </form>

            </div>



            <div class="row">



                <!-- 
                <div class="attachment" class="col-md-3 col-xl-3" *ngIf="fileName != null">
        
                  <div>
                    <span class="filename" [innerHTML]="fileName"></span>
                    <span class="size" [innerHTML]="fileSize"></span>
                  </div>
        
                </div> -->


                <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
                    {{progress}}%
                </span>

                <span style="font-weight:bold;color:green;" *ngIf="message">
                    {{message}}
                </span>


            </div>




        </div>

    </div>


    <div class="bottom-cmd card-footer">
        <div class="space-between">
            <div>
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
            </div>
            <div></div>
            <div>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk
                    [disabled]="loading || fileName == null" (click)="submit();">Salvar
                </button>
            </div>
        </div>
    </div>


</div>