<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">

        <div class="card-body">

            <div class="form-group col-md-12">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'product-add-edit.description' | translate }}</mat-label>
                    <input required matInput type="text" #txtName [(ngModel)]="data.name">
                    <mat-error>
                        {{ formErrors | findErrors: 'name'}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false">
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                    (click)="submit();">Salvar</button>
            </div>


        </div>

    </mat-card>
</div>