import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/AuthGuard';
import { DashboardPage } from './dashboard.page';
import { HomePageComponent } from './home-page/home-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ViewReceivableComponent } from './views/financial/receivable/view-receivable/view-receivable.component';
import { ViewTaskProcessComponent } from './views/process/taskprocess/view-taskprocess/view-taskprocess.component';
import { ViewComponent } from './views/view/view.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardPage,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'app/home', component: HomePageComponent },
      { path: 'app/profile', component: ProfilePageComponent },

      { path: 'app/:tableName', component: ViewComponent },     
      { path: 'app/:tableName/:id', component: ViewComponent },
      { path: 'invoice/receivable', component: ViewReceivableComponent },
      { path: 'invoice/receivable/:id', component: ViewReceivableComponent },
      
      { path: 'process/taskprocess', component: ViewTaskProcessComponent },
      { path: 'process/taskprocess/:id', component: ViewTaskProcessComponent },
    ],
    canActivate: [AuthGuard]
  }
  // {
  //   path: 'view',
  //   loadChildren: () => import('./sign-up/sign-up.module').then( m => m.ViewPageModule)
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardPageRoutingModule {}
