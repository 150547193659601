import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from '../../../../services/shared-info';
import { UserService } from '../../../../services/user-service';
import { Observable } from 'rxjs';
import * as moment from 'moment';


let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class TaskProcessService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('TaskProcess', 
      { 
        startDate: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
        endDate:  new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0),
      }, sharedInfo, userService, http, { add: true, fetch: true, tableMode : true });

    this.routerTitle = 'Lista de Tarefas';
  }


  getColums() {
    return [
      { columnDef: 'name', header: 'Título', cell: (row) => `${row.name}` },
      
      { columnDef: 'firstDateOfTask', header: 'Data Inicio', cell: (row) => `${row.firstDateOfTask}` },
      { columnDef: 'endDate', header: 'Data Final', cell: (row) => `${row.endDate}` },
      { columnDef: 'repetitionPeriod', header: 'Repetição', cell: (row) => `${row.repetitionPeriod}` },
    
      { columnDef: 'personName', header: 'Pessoa', cell: (row) => `${row.personName}%` },

    ];;
  }

}
