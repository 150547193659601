import { City } from "./city-model";

export class Address  {
    id: any;
    streetType: number;
    street: string;
    number: string;
    village: string;
    complement: string;
    zipCode: string;
    personId: string;
    //person: Person;
    cityCode: number;
    city: City;
    streetAll: string;
    removed: boolean = false
}

export enum AddressType {
    Main = 0,
    Invoice = 1,
    Delivery = 2
}