import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShDataService } from '../services/pageSharedFunctions';
import { SharedInfo } from '../services/shared-info';
import { UserService } from '../services/user-service';
import { UrlOrign } from 'src/app/enums/urlorign';
import * as moment from 'moment';


export class GenericDataService implements ShDataService {
  public tableService: any;
  public routerTitle;

  constructor(
    public tableName: string,
    public filterData: any,
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient,
    public options: any = { add: true, fetch: true, timeLimeMode: false, tableMode: true, urlOrign: UrlOrign.Domain }) {
    this.tableName = tableName;
    this.options = options;
  }

  

  public get url() : string {
    switch (this.options.urlOrign) {

      case UrlOrign.Auth:
        return this.sharedInfo.authUrl;
    
      default:
        return this.sharedInfo.serviceUrl;
    }
  }

  

  getColums(): any[] {
    return this.tableService.getColums();
  }

  getData(filterData): Observable<any> {
    filterData['unityId'] = this.userService.selectedUnit.id;

    // if(filterData.startDate){
    //   filterData.startDate = moment(filterData.startDate).format('YYYY-MM-DD')
    // }

    // if(filterData.endDate){
    //   filterData.endDate = moment(filterData.endDate).format('YYYY-MM-DD')
    //   console.log(filterData.endDate);
    // }


    console.log(filterData);
    return this.http
      .put<any>(this.url + '/' + this.tableName + '/getdata', filterData);
  }

  findData(strFind): Observable<any> {
    var filterData = {
      unityId: this.userService.selectedUnit.id,
      strFind: strFind || '',
      page: 1,
      take: 20
    };
    return this.http
      .put<any>(this.url + '/' + this.tableName + '/getdata', filterData);
  }


  getItemDetails(id: string): Observable<any> {
    return this.http
      .get<any>(this.url + '/' + this.tableName + '/get/' + id);
  }


  insertUpdateItem(data): Observable<any> {
    let roteUrl = '/' + this.tableName + '/insert';
    if (data.id) {
      roteUrl = '/' + this.tableName + '/update';
    }

    return this.http
      .post<any>(this.url + roteUrl, { mainData: data });
  }


  delete(id: string): Observable<any> {
    return this.http
      .post<any>(this.url + '/' + this.tableName + '/delete', { id: id });
  }

}
