import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit, HostListener, ElementRef, TemplateRef, OnDestroy } from '@angular/core';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, startWith, timeout } from 'rxjs/operators';

import { UserService } from 'src/app/services/user-service';
import { MatTableDataSource } from '@angular/material/table';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SignalRService } from 'src/app/services/SignalRService';
import { HubConnectionState } from '@aspnet/signalr';
import { CommandType, LocalCommand } from 'src/app/models/local-command';
import { InsertInvoiceOrderModel, LocalOrderPayment, Order, OrderItem, OrderModel, OrderPayCommand } from 'src/app/dashboard/views/commercial/order-add-edit/order-model';
import { OrderDataService } from 'src/app/dashboard/views/commercial/order-add-edit/order.service';
import { Product } from 'src/app/dashboard/views/stock/product-add-edit/product-model';
import { Subject, Subscription } from 'rxjs';
import { PersonFilterModel } from 'src/app/dashboard/views/catalog/person/person-filter';
import { Person } from 'src/app/dashboard/views/catalog/person/person-model';
import { ProductDataService } from 'src/app/dashboard/views/stock/product-add-edit/product.service';
import { PersonDataService } from 'src/app/dashboard/views/catalog/person/person-add-edit/person.service';

import { v4 as uuidv4 } from 'uuid';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { InvoicePaymentType, PaymentsActives } from 'src/app/dashboard/views/financial/invoice/invoice-enums';
import * as moment from 'moment';
import { OrderPaymentModalComponent } from 'src/app/comp/order-payment-modal/order-payment-modal.component';


@Component({
  selector: 'app-pdv',
  templateUrl: './pdv.component.html',
  styleUrls: ['./pdv.component.scss'],
  animations: [SharedAnimations]
})
export class PDVComponent implements AfterViewInit, OnInit, OnDestroy, AdComponent {

  //#region <Prop>

  title: string = '';
  localStName = 'currentPdvSale';

  keyUpSubscription: Subscription;
  public onKeyUpEvent: EventEmitter<KeyboardEvent>;
  @Output() public onSaved = new EventEmitter<any>();
  @Input() tableName: string;
  @Input() data: OrderModel;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtQuantity') txtQuantity;
  @ViewChild('txtFindProd') txtFindProd;
  @ViewChild('txtFindClient') txtFindClient;
  @ViewChild('tableItens') private tableItens: ElementRef;
  @ViewChild('setInvoiceDialog') setInvoiceDialog: TemplateRef<any>;

  itensColumns = ['productCode', 'productDescription', 'cmd'];

  loading = false;
  initializing = true;

  plugInState;
  plugInFailure = false;
  plugInFailure_reconnectInterval = 0;


  formErrors: DomainNotification[] = [];

  info = '';
  isLoadingProd = false;
  productFind: Product;
  quantityInsert: number = 1;
  filteredProducts: any[] = [];
  subjectProducts: Subject<any> = new Subject();

  isLoadingClients = false;
  filteredClients: any[] = [];

  personSearchModes: PersonFilterModel = new PersonFilterModel();

  subjectClients: Subject<any> = new Subject();

  itensDataSource = new MatTableDataSource<OrderItem>();

  setStatusMode: boolean = false;

  personBilled: Person;


  setPaymentDialogRef: MatDialogRef<OrderPaymentModalComponent>;

  //#endregion


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dataService: OrderDataService,
    private productDataService: ProductDataService,
    private personDataService: PersonDataService,
    private deviceService: DeviceDetectorService,
    private sharedInfo: SharedInfo,
    private userService: UserService,
    private signalRService: SignalRService,
    private ngxLoader: NgxUiLoaderService) {

    this.title = 'Ponto de Vendas';
    this.localStName = this.userService.selectedUnit.id + this.localStName;

    this.onKeyUpEvent = new EventEmitter<KeyboardEvent>();
    this.keyUpSubscription = this.onKeyUpEvent.subscribe((event: KeyboardEvent) => {

      //console.log('event', event);
      if (this.loading == true) {
        return;
      }

      if (event.altKey == true) {
        if (event.key.toLowerCase() == 's') {
          this.sinckData();
        }
      }

      if (event.altKey == true) {
        if (event.code == 'P') {
          this.printLocal();
        }
      }

      if (event.code == 'F2') {
        this.txtFindProd.nativeElement.focus();
      }

      if (event.altKey == true) {
        if (event.code == 'F5') {
          event.preventDefault();
          this.confirmCancel();
        }
      }

      if (event.code == 'F10') {
        this.printLocal();
      }

      if (event.code == 'F4') {
        this.signalRService.sendCommand(
          new LocalCommand(CommandType.Certificate_Get, {}), true).then(
            callBack => {

              if (callBack) {
                console.log('callBack', JSON.parse(callBack));
              }


            }, error => {

            });


      }

      if (event.code == 'F9') {
        if (this.dialog.openDialogs.length <= 0) {

          console.log(this.data.mainData);
          if (this.data.mainData.payed == true) {

            // if (this.data.mainData.clientResume) {
            //   this.data.invoideCmd.personBilledId = this.data.mainData.clientResume.id;
            //   this.personBilled = this.data.mainData.clientResume;
            // }
            // this.openDialogWithTemplateRef(this.setInvoiceDialog);
            Swal.fire("O'ops", 'O pagamento já foi realizado por completo!')

          } else {


            this.setPaymentDialogRef = this.dialog.open(OrderPaymentModalComponent, {
              //panelClass: 'mat-dialog-fix',
              disableClose: true,
              data: { order: this.data.mainData }
            });

            this.setPaymentDialogRef.componentInstance.onSetPayment.subscribe(command => {
              this.onSetPayment(command);
            });


          }

          //this.saveOrderLocal();

        }
      }

      if (event.keyCode === 13) {
        if (this.dialog.openDialogs.length <= 0 && this.data?.mainData?.payed == true) {
          this.finalizeSale();
        }
      }


    });
  }


  //#region <Events>

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    var loader = this.ngxLoader.getLoader();
    if (loader && loader.tasks && loader.tasks['fg-default']) {
      return;
    } else {
      //console.log(event)
      this.onKeyUpEvent.emit(event);
    }
  }


  ngOnDestroy() {
    this.keyUpSubscription.unsubscribe();
  }

  ngOnInit() {

    this.initialize();

  }

  initialize() {


    this.data = new OrderModel();

    this.data.invoideCmd = new InsertInvoiceOrderModel();
    this.data.invoideCmd.firstDueDate = new Date();
    this.personBilled = null;


    if (this.sharedInfo.AppConfig.offLineMode === true) {

      this.connectPlugin();

      var checkConnInterval = setInterval(() => {

        this.plugInState = this.signalRService.state();

        if (this.loading) {
          //clearInterval(checkConnInterval);
          return;
        }

        if (this.signalRService.state() == HubConnectionState.Disconnected) {
          this.dialog.closeAll();
          this.plugInFailure = true;
          this.initializing = true;

          this.plugInFailure_reconnectInterval++;

          this.info = 'Tentar novamente em: ' + (30 - this.plugInFailure_reconnectInterval).toString() + 's';

          if (this.plugInFailure_reconnectInterval >= 30) {
            this.plugInFailure_reconnectInterval = 0;
            this.connectPlugin();
          }
        } else {
          if (this.plugInFailure_reconnectInterval > 0 && this.plugInFailure == true) {
            this.connectPlugin();
          }
        }

      }, 1000);

    }




    this.subjectProducts
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {

          if (this.sharedInfo.AppConfig.offLineMode === true) {


            this.isLoadingProd = true;
            this.signalRService.sendCommand(
              new LocalCommand(CommandType.Product_Get, {
                unityId: this.userService.selectedUnit.id,
                strFind: value || '',
              }), false).then(
                callBack => {

                  console.log('Product_Get:..', callBack);

                  this.isLoadingProd = false;
                  this.filteredProducts = JSON.parse(callBack);


                }, error => {
                  this.isLoadingProd = false;
                });


          } else {

            this.isLoadingProd = true;
            this.productDataService.findData(value).subscribe(ret => {
              this.isLoadingProd = false;
              this.filteredProducts = ret.data.itens
            }, err => { this.isLoadingProd = false });

          }


        }
      });


    this.subjectClients
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingClients = true;
          this.personSearchModes.strFind = value;
          this.personDataService.findPersonData(this.personSearchModes).subscribe(ret => {
            this.isLoadingClients = false;
            this.filteredClients = ret.data.itens
          }, err => { this.isLoadingClients = false });
        }
      });

    this.setCodeFocus();

  }

  offLineMode() {
    this.sharedInfo.AppConfig.offLineMode = true;
    this.sharedInfo.saveConfig();
    this.initialize();
  }


  ngAfterViewInit(): void {
    //console.log('idForEdit', this.idForEdit);
  }


  productSelected(event) {
    this.txtQuantity.nativeElement.focus();
    this.txtQuantity.nativeElement.select();
  }

  clientSelected(event) {
    this.data.mainData.clientId = this.data.mainData.clientResume.id;
    setTimeout(() => {
      this.saveOrderLocal();
    }, 150);
  }

  transpSelected(event) {
    this.data.mainData.transporterId = this.data.mainData.transporterResume.id;
  }

  personBilledSelected(event) {
    this.data.invoideCmd.personBilledId = this.personBilled.id;
  }

  onQuantityKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      console.log('entered');
      this.addItem();
      this.signalRService.pdvConnect(this.userService.userLoged.email);
    }
  }

  discountValueKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.saveOrderLocal();
    }
  }





  //#endregion


  //#region <GET Data Functions>

  getProducts(event: any) {

    if (event.keyCode === 13) {
      console.log(event);
      if (this.filteredProducts && this.filteredProducts.length > 0) {
        this.productFind = this.filteredProducts[0];
        if (this.productFind.code == event.target.value) {
          this.productSelected(this.productFind);
        }
      }

      return;
    }

    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectProducts.next(searchTerm);
    }
  }

  getClients(event: any, mode: string = '') {

    this.personSearchModes = new PersonFilterModel();

    switch (mode) {
      case 'isShipping':
        this.personSearchModes.isShipping = true;
        break;

      default:
        break;
    }

    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectClients.next(searchTerm);
    }
  }

  //#endregion


  //#region <Itens Functions>

  addItem() {
    if (this.productFind != null) {
      if (this.data.itens == null) {
        this.data.itens = [];
      }
      var addItem = new OrderItem();
      addItem.id = uuidv4();
      addItem.productCode = this.productFind.code;
      addItem.productId = this.productFind.id;
      addItem.productDescription = this.productFind.name;
      addItem.quantity = this.quantityInsert;
      addItem.unitValue = this.productFind.price;
      addItem.totalValue = this.productFind.price * this.quantityInsert;
      addItem.un = this.productFind.un;
      addItem.unTrib = this.productFind.unTrib;
      addItem.quantTrib = this.productFind.quantTrib;

      addItem.ncm = this.productFind.ncm;
      addItem.cest = this.productFind.cest;
      addItem.number = this.setNumbersOfItens();

      this.insertItem(addItem);

    }
  }

  clearItemFind() {
    this.productFind = null;
    this.filteredProducts = [];
    this.quantityInsert = 1;
    this.txtFindProd.nativeElement.focus();
    this.scrollToBottom();
  }

  insertItem(item: OrderItem) {

    if (this.sharedInfo.AppConfig.offLineMode === true) {

      this.signalRService.sendCommand(
        new LocalCommand(CommandType.Order_AddItem, { item: item, orderId: this.data.mainData.id }), true).then(
          callBack => {
            this.info = this.productFind.name.toUpperCase();
            this.clearItemFind();
            if (callBack) {
              this.parceLocalData(callBack);
            }
          },
          error => {

          });

    } else {

      this.loading = true;
      this.dataService.insertItem({ item: item, orderId: this.data.mainData.id }).subscribe(ret => {
        this.loading = false;
        if (ret.success === true) {
          this.data = ret.data;
          this.itensDataSource.data = this.data.itens;
          this.clearItemFind();
        }
      }, error => {
        this.loading = false;
        const ret = error.error;
        if (ret.errors) {
          this.formErrors = ret.errors;
        }
      });

    }


  }

  confirmDeleteItem(item) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      position: 'center',
      title: `Excluir Item ${item.number}`,
      text: `Confirma a exclusão do item ${item.number} ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim, Excluir',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        this.deleteItem(item);
      } else {
        this.setCodeFocus();
      }

    })
  }

  deleteItem(item) {

    if (this.sharedInfo.AppConfig.offLineMode === true) {

      this.signalRService.sendCommand(
        new LocalCommand(CommandType.Order_DeleteItem, { id: item.id, orderId: this.data.mainData.id }), true).then(
          callBack => {
            this.info = '';
            if (callBack) {
              this.parceLocalData(callBack);
            }
            this.setCodeFocus();
          },
          error => {
            this.setCodeFocus();
          });


    } else {

      this.dataService.deleteItem({ id: item.id, orderId: this.data.mainData.id }).subscribe(ret => {
        this.loading = false;
        this.setCodeFocus();
        if (ret.success === true) {
          this.data = ret.data;
          this.itensDataSource.data = this.data.itens;
        }
      }, error => {
        this.loading = false;
        this.setCodeFocus();
        const ret = error.error;
        if (ret.errors) {
          this.formErrors = ret.errors;
        }
      });

    }

  }

  //#endregion


  //#region <Sale Functions>

  newSale() {

    this.info = '';

    this.data = new OrderModel();
    this.data.mainData = new Order();
    this.data.mainData.id = uuidv4();
    this.data.mainData.unityId = this.userService.selectedUnit.id;
    this.data.mainData.userId = this.userService.userLoged.id;
    this.data.mainData.userName = this.userService.userLoged.name;

    this.data.invoideCmd = new InsertInvoiceOrderModel();
    this.data.invoideCmd.firstDueDate = new Date();
    this.data.payments = [];
    this.personBilled = null;

    this.itensDataSource.data = this.data.itens;

    this.data.mainData.userId = this.userService.userLoged.id;
    this.data.mainData.userName = this.userService.userLoged.name;
    this.data.mainData.erpId = this.userService.userLoged.erpId;

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_Save,
        {
          order: this.data.mainData,
          itens: this.data.itens,
        }), true).then(callBack => {
          this.info = '*** CAIXA LIVRE ***';
          this.parceLocalData(callBack);

        }, error => { });

  }


  onSetPayment(cmd: OrderPayCommand) {

    console.log('this.payCommand', cmd);

    if (cmd && cmd.payment.value > 0 && cmd.payment.paymentType >= 0) {
      this.signalRService.sendCommand(
        new LocalCommand(CommandType.Order_Pay, cmd), true).then(callBack => {
          console.log(callBack);
          this.parceLocalData(callBack);
          this.chechStatusInfo();
          this.setPaymentDialogRef.close();
        }, error => { });
    }
  }


  confirmCancel() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      position: 'center',
      title: `Cancelar Venda?`,
      text: `Confirma o cancelamento da venda atual ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim, Cancelar',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        this.cancelSale();
      } else {
        this.setCodeFocus();
      }

    })
  }

  cancelSale() {
    this.info = 'CANCELAR VENDA...';

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_Cancel,
        {
          orderId: this.data.mainData.id,
        }), true).then(callBack => {

          this.info = '*** VENDA CANCELADA ! ***';

          setTimeout(() => {
            this.newSale();
          }, 800);

        }, error => { });

  }


  finalizeSale() {
    this.info = '*** FINALIZANDO A VENDA ***';

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_Close,
        {
          orderId: this.data.mainData.id,
        }), true).then(callBack => {

          setTimeout(() => {
            this.newSale();
          }, 800);

        }, error => { });

  }

  //#endregion


  //#region  <Util Functions>

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  parceLocalData(callBack) {
    var ret = JSON.parse(callBack);
    var orderData = JSON.parse(ret.orderData);
    console.log('callback_OK', ret);

    if (orderData) {
      this.data.mainData = orderData.order;
      this.data.itens = orderData.itens;
      this.data.payments = orderData.payments;
      this.itensDataSource.data = this.data.itens;
      this.setCodeFocus();
    }
  }

  chechStatusInfo() {
    if (this.data.mainData.totalPayments > 0) {
      if (this.data.mainData.payed === true) {
        this.info = '*** PAGAMENTO REALIZADO ***';
      } else {
        this.info = '*** PAGAMENTO EM PROCESSO ***';
      }


    } else if (this.data.mainData.totalValue > 0) {
      this.info = '*** VENDA EM ANDAMENTO ***';

    } else {
      this.info = '*** CAIXA LIVRE ***';

    }
  }

  connectPlugin() {

    this.loading = true;
    this.info = 'Conectando ao Plugin Local...';

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.CheckStatus, {
        pluginVersion: '1.0.0',
        unityId: this.userService.selectedUnit.id
      }), false).then(
        callBack => {

          this.plugInState = this.signalRService.state();

          this.loading = false;
          console.log('CheckStatus....', callBack);

          var retConfig = JSON.parse(callBack);

          console.log('retConfig....', retConfig);

          if (retConfig && retConfig.unityId) {

            this.info = 'Plugin conectado!';
            this.initializing = false;
            this.plugInFailure = false;
            this.plugInFailure_reconnectInterval = 0;
            this.checkCurrentSales();

          } else {

            //this.router.navigate(['app/home']);
            //Abrir configuração do PDV....

            this.plugInFailure = false;
            this.plugInFailure_reconnectInterval = 0;
            this.info = 'Plugin conectado! Acessando PDV...';

            this.initializing = false;
            this.checkCurrentSales();

          }


        }, error => {

          this.info = '# Falha de conexão ao Plugin !';
          this.loading = false;
          this.plugInFailure = true;
          this.plugInState = this.signalRService.state();

        });


  }

  checkCurrentSales() {

    console.log('call CommandType.Order_Get');

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_Get, this.userService.selectedUnit.id), true).then(
        callBack => {

          console.log('Order_Get:..', callBack);

          // if(callBack == "{}") {
          //   this.newSale();
          //   return;
          // }

          try {
            this.parceLocalData(callBack);
            this.chechStatusInfo();

          } catch (error) {

            this.newSale();
          }
        }, error => {

        });
  }



  GetUnsynchronizedOrders() {

    this.signalRService.sendCommand(
      new LocalCommand(CommandType.GetUnsynchronizedOrders, { UnityId: this.userService.selectedUnit.id }), true).then(
        callBack => {

          console.log('GetUnsynchronizedOrders:..', callBack);

          // if(callBack == "{}") {
          //   this.newSale();
          //   return;
          // }

        }, error => {

        });
  }


  sinckData() {
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.SincData, { UnityId: this.userService.selectedUnit.id }), true).then(
        callBack => {

          console.log('SincData', callBack);

          //localStorage.setItem("dnErp.localData", JSON.stringify(JSON.parse(callBack).data));


        }, error => {

        });
  }

  setCodeFocus() {
    setTimeout(() => {
      if (this.deviceService.isMobile() == false && this.txtFindProd) {
        this.txtFindProd.nativeElement.focus();
      }
    }, 150);
  }

  clearFilters() {
    this.filteredClients = [];
    this.filteredProducts = [];
  }

  clientDisplayFn(item: Person) {
    if (item) { return item.cnpjcpf + ' - ' + item.name; }
  }

  itemFindDisplayFn(item: Product) {
    if (item) { return item.code + ' - ' + item.name; }
  }

  setNumbersOfItens(): number {
    var num = 1;
    this.data.itens.forEach(item => {
      item.number = num;
      num++;
    });
    return num;
  }

  scrollToBottom(): void {
    try {
      this.tableItens.nativeElement.scrollTop = this.tableItens.nativeElement.scrollHeight;
    } catch (err) { }
  }


  //#endregion


  //#region <Post Data Functions>

  saveOrderLocal() {
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_Save,
        {
          order: this.data.mainData,
        }), true).then(callBack => {
          this.parceLocalData(callBack);
        }, error => { });

  }

  submit() {

    //this.data.invoideCmd.ordersId = [this.data.mainData.id];
    this.data.invoideCmd.userId = this.userService.userLoged.id;
    this.data.invoideCmd.unityId = this.userService.selectedUnit.id;
    this.data.invoideCmd.personBilledId = this.personBilled != null ? this.personBilled.id : null;

    if (this.sharedInfo.AppConfig.offLineMode === true) {
      this.submitLocal();
    } else {
      this.submitOnLine();
    }

  }

  printLocal() {
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.Order_PrintDav,
        {
          unity: this.userService.selectedUnit,
          order: this.data.mainData,
          itens: this.data.itens
        }), false);
  }

  submitLocal() {
    this.loading = true;
    this.signalRService.sendCommand(
      new LocalCommand(CommandType.GenerateInvoice,
        {
          unity: this.userService.selectedUnit,
          order: this.data.mainData,
          itens: this.data.itens,
          invoideCmd: this.data.invoideCmd
        }), true).then(ok => { this.loading = false }, err => { this.loading = false });
  }

  submitOnLine() {

    this.loading = true;
    this.dataService.insertUpdateOrder(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.newSale();
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });


  }


  //#endregion

}
