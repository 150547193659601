import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AdComponent } from 'src/app/models/ad.component';
import { DomainNotification } from 'src/app/models/domainNotification';
import { SharedInfo } from 'src/app/services/shared-info';
import Swal from 'sweetalert2';
import { Person } from '../../../catalog/person/person-model';
import { InstalmentResumeViewModel } from '../receivable.models';
import { ReceivableDataService } from '../receivable.service';

@Component({
  selector: 'app-add-edit-receivable',
  templateUrl: './add-edit-receivable.component.html',
  styleUrls: ['./add-edit-receivable.component.scss']
})
export class AddEditReceivableComponent  implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: InstalmentResumeViewModel;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtName') txtName;

  loading = false;
  formErrors: DomainNotification[] = [];

  constructor(
    private dataService: ReceivableDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,) {

  }


  ngOnInit(){
    if(this.data == null){
      this.data = new InstalmentResumeViewModel();
      this.title = 'Nova Receita';
    }
    else {
      this.title = 'Editar Receita';
    }
    this.sharedInfo.onChangePopUpTitle.emit(this.title);
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
    //this.btnOk.nativeElement.focus();

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.btnOk.nativeElement.focus();
      }
      else {
        this.txtName.nativeElement.focus();
      }

    }, 150);

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  clientDisplayFn(item: Person) {
    if (item) { return item.cnpjcpf + ' - ' + item.name; }
  }
  
  close() {
    this.onCancelClick.emit(null);
  }

  submit() {
    this.formErrors = [];
    this.loading = true;

    this.dataService.insertUpdateItem(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

    });
  }

}
