import { Pipe, PipeTransform } from '@angular/core';
import { InvoicePaymentType } from '../dashboard/views/financial/invoice/invoice-enums';
import { RepetitionPeriod } from '../dashboard/views/process/taskprocess/taskprocess.models';

@Pipe({
  name: 'movementType'
})
export class MovementTypePipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {
      case 0:
        return 'Entrada';
      case 1:
        return 'Saída';
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'movementOrigin'
})
export class MovementOriginPipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {
      case 0:
        return 'Manual';
      case 1:
        return 'Pedido de Venda';
      default:
        return '';
    }
  }
}


@Pipe({
  name: 'statusDelivery'
})
export class StatusDeliveryPipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {

      case 1:
        return 'Preparando';
      case 2:
        return 'Entregando';
      case 10:
        return 'Faturado';

      default:
        return 'Aberto';
    }
  }
}


@Pipe({
  name: 'statusSincPdv'
})
export class StatusSincPdvPipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {

      case 0:
        return 'Pendente';
      case 1:
        return 'Sincronizando...';
      case 2:
        return 'Sincronizado';

      default:
        return '';
    }
  }
}




@Pipe({
  name: 'financeOperation'
})
export class FinanceOperationPipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {
      case 0:
        return 'Despesa';
      case 1:
        return 'Receita';
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'invoiceOrigin'
})
export class InvoiceOriginPipe implements PipeTransform {
  transform(value: number, args: any[]): string {
    switch (value) {
      case 0:
        return '';
      case 1:
        return 'Venda';
      case 2:
        return 'Compra';
      case 3:
        return 'Contrato';

      default:
        return '';
    }
  }
}


@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {
  transform(value: InvoicePaymentType, args: any[]): string {

    switch (value)
    {
        case InvoicePaymentType.Dinheiro:
          return "Dinheiro";
        case InvoicePaymentType.Cheque:
          return "Cheque";
        case InvoicePaymentType.CartaoCredito:
          return "Cartão de Crédito";
        case InvoicePaymentType.CartaoDebito:
          return "Cartão de Débito";
        case InvoicePaymentType.CreditoLoja:
          return "Crédito Loja";
        case InvoicePaymentType.ValeAlimentacao:
          return "Vale Alimentação";
        case InvoicePaymentType.ValeRefeicao:
          return "Vale Refeição";
        case InvoicePaymentType.ValePresente:
          return "Vale Presente";
        case InvoicePaymentType.ValeCombustivel:
          return "Vale Combustível";
        case InvoicePaymentType.DuplicataMercantil:
          return "Duplicata Mercantil";
        case InvoicePaymentType.BoletoBancario:
          return "Boleto Bancario";
        case InvoicePaymentType.SemPagamento:
          return "Sem Pagamentos";
        case InvoicePaymentType.Outros:
          return "Outros";
        default:
          return InvoicePaymentType[value].toString();
    }

  }
}

@Pipe({
  name: 'paymentTypeIcon'
})
export class PaymentTypeIconPipe implements PipeTransform {
  transform(value: InvoicePaymentType, args: any[]): string {

    switch (value)
    {
        case InvoicePaymentType.Dinheiro:
          return "attach_money";
        case InvoicePaymentType.Cheque:
          return "wysiwyg";
        case InvoicePaymentType.CartaoCredito:
          return "payment";
        case InvoicePaymentType.CartaoDebito:
          return "payment";
        case InvoicePaymentType.CreditoLoja:
          return "";
        case InvoicePaymentType.ValeAlimentacao:
          return "";
        case InvoicePaymentType.ValeRefeicao:
          return "";
        case InvoicePaymentType.ValePresente:
          return "";
        case InvoicePaymentType.ValeCombustivel:
          return "";
        case InvoicePaymentType.DuplicataMercantil:
          return "";
        case InvoicePaymentType.BoletoBancario:
          return "receipt";
        case InvoicePaymentType.SemPagamento:
          return "";
        case InvoicePaymentType.Outros:
          return "";
        default:
          return "";
    }

  }
}