import { NgModule } from '@angular/core';
import { NotificationPipe } from './getErrorByKey.pipe';
import { CapitalizeFirstPipe } from './capitalizefirst.pipe';
import { MovementTypePipe, StatusDeliveryPipe, MovementOriginPipe, FinanceOperationPipe, InvoiceOriginPipe, PaymentTypePipe, PaymentTypeIconPipe, StatusSincPdvPipe } from './converters.pipes';

@NgModule({
    declarations: [
      NotificationPipe,
      CapitalizeFirstPipe,
      MovementTypePipe,
      MovementOriginPipe,
      StatusDeliveryPipe,
      StatusSincPdvPipe,
      FinanceOperationPipe,
      InvoiceOriginPipe,
      PaymentTypePipe,
      PaymentTypeIconPipe


    ],
    imports     : [],
    exports     : [
      NotificationPipe,
      CapitalizeFirstPipe,
      MovementTypePipe,
      MovementOriginPipe,
      StatusDeliveryPipe,
      StatusSincPdvPipe,
      FinanceOperationPipe,
      InvoiceOriginPipe,
      PaymentTypePipe,
      PaymentTypeIconPipe
    ]
})

export class CustomPipesModule {

 }
