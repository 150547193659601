import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/AuthGuard';
import { DashboardSignaturePage } from './dashboard.page';
import { SignatureHomePageComponent } from './home-page/home-page.component';
import { DetailsDocumentComponent } from './views/document/details-document/details-document.component';
import { PrepareDocumentComponent } from './views/document/prepare-document/prepare-document.component';
import { PublicSignComponent } from './views/document/public-sign/public-sign.component';
import { ViewDocumentComponent } from './views/document/view-document/view-document.component';

const routes: Routes = [
  {
    path: 'signature',
    component: DashboardSignaturePage,
    children: [
      { path: '', redirectTo: 'signature/document', pathMatch: 'full' },
      { path: 'home', component: SignatureHomePageComponent },
      { path: 'document', component: ViewDocumentComponent },
      { path: 'document/view/:id', component: DetailsDocumentComponent },
      { path: 'document/edit/:id', component: PrepareDocumentComponent },
    ],
    canActivate: [AuthGuard]
  },

  {
    path: 'public',
    component: DashboardSignaturePage,
    children: [
      { path: 'signdoc', component: PrepareDocumentComponent },
    ],
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardSignaturePageRoutingModule {}
