import { Component, OnInit, ComponentFactoryResolver, ViewChild, Renderer2, HostListener } from '@angular/core';
import { AdItem } from 'src/app/models/ad-item';
import { AdComponent } from 'src/app/models/ad.component';
import { SharedInfo } from 'src/app/services/shared-info';
import { FormEditDirective } from './form-container.directive';
import { MatDialog } from '@angular/material/dialog';
//import { flipAnimation } from 'angular-animations';

@Component({
  selector: 'app-modal-overlay',
  templateUrl: './modal-overlay.component.html',
  styleUrls: ['./modal-overlay.component.scss']
  // animations: [
  //   flipAnimation(),
  // ]
})
export class ModalOverlayComponent implements OnInit {

  public AppConfig: any;
  title: string = '';
  currentForm: AdItem<any>;
  toggledMenu: boolean = false;

  @ViewChild(FormEditDirective, { static: true }) adHost: FormEditDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2,  public dialog: MatDialog,
    public sharedInfo: SharedInfo) {

    this.AppConfig = sharedInfo.AppConfig;

    renderer.data
  }

  ngOnInit(): void {



    this.sharedInfo.onChangePopUpTitle.subscribe((title: string) => {

      setTimeout(() => {
        this.title = title;
      }, 50);
    });
    console.log('ModalOverlay init..');


    this.sharedInfo.onSetComponent.subscribe((adItem: AdItem<any>) => {

      console.log('adItem', adItem);
      this.currentForm = adItem;
      //this.title = adItem.title;

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);

      const viewContainerRef = this.adHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);

      const adComponent = (<AdComponent>componentRef.instance);
      adComponent.data = adItem.data;

      adItem.onCreated.emit(adComponent);

      this.toggledMenu = false;
      setTimeout(() => {
        this.toggledMenu = true;
      }, 1000);

    });


  }


  executeCommand(cmd) {
    this.currentForm.onCommandExecuted.emit(cmd);
  }

  closeModal() {
    console.log('close mopdal...');
    this.renderer.removeClass(document.body, 'overlay-active');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // console.log('Key:', event.key);
    // console.log('this.onKeyUpEvent:', this.onKeyUpEvent);
    if ( event.keyCode === 27) { // when ESC is pressed
      if(this.dialog.openDialogs.length <=0)
        this.closeModal();
    }
  }
  
}
