<section class="container-fluid with-maxwidth chapter">

    <ul class="breadcrumb">
        <li class="breadcrumb-item">
            <span class="active">Dashboard</span>
        </li>
    </ul>

    <div class="row">
        <!-- ICON BG -->
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="card card-icon-bg card-icon-bg-primary card-shadow o-hidden mb-4"
                style="background-color: rgba(104, 190, 248, 0.849); color: white;">
                <div class="card-body" style="color: rgb(77, 77, 77);">

                    <div style="display: flex;;">

                        <mat-icon class="dashIcon" style="font-size: 84px; width: 90px; opacity: 0.6;">
                            drive_folder_upload</mat-icon>
                       
                        <div class="content">
                            <p class="mt-2 mb-0"><b>Documentos Publicados</b></p>

                            <p class="text-24 line-height-1 mb-2">{{ dashboardTotalizer?.totalDocuments_f }}</p>
                        </div>

                       


                    </div>

                </div>
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="card card-icon-bg card-icon-bg-primary card-shadow o-hidden mb-4"
                style="background-color: rgba(250, 190, 121, 0.876); color: white;">

                <div class="card-body" style="color: rgb(77, 77, 77);">

                    <div style="display: flex;">

                        <mat-icon class="dashIcon" style="font-size: 84px; width: 100px; opacity: 0.6;">
                            drive_file_rename_outline</mat-icon>

                        <div class="content">
                            <p class="mt-2 mb-0"><b>Assinaturas</b></p>

                            <p class="text-24 line-height-1 mb-2">{{ dashboardTotalizer?.totalSignatures_f }}</p>
                        </div>

                    </div>

                </div>


            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="card card-icon-bg card-icon-bg-primary card-shadow o-hidden mb-4"
                style="background-color: rgba(162, 251, 184, 0.835); color: white;">

                <div class="card-body" style="color: rgb(77, 77, 77);">

                    <div style="display: flex;">

                        <mat-icon class="dashIcon" style="font-size: 84px; width: 100px; opacity: 0.6;">done_all
                        </mat-icon>

                        <div class="content">
                            <p class="mt-2 mb-0"><b>Validações</b></p>

                            <p class="text-24 line-height-1 mb-2">{{ dashboardTotalizer?.totalValidations_f }}</p>
                        </div>

                    </div>

                </div>


            </div>
        </div>

        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div class="card-body text-center">
                        <i class="i-Money-2"></i>
                        <div class="content">
                            <p class="text-muted mt-2 mb-0">Expense</p>
                            <p class="text-primary text-24 line-height-1 mb-2">120</p>
                        </div>
                    </div>
                </div>
            </div> -->

    </div>




</section>




<!-- 
<div class="v-center">
    <mat-card style="height: 180px; width: 320px;">
        <span class="card-title">Página Principal</span>
    </mat-card>
  </div> -->