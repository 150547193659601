import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable } from 'rxjs';
import { OrderModel, DeleteItemCmd, OrderItem, InserttemCmd, UpdateOrderModel, SetStatusOrderModel, InsertInvoiceOrderModel, OrderPayCommand } from './order-model';

let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class OrderDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('order', {
      startDate: new Date(startDate.setMonth(startDate.getMonth() - 1)),
      endDate: endDate,
    }, sharedInfo, userService, http);

    this.routerTitle = 'Comercial / Pedidos';
  }


  getColums() {
    return [
      { columnDef: 'seqCode', header: 'Código', cell: (row) => `${row.seqCode}` },
      { columnDef: 'date', header: 'Data', cell: (row) => `${row.date}` },
      { columnDef: 'clientName', header: 'Nome do Cliente', cell: (row) => `${row.clientName}` },
      // { columnDef: 'userName', header: 'Usuário', cell: (row) => `${row.userName}%` },
      { columnDef: 'totalValue', header: 'Valor Total', cell: (row) => `${row.totalValue}%` },
      { columnDef: 'statusDelivery', header: 'Situação', cell: (row) => `${row.statusDelivery}%` }
   
    ];;
  }

  getNewSequencialCode(): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.serviceUrl + '/' + this.tableName + '/getsequencialcode?UnityId=' + this.userService.selectedUnit.id);
  }


  insertUpdateOrder(data: OrderModel): Observable<any> {
    var postData: any;
    let roteUrl = '';

    if (data.mainData.id) {
      roteUrl = '/' + this.tableName + '/update';
      postData = new UpdateOrderModel();
      postData.id = data.mainData.id;
      postData.clientId = data.mainData.clientId;
      postData.statusDelivery = data.mainData.statusDelivery;
      postData.details = data.mainData.details;
      postData.discountPercentual = data.mainData.discountPercentual;
      postData.discountValue = data.mainData.discountValue;
      postData.transportValue = data.mainData.transportValue;
    }
    else {
      roteUrl = '/' + this.tableName + '/insert';
      postData = data;
    }

    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, postData);
  }

  setStatusOrder(data: OrderModel): Observable<any> {
    var postData: any;
    let roteUrl = '';

    roteUrl = '/' + this.tableName + '/setStatus';
    postData = new SetStatusOrderModel();
    postData.id = data.mainData.id;
    postData.transporterId = data.mainData.transporterId;
    postData.statusDelivery = data.mainData.statusDelivery;
    postData.details = data.mainData.details;
    postData.discountPercentual = data.mainData.discountPercentual;
    postData.discountValue = data.mainData.discountValue;
    postData.transportValue = data.mainData.transportValue;

    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, postData);
  }

  generateInvoice(postData: InsertInvoiceOrderModel): Observable<any> {
    let roteUrl = '';
    roteUrl = '/Invoice/insert';
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, postData);
  }

  insertOrderPayment(orderId, postData: OrderPayCommand): Observable<any> {
    let roteUrl = '/' + this.tableName + '/insertOrderPayment';
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, postData);
  }

  deleteItem(data: DeleteItemCmd): Observable<any> {
    let roteUrl = '/' + this.tableName + '/deleteItem';
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, data);
  }

  insertItem(data: InserttemCmd): Observable<any> {
    let roteUrl = '/' + this.tableName + '/insertItem';
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, data);
  }

}
