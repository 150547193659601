import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import { UserService } from 'src/app/services/user-service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { DocumentService } from '../document.service';
import { SignFileEletronicComponent } from '../sign-file-eletronic/sign-file-eletronic.component';
import { SignFileComponent } from '../sign-file/sign-file.component';

@Component({
  selector: 'app-public-sign',
  templateUrl: './public-sign.component.html',
  styleUrls: ['./public-sign.component.scss'],
  animations: [SharedAnimations]
})
export class PublicSignComponent implements OnInit {

  token: any;
  docId = '';
  documentData: any = {};
  currentUserNotSigned = false;

  base64Img: any;

  base64ImgSigned: any;

  // tslint:disable-next-line:max-line-length
  urlDocument;
  urlPublicDocument;

  displayedColumns = ['documentNumber', 'dateSigned', 'userName', 'cmd'];



  constructor(public dataService: DocumentService,
    private route: ActivatedRoute,
    public userService: UserService,
    public sanitizer: DomSanitizer,
    public modalService: NgbModal) {




    //this.token = this.route.snapshot.params['token'] || '';
    


    this.route
      .queryParams
      .subscribe(params => {
        this.token = params['token'] || '';
        console.log('token', this.token);

        userService.publicTokenData = this.token;

        this.loadData();

      });


  }


  loadData() {
    this.dataService.getPortalSignDetails_ByToken(this.token).subscribe((doc) => {
      this.documentData = doc.data || {};
      this.requestSignature();
    });
  }

  openLink(url) {
    // this.router.navigateByUrl(url);
    window.open(url, '_blank');
  }

  requestSignature() {
    
    // if ((this.documentData.signatures as Array<any>).find(i => i.userId === this.dataService.userService.userLoged.id) == null) {
    //   this.currentUserNotSigned = true;
    // }

    this.base64Img = 'data:image/jpeg;base64,' + this.documentData.base64Img;

    this.base64ImgSigned = 'data:image/jpeg;base64,' + this.documentData.base64ImgSigned;


    this.urlDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentData.urlDocument);

    if (this.documentData.urlPublicDocument !== '') {
      this.urlPublicDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentData.urlPublicDocument);
    }

  }

  ngOnInit() {

  }


  signDoc(userSigInfo) {

    var documentList = [
      {
        erpId: this.documentData.erpId,
        publicDocumentId: this.documentData.id,
        documentName: this.documentData.name,
        signatureId: userSigInfo.id,
        userId: userSigInfo.userId,
        documentNumber: userSigInfo.documentNumber,
        email: userSigInfo.email,
        printProtocolPage: this.documentData.printProtocolPage,
        fields: JSON.parse(userSigInfo.jsonFields)
      }];

    

    const modalRef = this.modalService.open(userSigInfo.method == SignatureMethod.DigitalCertificate ? SignFileComponent : SignFileEletronicComponent, { size: 'lg', backdrop: 'static' });
    modalRef.result.then((result) => {

    }, (reason) => {

    });

    modalRef.componentInstance.documentList = documentList;
    modalRef.componentInstance.userSigInfo = userSigInfo;
    modalRef.componentInstance.pubToken = this.token;

    modalRef.componentInstance.onSaved.subscribe((dataFromAPI) => {
      console.log('resp:', dataFromAPI);
      this.loadData();
      modalRef.close();
    });

    modalRef.componentInstance.onCancelClick.subscribe(() => {
      modalRef.close();
    });
  }


  


}
