export class LocalSignCommand {
    constructor(_commandType: SignCommandType, _commandValue: any) {
        this.commandType = _commandType;
        this.commandValue = JSON.stringify(_commandValue);    
    }
    commandType: SignCommandType;
    commandValue: string;
}

export enum SignCommandType {

    CheckStatus = 1,
    Certificate_Get = 2,
    SignDocument = 10,
    ViewDocument = 11,
    UploadDocument = 12
}