<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">


        <div class="card-body">


            <div class="row">

                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'person_add_edit.type' | translate }}</mat-label>
                        <mat-select [(ngModel)]="data.type" required>
                            <mat-option [value]="1">{{'person_type.1' | translate}}</mat-option>
                            <mat-option [value]="2">{{'person_type.2' | translate}}</mat-option>
                            <mat-option [value]="3">{{'person_type.3' | translate}}</mat-option>
                        </mat-select>
                        <mat-error>
                            {{ formErrors | findErrors: 'type'}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group  col-md-9">
                    <mat-card style="float: right; padding: 10px;">
                        <div class="row" style="display: flex;">
                            <div class="col-md-2">
                                <mat-checkbox [(ngModel)]="data.isClient">Cliente</mat-checkbox>
                            </div>

                            <div class="col-md-3">
                                <mat-checkbox [(ngModel)]="data.isProvider">Fornecedor</mat-checkbox>
                            </div>

                            <div class="col-md-4">
                                <mat-checkbox [(ngModel)]="data.isShipping">Transportadora</mat-checkbox>
                            </div>

                            <div class="col-md-3">
                                <mat-checkbox [(ngModel)]="data.isSalesman">Vendedor</mat-checkbox>
                            </div>

                        </div>
                    </mat-card>

                </div>

            </div>

            <div class="card" style="padding: 10px;" *ngIf="data.type > 0">

                <div class="row" *ngIf="data.type == 2">
                    <div class="form-group col-md-6">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.companyName' | translate }}</mat-label>
                            <input required matInput type="text" [(ngModel)]="data.companyName">
                            <mat-error>
                                {{ formErrors | findErrors: 'companyName'}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="form-group col-md-3">
                        <mat-form-field class="full-width">
                            <mat-label>CNPJ</mat-label>
                            <input required matInput type="text" #txtcnpjcpf [(ngModel)]="data.cnpjcpf"
                                mask="00.000.000/0000-99">
                            <mat-error>
                                {{ formErrors | findErrors: 'cnpjcpf'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.stateReg' | translate }}</mat-label>
                            <input required matInput type="text" [(ngModel)]="data.stateReg">
                            <mat-error>
                                {{ formErrors | findErrors: 'stateReg'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-1">
                        <mat-label style="font-size: 12px;">{{ 'person_add_edit.freeStateReg' | translate }}</mat-label>
                        <mat-slide-toggle class="mat-secondary" [(ngModel)]="data.freeStateReg"></mat-slide-toggle>
                    </div>
                </div>

                <div class="row" *ngIf="data.type == 1">

                    <div class="form-group col-md-6">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.name' | translate }}</mat-label>
                            <input required matInput type="text" #txtName [(ngModel)]="data.name">
                            <mat-error>
                                {{ formErrors | findErrors: 'name'}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>CPF</mat-label>
                            <input required matInput type="text" #txtcnpjcpf [(ngModel)]="data.cnpjcpf"
                                mask="000.000.000-99">
                            <mat-error>
                                {{ formErrors | findErrors: 'cnpjcpf'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>RG</mat-label>
                            <input matInput type="text" [(ngModel)]="data.rg" max="20">
                            <mat-error>
                                {{ formErrors | findErrors: 'rg'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.birthDate' | translate }}</mat-label>
                            <input matInput [matDatepicker]="birthDate" [(ngModel)]="data.birthDate">
                            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
                            <mat-datepicker #birthDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <ngx-mat-intl-tel-input
                      
                            [enableSearch]="true"
                            [(ngModel)]="data.phone">
                        </ngx-mat-intl-tel-input>
                    </div>

                </div>

                <div class="row" *ngIf="data.type == 2">

                    <div class="form-group col-md-6">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.fantasy_name' | translate }}</mat-label>
                            <input required matInput type="text" #txtName [(ngModel)]="data.name">
                            <mat-error>
                                {{ formErrors | findErrors: 'name'}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.cityReg' | translate }}</mat-label>
                            <input required matInput type="text" [(ngModel)]="data.cityReg">
                            <mat-error>
                                {{ formErrors | findErrors: 'cityReg'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.suframaReg' | translate }}</mat-label>
                            <input required matInput type="text" [(ngModel)]="data.suframaReg">
                            <mat-error>
                                {{ formErrors | findErrors: 'suframaReg'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-2">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.foundation_date' | translate }}</mat-label>
                            <input matInput [matDatepicker]="birthDate" [(ngModel)]="data.birthDate">
                            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
                            <mat-datepicker #birthDate></mat-datepicker>
                        </mat-form-field>
                    </div>




                </div>


                <div class="row" *ngIf="data.type == 3">

                    <div class="form-group col-md-8">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.name' | translate }}</mat-label>
                            <input required matInput type="text" #txtName [(ngModel)]="data.name">
                            <mat-error>
                                {{ formErrors | findErrors: 'name'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group col-md-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'person_add_edit.passport' | translate }}</mat-label>
                            <input required matInput type="text" #txtcnpjcpf [(ngModel)]="data.passport">
                            <mat-error>
                                {{ formErrors | findErrors: 'passport'}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>


                <div class="row">
                    <div class="form-group col-md-12">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{ 'person_add_edit.details' | translate }}</mat-label>
                            <textarea matInput placeholder="..." [(ngModel)]="data.details" rows="6"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>


            <div class="card" *ngIf="data.type > 0">

                <mat-tab-group>

                    <mat-tab label="{{ 'address.main_address'  | translate}}">

                        <ng-container *ngTemplateOutlet="addressEditTemplate;context:{item: this.principalAddress}">
                        </ng-container>

                    </mat-tab>

                    <!-- <mat-tab label="{{ 'address.other_addresses'  | translate}}">

                        <div style="display: flex;">
                            <button mat-raised-button class="btn-sm btn_cmd" (click)="addAddress()">
                                Novo Endereço
                                <i class="material-icons">add</i>
                            </button>
        
                        </div>

                        <hr>
                        
                        <div  *ngFor="let address of data.adresses">
                            <div class="card"  *ngIf="address.streetType > 0">
                                <div class="row address-row">
                                    <div class="form-group col-md-12">
                                        <mat-form-field>
                                            <mat-label>{{ 'address.street' | translate }}</mat-label>
                                            <input required matInput type="text" [(ngModel)]="address.street">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </mat-tab> -->

                </mat-tab-group>

            </div>




            <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false"
                style="margin-top: 15px;">
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                    (click)="submit();">Salvar</button>
            </div>


        </div>



    </mat-card>
</div>


<ng-template #addressEditTemplate let-addressItem="item">
    <div style="padding: 10px;">
        <div class="row address-row" [ngClass]="{'removed-item' : addressItem.removed == true}">

            <div class="form-group col-md-2" style="display: flex;">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.zipCode' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="addressItem.zipCode" mask="00.000-000"
                        [disabled]="addressItem.removed == true"
                        (focusout)="cepFocusOut(addressItem, addessNumberInput)">
                </mat-form-field>
                <a mat-button (click)="checkAndFixZipCode(addressItem);" style="min-width: 20px !important;
                          min-height: 20px !important;
                          padding: 2px !important;
                          right: 20px !important; 
                          line-height: unset !important;
                          float: right;">

                    <i class="material-icons" style="font-size: 22px; color: rgb(133, 133, 133);">search</i>
                </a>
            </div>


            <div class="form-group col-md-6">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.city' | translate }}</mat-label>
                    <input matInput required [matAutocomplete]="acbCity" [(ngModel)]="addressItem.city"
                        (keyup)="getCities($event)">
                </mat-form-field>

                <mat-autocomplete #acbCity="matAutocomplete" [displayWith]="cityDisplayFn"
                    (optionSelected)="citySelected($event, addressItem)">
                    <mat-option *ngIf="isLoadingCities" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingCities">
                        <mat-option *ngFor="let item of filteredCities" [value]="item">
                            <span>{{ item.name }}</span>
                            <span> - {{ item.uf }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>

            <div class="form-group col-md-4">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.village' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="addressItem.village"
                        [disabled]="addressItem.removed == true">
                </mat-form-field>
            </div>


            <div class="form-group col-md-6">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.street' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="addressItem.street"
                        [disabled]="addressItem.removed == true">
                </mat-form-field>
            </div>

            <div class="form-group col-md-2">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.number' | translate }}</mat-label>
                    <input required matInput #addessNumberInput id="addessNumberInput" type="text"
                        [(ngModel)]="addressItem.number" [disabled]="addressItem.removed == true">
                </mat-form-field>
            </div>

            <div class="form-group col-md-4">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.complement' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="addressItem.complement"
                        [disabled]="addressItem.removed == true">
                </mat-form-field>
            </div>




            <!-- <div class="form-group col-md-5">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'address.city' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="addressItem.city.name" [disabled]="addressItem.removed == true">
                </mat-form-field>
            </div> -->

        </div>
        <a mat-button (click)="removeAddress(addressItem);" class="link-text" *ngIf="addressItem.removed == false">
            Remover Este Endereço
        </a>

        <a mat-button (click)="includeAddress(addressItem);" class="link-text" *ngIf="addressItem.removed == true">
            Manter Este Endereço
        </a>

    </div>
</ng-template>