import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AdComponent } from 'src/app/models/ad.component';
import { DomainNotification } from 'src/app/models/domainNotification';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import Swal from 'sweetalert2';
import { PersonDataService } from '../../../catalog/person/person-add-edit/person.service';
import { PersonFilterModel } from '../../../catalog/person/person-filter';
import { Person } from '../../../catalog/person/person-model';
import { EnumLists, TaskProcessResumeViewModel } from '../taskprocess.models';
import { TaskProcessService } from '../taskprocess.service';

@Component({
  selector: 'app-add-edit-taskprocess',
  templateUrl: './add-edit-taskprocess.component.html',
  styleUrls: ['./add-edit-taskprocess.component.scss'],
  animations: [SharedAnimations]
})
export class AddEditTaskProcessComponent  implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: TaskProcessResumeViewModel;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtName') txtName;

  loading = false;
  formErrors: DomainNotification[] = [];

  EnumLists: EnumLists;

  subjectClients: Subject<any> = new Subject();
  isLoadingClients = false;
  filteredClients: any[] = [];
  personSearchModes: PersonFilterModel = new PersonFilterModel();
  
  MatcherName = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'Name'); } };
  MatcherFirstDateOfTask = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'FirstDateOfTask'); } };

  constructor(
    private dataService: TaskProcessService,
    public deviceService: DeviceDetectorService,
    public personDataService: PersonDataService,
    public sharedInfo: SharedInfo,
    public translate: TranslateService ) {
      this.EnumLists = new EnumLists(translate);
  }


  ngOnInit(){
    if(this.data == null){
      this.data = new TaskProcessResumeViewModel();
      this.data.active = true;
      //this.data.firstDateOfTask = new Date();
      this.title = 'Nova Tarefa';
    }
    else {
      this.title = 'Editar Tarefa';
    }
    this.sharedInfo.onChangePopUpTitle.emit(this.title);

    this.subjectClients
    .pipe(debounceTime(800))
    .subscribe((value) => {
      if (value.length >= 2) {
        this.isLoadingClients = true;
        this.personSearchModes.strFind = value;
        this.personDataService.findPersonData(this.personSearchModes).subscribe(ret => {
          this.isLoadingClients = false;
          this.filteredClients = ret.data.itens
        }, err => { this.isLoadingClients = false });
      }
    });
    
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
    //this.btnOk.nativeElement.focus();

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.btnOk.nativeElement.focus();
      }
      else {
        this.txtName.nativeElement.focus();
      }

    }, 150);

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  clientDisplayFn(item: Person) {
    if (item) { return item.cnpjcpf + ' - ' + item.name; }
  }

  getClients(event: any) {

    this.personSearchModes = new PersonFilterModel();

    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectClients.next(searchTerm);
    }
  }

  clientSelected(event: MatAutocompleteSelectedEvent ) {
    
    this.data.personId = event.option.value.id;
    console.log(this.data);

    setTimeout(() => {
      this.txtName.nativeElement.focus();
    }, 150);
  }
  
  close() {
    this.onCancelClick.emit(null);
  }

  submit() {
    this.formErrors = [];
    this.loading = true;

    this.dataService.insertUpdateItem(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

    });
  }

}
