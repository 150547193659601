<section class="container-fluid with-maxwidth chapter" style="padding-bottom: 100px;">

    <ul class="breadcrumb">
        <li class="breadcrumb-item">
            <span class="active">Meu Perfil</span>
        </li>
    </ul>



    <mat-card class="card-content">

        <mat-card-header>
            <div class="space-between">

                <div>
                    <mat-card-title>Dados da conta</mat-card-title>
                    <mat-card-subtitle>
                        Alterar dados principais do usuário
                    </mat-card-subtitle>
                </div>

                <mat-icon class="dashIcon" style="font-size: 56px; width:60px; opacity: 0.6;">folder_shared</mat-icon>
            </div>

        </mat-card-header>

        <hr>

        <div class="card-body" *ngIf="userData">

            <div class="row">

                <div class="form-group col-md-6">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'auth.profile.email' | translate }}</mat-label>
                        <input required matInput type="email" [(ngModel)]="userData.email" [readonly]="true">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-6">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'auth.profile.username' | translate }}</mat-label>
                        <input required matInput type="text" [(ngModel)]="userData.username" [readonly]="true">
                    </mat-form-field>
                </div>

            </div>


            <div class="row" >


                <div class="form-group col-md-6">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'auth.profile.name' | translate }}</mat-label>
                        <input required matInput type="text" [(ngModel)]="userData.name">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'auth.profile.cpf' | translate }}</mat-label>
                        <input required matInput type="text" [(ngModel)]="userData.cpf" mask="000.000.000-99">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'auth.profile.phone' | translate }}</mat-label>
                        <input matInput type="text" [(ngModel)]="userData.phone" mask="(00) 00000-0000">
                    </mat-form-field>
                </div>


            </div>


            <div class="row" >

                <div class="form-group col-md-12">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'auth.profile.signTitle' | translate }}</mat-label>
                        <input matInput type="text" [(ngModel)]="userData.signTitle">
                    </mat-form-field>
                </div>

            </div>


           

        </div>


        <div class="app-modal-footer text-right">
            <button mat-raised-button type="button"  [disabled]="loading"
                (click)="saveMainData();">Salvar</button>
        </div>

    </mat-card>



    
    <mat-card class="card-content" style="margin-top: 50px;">

        <mat-card-header>
            <div class="space-between">

                <div>
                    <mat-card-title>Certificado Digital</mat-card-title>
                    <mat-card-subtitle>
                        Carregar certificado digital A1
                    </mat-card-subtitle>
                </div>

                <mat-icon class="dashIcon" style="font-size: 56px; width:60px; opacity: 0.6;">card_membership</mat-icon>
            </div>

        </mat-card-header>

        <hr>

        <div class="card-body" *ngIf="userData">

            <div class="row">

                <div class="form-group col-md-9">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'auth.profile.certificateFileName' | translate }}</mat-label>
                        <input required matInput type="email" [(ngModel)]="userData.certificateFileName" [readonly]="true">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-3">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'auth.profile.certificateExpiresDate' | translate }}</mat-label>
                        <input required matInput type="email" value="{{ userData.certificateExpiresDate | date : 'short' }}" [readonly]="true">
                    </mat-form-field>
                </div>



            </div>


           


           

        </div>


        <div class="app-modal-footer text-right">
            <button mat-raised-button type="button"  [disabled]="loading"
                (click)="openDialog(sendCertificateDialog);">Novo Arquivo</button>
        </div>
      
    </mat-card>





    <mat-card class="card-content" style="margin-top: 50px;">

        <mat-card-header>
            <div class="space-between">

                <div>
                    <mat-card-title>Empresa</mat-card-title>
                    <mat-card-subtitle>
                        Informações da empresa
                    </mat-card-subtitle>
                </div>

                <mat-icon class="dashIcon" style="font-size: 56px; width:60px; opacity: 0.6;">business</mat-icon>
            </div>

        </mat-card-header>

        <hr>

        <div class="card-body" *ngIf="userData">

            <div class="row">

                <div class="form-group col-md-4">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'unit.name' | translate }}</mat-label>
                        <input required matInput type="email" [(ngModel)]="userService.selectedUnit.name" [readonly]="true">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-4">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'unit.companyName' | translate }}</mat-label>
                        <input required matInput type="email" [(ngModel)]="userService.selectedUnit.companyName" [readonly]="true">
                    </mat-form-field>
                </div>


                <div class="form-group col-md-4">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{ 'unit.cNPJCPF' | translate }}</mat-label>
                        <input required matInput type="email" [(ngModel)]="userService.selectedUnit.cnpjcpf" [readonly]="true">
                    </mat-form-field>
                </div>

            </div>


           


           

        </div>


      
    </mat-card>









</section>




<ng-template #sendCertificateDialog>
    <div class="md-content-modal">
  
      <span class="title">{{ 'auth.profile.sendNewCertificate_title' | translate }}</span>
      <hr>
  
      <app-send-certificate (onSaved)="onSendCertificateFile($event);"></app-send-certificate>
  
    </div>
  </ng-template>