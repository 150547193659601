<div>

    <form [formGroup]="formGroug" fxLayout="column" fxFlex>


        
        <div class="col-md-12">

            <div class="form-group">
                <input type="file" #fileInput formControlName="file" (change)="changeFile(fileInput.files)" 
                accept=".pfx, .cer"/>

                
            </div>

        </div>

        <div class="col-md-12">

            <p *ngIf="formErrors | findErrors: 'file'" style="color: rgb(255, 89, 89);"> {{ formErrors | findErrors:
                'file'}}
            </p>
            

        </div>

        <div class="col-md-12">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ 'auth.profile.certificatePassword' | translate }}</mat-label>
                <input type="password" required matInput formControlName="password">
            </mat-form-field>
        </div>
        





    </form>








    <div class="row">



        <!-- 
        <div class="attachment" class="col-md-3 col-xl-3" *ngIf="fileName != null">

          <div>
            <span class="filename" [innerHTML]="fileName"></span>
            <span class="size" [innerHTML]="fileSize"></span>
          </div>

        </div> -->


        <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
            {{progress}}%
        </span>

        <span style="font-weight:bold;color:green;" *ngIf="message">
            {{message}}
        </span>


    </div>


    <!-- <div class="col-md-6">
        <mat-form-field class="full-width" appearance="outline" >
            <mat-label>{{ 'taskprocess_add_edit.title' | translate }}</mat-label>
            <input required matInput type="text" [(ngModel)]="data.name" [errorStateMatcher]="MatcherName">
            <mat-error>
                {{ formErrors | findErrors: 'name'}}
            </mat-error>
        </mat-form-field>
    </div>


    <div class="col-md-4">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'taskprocess_add_edit.firstDate
                OfTask' | translate }}</mat-label>

            <input matInput [ngxMatDatetimePicker]="picker"  [(ngModel)]="data.firstDateOfTask" [errorStateMatcher]="MatcherFirstDateOfTask">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker>
            </ngx-mat-datetime-picker>
            <mat-error>
                {{ formErrors | findErrors: 'firstDateOfTask'}}
            </mat-error>
          </mat-form-field>
    </div> -->

    <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false">
        <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
        <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk
            [disabled]="loading || fileName == null" (click)="submit();">Salvar</button>
    </div>


</div>