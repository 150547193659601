import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/AuthGuard';
import { AuthGuardLocal } from '../services/AuthGuardLocal';
import { LocalOrdersComponent } from './comp/local-orders/local-orders.component';
import { DashboardPdvPage } from './dashboard.page';
import { PdvHomePageComponent } from './home-page/home-page.component';
//import { ViewComponent } from './tables/view/view.component';

const routes: Routes = [
  {
    path: 'pdv',
    component: DashboardPdvPage,
    children: [
      { path: '', redirectTo: 'pdv/home', pathMatch: 'full' },
      { path: 'home', component: PdvHomePageComponent },
      { path: 'localOrders', component: LocalOrdersComponent },
      // { path: ':tableName', component: ViewComponent },
      // { path: ':tableName/:id', component: ViewComponent },
    ],
    canActivate: [AuthGuard, AuthGuardLocal]
  }
  // {
  //   path: 'view',
  //   loadChildren: () => import('./sign-up/sign-up.module').then( m => m.ViewPageModule)
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardPdvPageRoutingModule {}
