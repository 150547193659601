<section class="container-fluid with-maxwidth chapter">

  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <span class="active">{{ appService.routerTitle }}</span>
    </li>
  </ul>

  <div class="webcomands row">
    <div class="col-md-9">


      <div class="row">

        <div class="col-md-5">
          <mat-form-field class="full-width">
            <mat-icon matSuffix class="material-icons">search</mat-icon>
            <input matInput #strFindControl placeholder="Buscar" [(ngModel)]="strFind" ngDefaultControl
              (ngModelChange)='updateFilter($event)'>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <div style="display: flex;">
            <mat-form-field *ngIf="appService?.filterData?.startDate != null" class="period-fields">
              <mat-label>Data Inicial</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.startDate"
                (dateChange)="fetch(null)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field *ngIf="appService?.filterData?.endDate != null" class="period-fields"
              style="margin-left: 12px;">
              <mat-label>Data Final</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.endDate"
                (dateChange)="fetch(null)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

        </div>






      </div>


      <a data-toggle="tooltip" title="Busca Avançada" class="search-icon  m-r-15"
        (click)="openModal(filterModal, 'md')">
        <i class="nav-icon i-Magnifi-Glass1"></i>
      </a>
    </div>

    <div class="col-md-3">
      <div class="webcomands_buttons">

        <a mat-raised-button (click)='fetch()' *ngIf="appService.options?.fetch == true">
          <i class="cmd-icon nav-icon i-Reload"></i> Atualizar
        </a>

        <a mat-raised-button color="primary" (click)='addItem()' style="margin-left: 5px;"
          *ngIf="appService.options?.add == true">
          <i class="cmd-icon nav-icon i-Add"></i> Novo Cadastro
        </a>

      </div>

    </div>

  </div>


  <div class="desktop-table-container"
    *ngIf="appService.options?.tableMode == true && (isTablet === true || isDesktopDevice === true)">

    <mat-card class="card-data-content"
      [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

      <div class="card-table-web">

        <table class="data-table" [dataSource]="data" mat-table matSort matSortActive="firstDateOfTask" matSortDirection="desc"
          matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Título </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="firstDateOfTask">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data Início </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.firstDateOfTask | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data Final </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.endDate}} </td>
          </ng-container>


          <ng-container matColumnDef="repetitionPeriod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Repetição </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.repetitionPeriod}} </td>
          </ng-container>


          <ng-container matColumnDef="personName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Pessoa Relacionada </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.personName}} </td>
          </ng-container>


        

          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]>
              <div style="display: flex; float: right;">
                <a mat-raised-button class="btn-sm btn_cmd" (click)="openEdit(row.id);  $event.stopPropagation();">
                  <i class="material-icons">edit</i></a>
                </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


      </div>




    </mat-card>

    <mat-card class="card-bottom-data-table">
      <div>

        <mat-paginator class="tbPaginator" #tbPaginator [length]="resultsLength" [pageSize]="25" style="float: right;"
          [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>

      </div>
    </mat-card>



  </div>


</section>