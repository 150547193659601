import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit } from '@angular/core';

import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserPermissionService } from './user-permission.service';

@Component({
  selector: 'app-userPermission',
  templateUrl: './userPermission.component.html',
  styleUrls: ['./userPermission.component.scss'],
  animations: [SharedAnimations]
})
export class UserPermissionEditComponent implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: any;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtName') txtName;

  loading = false;
  formErrors: DomainNotification[] = [];

  constructor(
    private dataService: UserPermissionService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,) {

  }


  ngOnInit(){
    this.title = 'Editar Permissões';
    this.sharedInfo.onChangePopUpTitle.emit(this.title);
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
    //this.btnOk.nativeElement.focus();

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.btnOk.nativeElement.focus();
      }
      else {
        this.txtName.nativeElement.focus();
      }

    }, 150);

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  setAll(checked, module) {

    if(module.childs) {
      module.childs.forEach(child => {
        child.active = checked;
        this.setChild(checked, module, child);
      });
    }

  }

  setChild(checked, module, child) {

    if(checked == true && module.active == false) {
      module.active = true;
    }

    if(checked == false && module.childs.find(i=>i.active == true) == null) {
      module.active = false;
    }

    if(child.actions) {
      child.actions.forEach(action => {
        action.active = checked;
      });
    }

  }

  setAction(checked, module, child, action) {

    if(checked == true && module.active == false) {
      module.active = true;
    }

    if(checked == true && child.active == false) {
      child.active = true;
    }

    if(checked == false && child.actions.find(i=>i.active == true) == null) {
      child.active = false;
    }

    if(checked == false && module.childs.find(i=>i.active == true) == null) {
      module.active = false;
    }

  }


  submit() {
    this.formErrors = [];
    this.loading = true;

    this.dataService.updateClaims(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit();
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

    });
  }

}
