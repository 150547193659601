import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ProductGroup } from './group-model';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { ProductDataService } from '../product-add-edit/product.service';
import { GroupDataService } from './group.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { SharedInfo } from 'src/app/services/shared-info';

@Component({
  selector: 'app-group-add-edit',
  templateUrl: './group-add-edit.component.html',
  styleUrls: ['./group-add-edit.component.scss'],
  animations: [SharedAnimations]
})
export class GroupAddEditComponent implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: ProductGroup;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtName') txtName;

  loading = false;
  formErrors: DomainNotification[] = [];

  constructor(
    private dataService: GroupDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,) {

  }


  ngOnInit(){
    if(this.data == null){
      this.data = new ProductGroup();
      this.title = 'Novo Grupo';
    }
    else {
      this.title = 'Editar Grupo';
    }
    this.sharedInfo.onChangePopUpTitle.emit(this.title);
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
    //this.btnOk.nativeElement.focus();

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.btnOk.nativeElement.focus();
      }
      else {
        this.txtName.nativeElement.focus();
      }

    }, 150);

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  submit() {
    this.formErrors = [];
    this.loading = true;

    this.dataService.insertUpdateItem(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

    });
  }

}
