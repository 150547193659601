import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, HostListener, Renderer2, TemplateRef } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AdItem } from 'src/app/models/ad-item';
import { PageSharedFns } from 'src/app/services/pageSharedFunctions';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { DocumentService } from '../document.service';
import {AddEditDocumentComponent} from '../add-edit-document/add-edit-document.component';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignFileComponent } from '../sign-file/sign-file.component';
import { SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import { SignFileEletronicComponent } from '../sign-file-eletronic/sign-file-eletronic.component';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
  animations: [SharedAnimations]
})

export class ViewDocumentComponent extends PageSharedFns implements AfterViewInit, OnDestroy {

  form: AdItem<DocumentService>;
  formAddEdit: any;
  id:any;
  
  private onUnitySub;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public deviceService: DeviceDetectorService,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private renderer: Renderer2,
    public modalService: NgbModal,) {
    super(dialog, route, deviceService);

    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    }


    this.route.params.subscribe(params => {
      this.tableName = 'receivables';// params['tableName'];
      this.id = params['id'];
      this.data = [];
      this.initialize();
    });
    
    this.onUnitySub = this.userService.onUnityChange.subscribe(unity=>{
      this.fetch(null);     
      setTimeout(() => {
        this.data.forEach(element => {
          element.animateChangeUnity = true;
        });
        console.log('unitChanged');
      }, 1000);
    });
  }

  ngOnDestroy() {
    this.onUnitySub.unsubscribe();
  }

  //this.onKeyUpEvent.emit(event);

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.onKeyUpEvent.emit(event.key);

    if(this.formAddEdit != null && this.formAddEdit.onKeyUpEvent != null){
      // if(this.$body.hasClass('overlay-active')) {
      //   this.formAddEdit.onKeyUpEvent.emit(event);
      // }
    }
  }


  initialize(){

    this.form = null;
    this.formAddEdit = null;
    
    this.form = new AdItem(AddEditDocumentComponent, new DocumentService(this.sharedInfo, this.userService, this.http));

    this.appService = this.form.service;
    this.columns = this.form.service.getColums();
    this.displayedColumns = [];
    this.displayedColumns = [...this.columns.map(x => x.columnDef), 'cmd'];

    this.form.onCreated.subscribe((comp) => {

      this.formAddEdit = comp;

      comp.onSaved.subscribe((dataFromAPI) => {
        console.log('dataFromAPI:', dataFromAPI);
        this.fetch(dataFromAPI);
        this.renderer.removeClass(document.body, 'overlay-active');
      });

      comp.onCancelClick.subscribe((dataFromAPI) => {
        this.renderer.removeClass(document.body, 'overlay-active');
      });

    });

    this.form.onCommandExecuted.subscribe((cmd: string) => {
      console.log('cmd:', cmd);

      switch (cmd) {

        case 'share':
          //this.clipboard.writeText(this.formAddEdit.data.id);
          break;

        case 'save':
          this.formAddEdit.submit();
          break;

       
        default:
          break;
      }
    });

    if(this.id) {
      //Auto edit mode, get ID from URL
      this.openEdit(this.id);
    }
  }

  setCommands(edit: boolean = false){
    this.form.commands = [];
    this.form.commands.push({ cmd: 'share', icon: 'share', label: '' });
    this.form.commands.push({ cmd: 'save', icon: 'save', label: '' });


    this.form.moreCommands = [];
    if(edit == true)
    {
      this.form.moreCommands.push({ cmd: 'delete', icon: 'delete', label: 'Excluir Registro' });
    }
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  openEdit(id) {
    this.setCommands(true);
    this.selectedItem = this.data.find(i=>i.id == id);
    this.appService.getItemDetails(id).subscribe(ret => {
      this.form.data = ret.data;
      this.form.component.prototype.idForEdit = ret.data.id;
      //this.form.component.prototype.tableName = this.tableName;
      this.sharedInfo.onSetComponent.emit(this.form);
      this.renderer.addClass(document.body, 'overlay-active');
    }, errRersp => {

    });

  }


  addItem(addFileDialog) {
    this.openDialogWithTemplateRef(addFileDialog);
  }

  onSendFile(dataFromAPI){
    console.log('dataInsertedFromAPI:', dataFromAPI);
      // this.fetch(dataFromAPI);
      if (dataFromAPI.id) {
        this.router.navigate(['/signature/document/edit/', dataFromAPI.id]);
      }
  }

  openSignAll(template)
  {
    this.dialog.open(template);
  }

  onSetUserForSign(event)
  {
    if(event.user){
      this.signAll(event.user.id, event.method);
      this.dialog.closeAll();
    }
  }

  signAll(userId, method) {

    if(method == SignatureMethod.Eletronic) 
    {
      Swal.fire("O'ops", 'Por enquanto somente assinatura digital poderá ser executada em lotes!', 'warning')
      return;
    }

    this.form.service.getDocumentPendingSignature(userId, method).subscribe(resp=>{

      if(resp.success == true && resp.data && resp.data.length > 0) {


        const modalRef = this.modalService.open(method == SignatureMethod.DigitalCertificate ? SignFileComponent : SignFileEletronicComponent, 
          { size: 'lg', backdrop: 'static' });
          
        modalRef.result.then((result) => {
  
        }, (reason) => {
  
        });
  

        modalRef.componentInstance.documentList = resp.data;

        modalRef.componentInstance.onSaved.subscribe((dataFromAPI) => {
          console.log('dataInsertedFromAPI:', dataFromAPI);
          this.router.navigate(['/signature/document/edit/', dataFromAPI.document.publicDocumentId]);
          modalRef.close();
        });
  
        modalRef.componentInstance.onCancelClick.subscribe(() => {
          modalRef.close();
        });


      } else {
        Swal.fire("O'ops", 'Não foi localizado nenhum documento pendente de assinatura para o usuário selecionado!', 'info');
      }
     



    });

  }


  delete(row) {
    this.selectedItem = row;
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      position: 'top',
      title: "Confirmar Exclusão",
      text: "Confirma a exclusão do documento ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim, Excluir',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        this.appService.delete(this.selectedItem.id).subscribe(ret => {
          if (ret.success == true) {
            this.selectedItem = this.data.find(i=>i.id == this.selectedItem.id);

            console.log(this.selectedItem);
            console.log('index', this.data.indexOf(this.selectedItem));

            this.data.splice(this.data.indexOf(this.selectedItem), 1);
            this.data = [... this.data];

            this.renderer.removeClass(document.body, 'overlay-active');
          }
        });
      }

    })
  }
}
