import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ProductGroup } from '../group-add-edit/group-model';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { Product } from './product-model';
import { ProductDataService } from './product.service';
import { GroupDataService } from '../group-add-edit/group.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';

//import { Capacitor, Camera, CameraResultType } from '@capacitor/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-product-add-edit',
  templateUrl: './product-add-edit.component.html',
  styleUrls: ['./product-add-edit.component.scss'],
  animations: [SharedAnimations]
})
export class ProductAddEditComponent implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: Product;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtGroup') txtGroup;

  loading = false;
  formErrors: DomainNotification[] = [];

  imageBase64: any;

  constructor(
    private dataService: ProductDataService,
    public groupDataService: GroupDataService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,
    private _sanitizer: DomSanitizer) {
  }


  ngOnInit() {
    if (this.data == null) {
      this.data = new Product();
      this.title = 'Novo Produto';
      this.dataService.getNewSequencialCode().subscribe(resp => {
        if (resp.success === true) {
          this.data.code = resp.data.code;
          this.data.seqCode = resp.data.seqCode;
        }
      });
    }
    else {
      this.title = 'Editar Produto';
    }

    this.sharedInfo.onChangePopUpTitle.emit(this.title);

    this.groupDataService.getInMemoryGroups().then(resolve => {
      console.log('groups', resolve)
    });


    setTimeout(() => {
      if (this.deviceService.isMobile() == false) {
        this.txtGroup.focus();
      }
    }, 150);
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  // async readImage(){
  //   const isAvailable = Capacitor.isPluginAvailable('Camera');

  //   if (!isAvailable) {
  //     // Have the user upload a file instead
  //   } else {
  //     // Otherwise, make the call:
  //     const image = await Camera.getPhoto({
  //       quality: 90,
  //       allowEditing: true,
  //       resultType: CameraResultType.Base64
  //     });
  //     this.imageBase64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image.base64String);
  //   }
  // }

  submit() {
    this.formErrors = [];

    if (this.data.groupId == null) {
      this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Informe a Categoria!' })
      return;
    }

    this.loading = true;
    this.dataService.insertUpdateItem(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });
  }

}
