<div style="min-width: 450px;">

    <div class="col-md-12">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Empresa</mat-label>
            <input matInput required #txtSignerUser [matAutocomplete]="auto" 
                (input)="getUsers($event)">
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="userDisplayFn" (optionSelected)="userSelected($event)">
            <mat-option *ngIf="isLoadingUsers" class="is-loading">
                <mat-spinner diameter="30"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isLoadingUsers">
                <mat-option *ngFor="let item of filteredUsers" [value]="item">
                    <span>{{ item.name }}</span>
                    <span> | {{ item.cnpjcpf }}</span>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </div>



    <hr>


    <div class="app-modal-footer text-right" style="margin-top: 90px;">
        <button mat-raised-button (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" #btnOk [disabled]="!clientErpSelected" style="margin-left: 10px;"
            (click)="save();">Salvar</button>
    </div>


</div>