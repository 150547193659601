import { NgModule } from '@angular/core';
//import { HttpModule, Http } from '@angular/http';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomPipesModule } from './pipes/pipes.module';
import { MaterialModule } from './material.module';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderPaymentModalComponent } from './comp/order-payment-modal/order-payment-modal.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({

  declarations: [
    OrderPaymentModalComponent
  ],

  imports: [
    HttpClientModule,
    CommonModule,
    CustomPipesModule,

    FormsModule,
    ReactiveFormsModule,

    MaterialModule,

    MomentModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false
    })
  ],
  exports: [
    CommonModule,
    CustomPipesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,

    MaterialModule,
    MomentModule
  ]
})
export class SharedModule {

  constructor(private translate: TranslateService) {

    var lang = localStorage.getItem('currLang');
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    }
    else {
      const browserLang = translate.getBrowserLang();
      const defaultLang = browserLang.replace('pt', 'pt_br');
      console.log('defaultLang', defaultLang);

      translate.use(defaultLang.match(/en|pt/) ? defaultLang : 'en');
      localStorage.setItem('currLang', defaultLang);
    }


    // translate.addLangs(["en", "fr"]);
    // translate.setDefaultLang('en');

    // let browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }
}
