<ng-container *ngTemplateOutlet="currentPage;">
</ng-container>


<ng-template #selectSigModelDialog>
  <div class="eletronic-sig-modal">

    <div class="card-header">
      <span class="title">Modelo de Assinatura:</span>
    </div>

    <div class="card-body eletronic-signature-card" style="min-height: 280px;">

      <div class="row">

        <div class="col-md-5 col-xs-12">

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Selecione o Modelo</mat-label>
            <mat-select [(ngModel)]="selectedSignatureModel" (selectionChange)="onSelectedSignatureModel($event)" required>
              <mat-option *ngFor="let item of signatureModels" [value]="item">{{ item.title }}</mat-option>
            </mat-select>
          </mat-form-field>



          <div class="space-between" style="margin-top: 10px; margin-bottom: 30px; display: flex;">
            <button mat-raised-button  class="full-width" (click)="goAddModel();">
              <mat-icon>add_photo_alternate</mat-icon>Novo Modelo
            </button>
            <button mat-raised-button  class="full-width" (click)="deleteModel();" [disabled]="!selectedSignatureModel" style="color: rgb(102, 0, 0);">
              <mat-icon>delete_forever</mat-icon>
              Excluir
            </button>
          </div>
      

        </div>


        <div class="col-md-7 col-xs-12">
          <img *ngIf="selectedSignatureModel" src="{{ imagesUrl }}/{{ selectedSignatureModel.id }}.{{ selectedSignatureModel.imgExt }}"
          style="width: 100%; max-width: 400px; padding: 30px; border: solid 1px rgb(75, 75, 75);">
        </div>


      </div>
      
      

    </div>


    <div class="bottom-cmd card-footer">
      <div class="space-between">
        <div>
          <button mat-raised-button (click)="close()">Cancelar</button>
         
        </div>
        <div></div>
        <div>

          <button mat-raised-button color="primary" (click)="sign();" [disabled]="!selectedSignatureModel">Assinar documento</button>
        </div>
      </div>
    </div>



  </div>

</ng-template>



<ng-template #addSigModelDialog>

  <div class="eletronic-sig-modal">

    <div class="card-header">
      <span class="title">Adicionar Modelo de Assinatura:</span>
    </div>

    <div class="card-body eletronic-signature-card">


      <div class="row" *ngIf="userSigInfo.userId">

        <!-- <div class="col-md-6 col-xs-12">
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Selecione um Campo:</mat-label>
            <mat-select [(ngModel)]="fieldToAddmodel" required>
              <mat-option *ngFor="let item of documentList[0].fields" [value]="item">P0{{ item.page }} - {{ item.id }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->


        <div class="col-md-6 col-xs-12" >
          <mat-form-field class="full-width" appearance="outline">
              <mat-label>Digite um Título para o Modelo:</mat-label>
              <input required matInput type="text" [(ngModel)]="titleToAddmodel">
          </mat-form-field>
        </div>



      </div>

      


      <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
        <mat-tab label="Desenhar">

          <div class="tabItem" style="padding-top: 60px;">
            <div class="signaturepad">
              <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">

              </signature-pad>
            </div>
          </div>


        </mat-tab>
        <mat-tab label="Imagem"> 
        
          <div class="tabItem" style="padding-top: 60px;">
          
            <form fxLayout="column" fxFlex>
              <div class="form-group">
                <input type="file" #fileInput  (change)="fileChangeEvent($event)" />
              </div>
            </form>

            <div class="imgcropper">
              <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="false"
                    [cropperMinWidth]="320"
                    [backgroundColor]="cropperBackgroundColor"
                    format="png"
                    (imageCropped)="imageCropped($event)">
              </image-cropper>
            </div>
           
          
          </div>

          
        </mat-tab>
        <mat-tab label="Digitar">

          <div class="tabItem">
            <div style="text-align: left; display: flex;">

              <mat-radio-group [(ngModel)]="imgDigitSignatureID" (change)="exportImg()">

                <div class="row">

                  <div class="col col-md-6 col-xs-12 cardsignpad">
                    <mat-radio-button [value]="'textSign01'" class="textSign">
                      <div id="textSign01" style="padding-left: 5px; padding-right: 5px;">
                        <p style="font-family: Caveat, cursive; margin: 0px; line-height: 40px;">
                          {{userSigInfo.userName}}
                        </p>
                      </div>
                    </mat-radio-button>
                  </div>

                  <div class="col col-md-6 col-xs-12 cardsignpad">
                    <mat-radio-button [value]="'textSign02'" class="textSign">
                      <div id="textSign02" style="padding-left: 5px; padding-right: 5px;">
                        <p style="font-family: Pacifico, cursive; margin: 0px; line-height: 58px;">
                          {{userSigInfo.userName}}
                        </p>
                      </div>

                    </mat-radio-button>
                  </div>

                  <div class="col col-md-6 col-xs-12 cardsignpad">

                    <mat-radio-button [value]="'textSign03'" class="textSign">
                      <div id="textSign03" style="padding-left: 5px; padding-right: 5px;">
                        <p style="font-family: Marck Script, cursive; margin: 0px; line-height: 40px;">
                          {{userSigInfo.userName}}
                        </p>
                      </div>

                    </mat-radio-button>
                  </div>

                  <div class="col col-md-6 col-xs-12 cardsignpad">
                    <mat-radio-button [value]="'textSign04'" class="textSign">
                      <div id="textSign04" style="padding-left: 5px; padding-right: 5px;">
                        <p style="font-family: Meddon, cursive; margin: 0px; line-height: 62px;">
                          {{userSigInfo.userName}}
                        </p>
                      </div>

                    </mat-radio-button>
                  </div>






                </div>

              </mat-radio-group>



            </div>
          </div>



        </mat-tab>
      </mat-tab-group>



    </div>


    <div class="bottom-cmd card-footer">
      <div class="space-between">
        <div>
          <button mat-raised-button (click)="goSelectModel()">Voltar</button>
        </div>
        <div></div>
        <div>
          <button mat-raised-button color="primary" [disabled]="!fieldToAddmodel || !titleToAddmodel || !imageData" (click)="saveModel();">Salvar</button>
        </div>
      </div>
    </div>



  </div>


</ng-template>



<ng-template #signLocalPluginDialog>
  <app-sign-file [documentList]="documentList" [pubToken]="this.pubToken ? this.pubToken : this.userService.tokenData" 
  [imageData]="imageData"
  [signatureModelUrlImage]="selectedSignatureModel?.urlImage" (onSaved)="onLocalPluginSigned($event)" (onCancelClick)="close()"></app-sign-file>
</ng-template>