<!-- [style.marginTop.px]="mobileQuery.matches ? 65 : 0" -->
<section class="container-fluid with-maxwidth chapter">

    <ul class="breadcrumb">
        <li class="breadcrumb-item">
            <span class="active">{{ 'document_details.title' | translate }}</span>
        </li>
    </ul>

    <div class="webcomands" *ngIf="documentData">


        <div class="space-between">


            <div>
                <button mat-raised-button [routerLink]="['/signature/document']">Voltar</button>
            </div>

            <div></div>

            <div>
                <button mat-raised-button class="btn-rcmd" (click)="deleteDocument()"
                    *ngIf="(documentData.id) !== null && (documentData.datePublish === null)">
                    <mat-icon>delete</mat-icon>
                    Excluir Documento
                </button>

                <button mat-raised-button class="btn-rcmd" color="primary" (click)="sendRequestSignatures()"
                    *ngIf="!documentData.signatureComplete">
                    <mat-icon>alternate_email</mat-icon>
                    Solicitar Assinaturas por e-mail
                </button>

                <button mat-raised-button class="btn-rcmd" color="accent" (click)="sendEmailDoc(sendDocumentDialog)"
                    *ngIf="documentData.datePublish != null">
                    <mat-icon>send</mat-icon>
                    Enviar por e-mail
                </button>

                <button mat-raised-button class="btn-rcmd" color="primary" (click)="publishDocument()"
                    *ngIf="documentData.lastSignature != null && documentData.datePublish == null">
                    <mat-icon>check_circle_outline</mat-icon>
                    Publicar Documento
                </button>

            </div>


        </div>

    </div>


    <div class="desktop-table-container" *ngIf="documentData">

        <mat-card class="card-data-content document-content"
            [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

            <mat-card-header style="padding: 15px; ">
                <!-- <div mat-card-avatar class="example-header-image"></div> -->
                <mat-icon mat-card-avatar>picture_as_pdf</mat-icon>
                <mat-card-title>{{ documentData.name }}</mat-card-title>
                <mat-card-subtitle>
                    <p *ngIf="documentData.datePublish != null">

                        <span>Publicado em: {{ documentData.datePublish | amFromUtc | amLocal | amDateFormat:
                            'DD-MM-YYYY HH:mm'
                            }}</span>

                        Por: <b>{{ documentData.publisherUserName }}</b>


                    </p>
                </mat-card-subtitle>
            </mat-card-header>

            <hr style="margin: 0px;">

            <mat-card-content style="padding: 15px; margin-top: 0px;">


                <div class="card-body">


                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">

                            <div class="card">
                                <div class="card-header p-2" color="txt-primary">

                                    <div class="float-left">
                                        <p class="text-muted f-w-600 f-16">
                                            Lista de Signatários
                                        </p>
                                    </div>



                                    <div class="float-right">
                                        <!-- <i class="text-muted icofont icofont-users-alt-5 f-20"></i>
-->
                                        <button mat-raised-button
                                            (click)="addSigner(addSignerDialog)">Adicionar Signatário</button>

                                    </div>



                                </div>


                                <div class="p-20">

                                    <table class="data-table" [dataSource]="documentData.signatures" mat-table>

                                        <ng-container matColumnDef="documentNumber">
                                            <th mat-header-cell *matHeaderCellDef  disableClear> Documento </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                {{ signature.documentNumber }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="dateSigned">
                                            <th mat-header-cell *matHeaderCellDef  disableClear> Data de Assinatura </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                <span *ngIf="signature.dateSigned">
                                                    {{ signature.dateSigned |
                                                        amFromUtc | amLocal | amDateFormat:
                                                        'DD-MM-YYYY HH:mm' }} 
                                                </span>
                                                
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="method">
                                            <th mat-header-cell *matHeaderCellDef  disableClear> Metodo </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                {{ 'document_add.method_' + signature.method | translate }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="userName">
                                            <th mat-header-cell *matHeaderCellDef  disableClear> Nome </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                {{ signature.userName }}
                                            </td>
                                        </ng-container>

                                       

                                        



                                        <ng-container matColumnDef="cmd">
                                            <th mat-header-cell *matHeaderCellDef  disableClear> </th>
                                            <td mat-cell *matCellDef="let signature" [@myfadeIn]>
                                                <div style="display: flex; float: right;">

                                                    <button mat-raised-button
                                                    (click)="signDoc(signature)" *ngIf="signature.dateSigned == null">
                                                    Assinar
                                                </button>


                                                <button mat-raised-button style="margin-left: 5px;"
                                                    (click)="removeSigner(signature.id)"
                                                    *ngIf="signature.dateSigned == null">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                                </div>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row">
                                        </tr>
                                    </table>


                                   





                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">



                        <div class="col-sm-6 col-md-6 col-xs-12">

                            <div class="card">
                                <div class="card-header p-1" color="txt-primary">

                                    <div class="float-left">

                                        <p class="text-muted f-w-600 f-16"> <i
                                                class="icofont icofont-attachment f-20"></i>
                                            <a (click)="openLink(documentData.urlDocument);" (alt)="documentData.name"
                                                style="cursor: pointer;">
                                                Visualizar Versão Original</a>
                                        </p>
                                    </div>



                                </div>


                                <div class="p-10" style="height: 285px;">



                                    <!-- <a href="javascript:" (click)="openLink(documentData.urlDocument);" target="_blank" (alt)="documentData.name">
          
                              <img class="card-img-top img-fluid img-thumbnail" [src]="base64Img" style="height:280px; object-fit: contain;">
          
                            </a> -->

                                    <iframe [src]='urlDocument' width="100%" height="280" frameborder="0"
                                        webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                    </iframe>





                                </div>
                            </div>

                        </div>



                        <div class="col-sm-6 col-md-6 col-xs-12">

                            <div class="card">
                                <div class="card-header p-1" color="txt-primary">

                                    <div class="float-left">

                                        <p class="text-muted f-w-600 f-16"> <i
                                                class="icofont icofont-certificate f-20"></i>
                                            <a href="javascript:" (click)="openLink(documentData.urlPublicDocument);"
                                                style="cursor: pointer;" (alt)="documentData.name">
                                                Visualizar Versão Assinada</a>
                                        </p>
                                    </div>




                                </div>


                                <div class="p-10" style="height: 285px;" >



                                    <!-- <a href="javascript:" (click)="openLink(documentData.urlPublicDocument);" target="_blank" (alt)="documentData.name">
          
                              <img class="card-img-top img-fluid img-thumbnail" [src]="base64ImgSigned" style="height:280px; object-fit: contain;">
          
                            </a> -->


                                    <iframe [src]='urlPublicDocument' width="100%" height="280" frameborder="0"  *ngIf="documentData.urlPublicDocument"
                                        webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                    </iframe>




                                </div>
                            </div>

                        </div>










                    </div>



                </div>





            </mat-card-content>






        </mat-card>



    </div>


</section>

<ng-template #addSignerDialog>
    <div class="md-content-modal">

        <span class="title">Adicionar Signatário</span>
        <hr>

        <app-signer-add-edit (onSaved)="onAddSigner($event);" (onCancelClick)="dialog.closeAll()" [document]="documentData" [userSigInfo]="userSigInfo"></app-signer-add-edit>
        
    </div>
</ng-template>


<ng-template #sendDocumentDialog>
    <div class="md-content-modal">

        <span class="title">Enviar documento por E-mail</span>
        <hr>

        <app-sign-send-doc (onSended)="onSendDocumentSigned($event);" (onCancelClick)="dialog.closeAll()" [document]="documentData"></app-sign-send-doc>
        
    </div>
</ng-template>