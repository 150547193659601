import { Person } from "../../catalog/person/person-model";
import { InvoicePaymentType } from '../../financial/invoice/invoice-enums';

export class Order {
    public id: any;
    public erpId: any;
    public userId: any;
    public unityId: any;
    public userName: string;
    public clientId: any;
    public clientName: string;
    public transporterId: any;
    public transpName: string;
    public statusDelivery: number;
    public code: string;
    public seqCode: number = 0;
    public date: any;
    public details: string;

    public subTotalValue: number = 0;
    public discountPercentual: number = 0;
    public discountValue: number = 0;
    public transportValue: number = 0;
    public totalValue: number = 0;

    public clientResume: Person;
    public transporterResume: Person;

    public payed: boolean;
    public totalPayments: number = 0;
    public payDevolutionValue: number = 0;
    public payRemainingValue: number = 0;
}

export class OrderItem {
    public id: any;
    public number: number;
    public productId: any;
    public productCode: any;
    public productDescription: string = '';
    public un: string = '';
    public unTrib: string = '';
    public quantTrib: number = 0;
    
    public ncm: string = '';
    public cest: string = '';
    public barCode: string = '';
    public quantity: number = 0;
    public unitValue: number = 0;

    public totalValue: number = 0;
    public discountValue: number = 0;
    public otherValue: number = 0;
    public transpValue: number = 0;
    public removed: boolean = false;
    
}


export class OrderModel {
    public mainData: Order = new Order();
    public itens: OrderItem[] = [];
    public payments: LocalOrderPayment[] = [];
    public invoideCmd: InsertInvoiceOrderModel;
}


export class DeleteItemCmd {
    public id: any;
    public orderId: number;
}

export class InserttemCmd {
    public item: OrderItem;
    public orderId: number;
}


export class UpdateOrderModel {
    public id: any; 
    public clientId: any;
    public statusDelivery: number;
    public details: string;
    public discountPercentual: number = 0;
    public discountValue: number = 0;
    public transportValue: number = 0;
}

export class SetStatusOrderModel {
    public id: any; 
    public transporterId: any;
    public statusDelivery: number;
    public details: string;
    public discountPercentual: number = 0;
    public discountValue: number = 0;
    public transportValue: number = 0;
}

export class InsertInvoiceOrderModel {
    public userId: any;
    public unityId: any;
    public ordersId: any[] = [];
    public personBilledId: any;
    public totalInstallments: number = 1;
    public firstDueDate: any;
    public paymentType: InvoicePaymentType;
}

export class LocalOrderPayment {
    totalInstallments: number;
    paymentType: InvoicePaymentType;
    firstDueDate: any;
    value: number;
}

export class OrderPayCommand {
    orderId: any;
    payment: LocalOrderPayment;
}