
import { EventEmitter, Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { UserService } from './user-service';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { IfStmt } from '@angular/compiler';
import { LocalSignCommand } from '../models/localsign-command';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    public ws: WebSocketSubject<any>;

    public onResponseData = new EventEmitter<any>();

    constructor(private ngxLoader: NgxUiLoaderService, private router: Router, private userService: UserService) {

    }

    connected = false;


    public sendCommand(cmd: LocalSignCommand, waitReturn: boolean, methodName: string = "executecmd"): Promise<any> {
        return new Promise((callback, error) => {

            if (waitReturn)
                try { this.ngxLoader.start(); } catch (err) { }



            this.ws = webSocket({
                url: 'ws://localhost:8080/sign',

                deserializer: ({ data }) => data,

                closeObserver: {
                    next(closeEvent) {
                        console.log(`ws closed..`);
                        this.connected = false;
                    }
                },

                openObserver: {
                    next: () => {
                        console.log('connetion ok');
                        this.connected = true;



                    }
                },


            });

            this.ws.subscribe(

                msg => { // Called whenever there is a message from the server.

                    console.log('message received: ' + msg)
                    var ret: any;
                    try {
                        ret = JSON.parse(msg);
                        this.onResponseData.emit(ret);
                        callback(ret);
                    } catch (error) {
                        error({ message: 'Falha ao interpretar mensagem do servidor!'});
                    }
                    
                    if (waitReturn) try { this.ngxLoader.stop(); } catch (err) { }

                }, 

                err => { // Called if at any point WebSocket API signals some kind of error.
                    this.ws.unsubscribe();
                    this.connected = false;
                    error({ message: 'Falha ao escutar mensagens'});
                    if (waitReturn) try { this.ngxLoader.stop(); } catch (err) { }
                }, 

                () => console.log('complete') // Called when connection is closed (for whatever reason).
            );

            this.ws.next(cmd);

            

        });
    }




}