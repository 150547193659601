import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable } from 'rxjs';
import { City } from './city-model';

const statesList:[City] = require('src/app/data/cities.json');

@Injectable()
export class CityDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('city', {}, sharedInfo, userService, http);

    this.routerTitle = 'Cadastro / Cidades';
  }

  checkZipCode = (zipCode) => {
    return this.http.get<any>(`https://viacep.com.br/ws/${zipCode}/json`);
  }

  findByNameAndUF(cityname, uf): Observable<any> {
    var filterData = {
      unityId: this.userService.selectedUnit.id,
      strFind: cityname,
      uf: uf,
      page: 1,
      take: 20
    };
    return this.http
      .put<any>(this.sharedInfo.serviceUrl + '/' + this.tableName + '/getdata', filterData);
  }

  getColums() {
    return [
      { columnDef: 'ibgeCode', header: 'Código', cell: (row) => `${row.code}` },
      { columnDef: 'name', header: 'Cidade', cell: (row) => `${row.name}` },
      { columnDef: 'uf', header: 'Estado', cell: (row) => `${row.ncm}%` },
    ];;
  }

}
