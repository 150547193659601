import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user-service';
import { HttpClient } from '@angular/common/http';
import { Page, SignRectangle } from 'src/app/dashboard-sign/models/sign-rectangle';
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { FieldInfo, UpdateSignerFieldsCommand } from '../document.models';
import { v4 as uuidv4 } from 'uuid';
import { SignFileEletronicComponent } from '../sign-file-eletronic/sign-file-eletronic.component';
import { SignFileComponent } from '../sign-file/sign-file.component';
import { PublicDocument, Signature, SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prepare-document',
  templateUrl: './prepare-document.component.html',
  styleUrls: ['./prepare-document.component.scss'],
  animations: [SharedAnimations]
})
export class PrepareDocumentComponent implements OnInit {

  token: any;
  
  docId = '';
  documentData: PublicDocument;
  urlDocument: any;
  urlPublicDocument: any;
  currentUserNotSigned = false;
  selectedSignature: Signature;

  width: number = 0;
  height: number = 0;
  movingOffset = { x: 0, y: 0 };
  endOffset = { x: 0, y: 0 };

  pages: Page[] = [];

  pagesWhichFieldsCount: number = 0;

  scale = 1.5;
  scaleField = 1.0;

  imageUrl: any;

  step = 1;
  currentMenu: any;

  isMobile: boolean = false;

  @ViewChild("prepareFieldsTemplate") prepareFieldsTemplate: any;
  @ViewChild("signatureTemplate") signatureTemplate: any;

  constructor(
    public dataService: DocumentService,
    public userService: UserService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private router: Router) {

    this.docId = this.route.snapshot.params['id'] || '';

    
    const pdfWorkerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
    GlobalWorkerOptions.workerSrc = pdfWorkerSrc;
  }

  async ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.token = params['token'] || '';
      if(this.token) {
        console.log('PublicToken', this.token);
        this.userService.publicTokenData = this.token;

        if(!this.userService.userLoged) {

          this.dataService.getUserByToken().then(resp=>{
            this.userService.userLoged = resp.data;
          });
         

        }

      }

      this.loadData();
    });

    if(window.innerWidth <= 700) {
      this.scale = window.innerWidth / 650;
      this.isMobile = true;
      this.lateralMenu = false;
      this.scaleField = 0.6;
    }
    if(window.innerWidth > 700) {
      this.scale = window.innerWidth / 900;
      this.scaleField = 1.0;
    }
    if(window.innerWidth > 900) {
      this.scale = window.innerWidth / 950;
      this.scaleField = 1.0;
    }
    if(window.innerWidth > 1200) {
      this.scale = window.innerWidth / 1000;
      this.scaleField = 1.0;
    }



    setTimeout(() => {
      this.currentMenu = this.prepareFieldsTemplate;
    }, 50);

    console.log('window.innerWidth', window.innerWidth);

  }

  lateralMenu: boolean = true;
  showLateralMenu(){
    this.lateralMenu = true;
  }

  pageOneClick(){
    if(this.isMobile)
      this.lateralMenu = false;
  }

  async loadData(renderPage = true) {

    this.dataService.getPortalSignDetails(this.docId, this.token).subscribe((doc) => {
      this.documentData = doc.data || {};
      this.urlDocument = this.documentData.urlDocument;
      this.urlPublicDocument = this.documentData.urlPublicDocument;


      if(this.documentData.signatures) {   
        if(this.token) {
          this.selectedSignature = (this.documentData.signatures as Array<any>).find(i => i.id === this.documentData.allowedSignatureId)
        }
        else {
          this.selectedSignature = (this.documentData.signatures as Array<any>).find(i => i.userId === this.userService.userLoged.id)
        }

        var requestLogin: boolean = false;
       

        if(requestLogin){
          this.router.navigate(['auth/login'], { queryParams: { reqPage:  this.router.url } });
          return;
        }

      }
      
      if(this.documentData.lastSignature || this.token) {
        this.step = 2;
      }

      setTimeout(() => {

        if(this.documentData.lastSignature || this.token) {
          this.step = 2;
          this.currentMenu = this.signatureTemplate;
        }

       
      }, 100);


      if (renderPage)
        this.render();

    });
  }

  render() {

    this.pages = [];
    var signRectangles: SignRectangle[] = [];

    if(!this.documentData.lastSignature && this.step == 1) {
      (this.documentData.signatures as Array<any>).forEach(signature => {

        if (signature.jsonFields) {
          var jsonFields = JSON.parse(signature.jsonFields) as Array<FieldInfo>;
  
          //debugger;
          jsonFields.forEach(field => {
  
            let rectagle: SignRectangle = {
              id: field.id,
              page: field.page,
              signModel: signature,
              initialHeight: field.height * this.scale,
              initialWidth: field.width * this.scale,
              position: { x: field.x * this.scale, y: field.y * this.scale },
  
              addName: field.addName,
              addDoc: field.addDoc,
              addTitle: field.addTitle,
  
              size: {
                width: field.width * this.scale,
                height: field.height * this.scale
              },
  
              image: field.imgData,
  
              dateSigned: signature.dateSigned
  
            };
  
            signRectangles.push(rectagle);
  
          });
        }
  
      });
    }
    
    this.countPagesHasFields(signRectangles);

    this.renderPdf(signRectangles);

  }

  countPagesHasFields(signRectangles) {
    var g = this.dataService.groupBy(signRectangles, 'page');
    this.pagesWhichFieldsCount = Object.keys(g).length;
  }

  replicFieldsInMorePages () {
    var p01 = this.pages.filter(i=>i.signRectangles && i.signRectangles.length > 0)[0];
    if(p01.signRectangles) {
      this.pages.forEach(page => {
        if(page.pageNumer != p01.pageNumer){
  
          var newFields = JSON.parse(JSON.stringify(p01.signRectangles));
          newFields.forEach(field => {
            field.id= uuidv4();
            field.page = page.pageNumer;
            field.initialWidth = field.size.width;
            field.initialHeight = field.size.height;

          });
          page.signRectangles = newFields;
        }
      });
    }
    this.refreshCountPagesHasFields();
   
  }


  public async renderPdf(signRectangles: SignRectangle[]) {
    //const arrayBuffer = await new Response(this.urlDocument).arrayBuffer();
    // data = arrayBuffer;

    var urlDoc =  this.urlDocument;

    if(this.step == 2 || this.documentData.lastSignature) {
      urlDoc = this.documentData.lastSignature ? this.urlPublicDocument : this.documentData.urlPreparedDocument;
    }
    else {
      urlDoc = this.urlDocument;
    }

    const pdf = await getDocument(urlDoc).promise;
    const pagesCount = pdf.numPages;

    for (var pageNumber = 1; pageNumber <= pagesCount; pageNumber++) {
      this.pages.push({ pageNumer: pageNumber, signRectangles: [] });
    }

    setTimeout(() => {
      this.renderPages(pagesCount, pdf);
    }, 50);


    var timeout = 1000;

    this.pages.forEach(page => {
      var fieldsOfPage = signRectangles.filter(i => i.page == page.pageNumer);
      fieldsOfPage.forEach(element => {

        setTimeout(() => {
          page.signRectangles.push(element);
        }, timeout);

        timeout += 50;

      });
      //debugger;
    });



  }

  public async renderPages(pagesCount, pdf) {
    for (var pageNumber = 1; pageNumber <= pagesCount; pageNumber++) {
      var canvasContainer = document.getElementById('pageContainer' + pageNumber);
      await this.renderPage(canvasContainer, pdf, pageNumber);
    }
  }


  public async renderPage(canvasContainer, pdf, pageNumber) {

    const page = await pdf.getPage(pageNumber);

    const viewPortParams = { scale: this.scale };
    const viewport = page.getViewport(viewPortParams);
    // Support HiDPI-screens.
    var outputScale = window.devicePixelRatio || 1;



    const canvas: any = document.createElement('canvas');

    var ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    canvas.id = pageNumber;
    canvas.width = Math.floor(viewport.width * outputScale);
    canvas.height = Math.floor(viewport.height * outputScale);
    canvas.style.width = Math.floor(viewport.width) + "px";
    canvas.style.height =  Math.floor(viewport.height) + "px";
    
    var transform = outputScale !== 1
      ? [outputScale, 0, 0, outputScale, 0, 0]
      : null;

    this.height = viewport.height;
    this.width = viewport.width;

    const renderContext = {
      canvasContext: ctx,
      viewport: viewport,
      transform: transform
    };

    const renderedPage = await page.render(renderContext).promise;

    canvasContainer.appendChild(canvas);


    //if (pdf != null && pageNumber >= pdf.numPages) pdf.destroy();

    //const res = canvas.toDataURL();
  }



  dragMousedown(item: SignRectangle) {
    if (this.step != 1) return;

    this.pages.forEach(page => {
      if (item.page == page.pageNumer) {
        page.signRectangles.forEach(element => {
          element.selected = true;
        });
      }
    });
    console.log('dragMousedown');
  }

  onDragStart(event) {

  }

  pageClicked(e) {
    if (this.step != 1) return;

    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    console.log(e);
    console.log({ x: x, y: y});

    var page = this.pages.find(i=>i.pageNumer == Number.parseInt(e.target.id));

    this.onDrop({
      data: this.documentData.signatures[0],
      event: {
        layerX: x - 125,
        layerY: y  - 47 
      }
    }, page)
  }

  onDrop(event, page) {
    if (this.step != 1) return;

    var size = {
      width: (this.width / 100) * 25,
      height: ((this.height / 100) * 6 ) - (event.data.signTitle ? 0 : 10) 
    };

    let rectagle: SignRectangle = {
      id: uuidv4(),
      page: page.pageNumer,
      signModel: event.data,
      initialHeight: size.height,
      initialWidth: size.width,
      position: { x: event.event.layerX, y: event.event.layerY },

      addName: true,
      addDoc: true,
      addTitle: event.data.signTitle ? true : false,

      size: size
    };

    page.signRectangles.push(rectagle);

    this.refreshCountPagesHasFields();
    
  }

  refreshCountPagesHasFields(){
    var signRectangles: SignRectangle[] = [];
    this.pages.forEach(p => {
      if(p.signRectangles)
        signRectangles.push(... p.signRectangles);
    });
    this.countPagesHasFields(signRectangles);
  }


  onDragBegin(event) {

  }

  onDragEnd(event) {

  }

  onMoving(event, item: SignRectangle) {
    if (this.step != 1) return;

    this.movingOffset.x = event.x;
    this.movingOffset.y = event.y;

    item.position.x = event.x;
    item.position.y = event.y;
  }

  onMoveEnd(event, item: SignRectangle) {
    if (this.step != 1) return;

    item.moved = true;

    this.endOffset.x = event.x;
    this.endOffset.y = event.y;

    item.position.x = event.x;
    item.position.y = event.y;

    this.pages.forEach(page => {
      if (item.page == page.pageNumer) {
        page.signRectangles.forEach(element => {
          element.selected = false;
        });
      }
    });

    //this.exportImg(item);
  }

  onResizeStart(event: IResizeEvent, item: SignRectangle) {
    if (this.step != 1) return;

    this.pages.forEach(page => {
      if (item.page == page.pageNumer) {
        page.signRectangles.forEach(element => {
          element.selected = true;
        });
      }
    });
  }

  onResizeStop(event: IResizeEvent, item: SignRectangle) {
    if (this.step != 1) return;

    this.pages.forEach(page => {
      if (item.page == page.pageNumer) {
        page.signRectangles.forEach(element => {
          element.selected = false;
        });
      }
    });
  }

  onResizing(event: IResizeEvent, item: SignRectangle) {
    if (this.step != 1) return;
    item.size = event.size;
  }

  updateAddName(item: SignRectangle){
    if(item.addName == true)
      item.size.height = item.size.height + 12;
    else
      item.size.height = item.size.height - 12;

    item.initialHeight = item.size.height;
  }

  updateAddDoc(item: SignRectangle){
    if(item.addDoc == true)
      item.size.height = item.size.height + 10;
    else
      item.size.height = item.size.height - 10;

    item.initialHeight = item.size.height;
  }

  updateAddTitle(item: SignRectangle){
    if(item.addTitle == true)
      item.size.height = item.size.height + 10;
    else
      item.size.height = item.size.height - 10;

    item.initialHeight = item.size.height;
  }

  // width: 220,
  //       height: 75

  // exportImg(item: SignRectangle) {

  //   var node = document.getElementById('widget' + item.id);

  //   //let element = document.querySelector("#rec0001");
  //   html2canvas(node, { scale: 4 }).then(canvas => {
  //     // Convert the canvas to blob
  //     document.body.appendChild(canvas);
  //     item.image = canvas.toDataURL('image/jpeg', 1.0);

  //     canvas.toBlob(blob => {
  //       var unsafeImageUrl = URL.createObjectURL(blob);
  //       this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  //     }, 'image/png');
  //   });
  // }

  openLink(url) {
    window.open(url, '_blank');
  }



  pageRendered() {
    //this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  addSigner(userSigInfo) {
    this.dialog.open(userSigInfo);
  }

  onAddSigner() {
    this.loadData(false);
    this.dialog.closeAll();
  }

  removeSigner(id) {
    this.dataService.deleteSigner(this.documentData.id, id).subscribe(ret => {
      this.loadData();
    });
  }

  removeField(id) {
    this.pages.forEach(page => {
      var item = page.signRectangles.find(i => i.id == id);
      if (item) {
        var index = page.signRectangles.indexOf(item);
        page.signRectangles.splice(index, 1);
      }
    });
    this.refreshCountPagesHasFields();
  }

  async clearFiels() {
    var cmd: UpdateSignerFieldsCommand = new UpdateSignerFieldsCommand();
    cmd.documentId = this.documentData.id;
    cmd.fields = [];
    cmd.clearFields = true;
    this.dataService.updateSignerFields(cmd).subscribe(resp => {
      if (resp.success) {
        this.loadData();
      }
    });
  }

  goEdit() {
    this.step = 1;
    this.currentMenu = this.prepareFieldsTemplate;
    this.render();
  }


  signDoc(userSigInfo) {

    var documentList = [
      {
        erpId: this.documentData.erpId,
        publicDocumentId: this.documentData.id,
        documentName: this.documentData.name,
        signatureId: userSigInfo.id,
        userId: userSigInfo.userId,
        documentNumber: userSigInfo.documentNumber,
        email: userSigInfo.email,
        printProtocolPage: this.documentData.printProtocolPage,
        fields: JSON.parse(userSigInfo.jsonFields)
      }];


      this.signFileEletronicComponent(userSigInfo, documentList);

   

  }

  // signDocument(documentList) {

  //   const modalRef = this.dialog.open(SignFileComponent, {
  //     panelClass: 'mat-dialog-fix',
  //     disableClose: false,
  //     //data : this.newSelectedCompany
  //   });


  //   //modalRef.result.then((result) => { }, (reason) => { });

  //   modalRef.componentInstance.documentList = documentList;
  //   modalRef.componentInstance.onSaved.subscribe((resp) => {
  //     this.loadData(true);
  //     modalRef.close();
  //   });
  //   modalRef.componentInstance.onCancelClick.subscribe(() => {
  //     modalRef.close();
  //   });
  // }


  signFileEletronicComponent(userSigInfo, documentList) {

    const modalRef = this.dialog.open(SignFileEletronicComponent, {
      panelClass: 'mat-dialog-fix',
      disableClose: false,
      //data : this.newSelectedCompany
    });

    //modalRef.result.then((result) => { }, (reason) => { });

    modalRef.componentInstance.userSigInfo = userSigInfo;
    modalRef.componentInstance.documentList = documentList;
    modalRef.componentInstance.pubToken = this.token;

    modalRef.componentInstance.onSaved.subscribe((resp) => {
      this.loadData(true);
      modalRef.close();
    });
    modalRef.componentInstance.onCancelClick.subscribe(() => {
      modalRef.close();
    });
  }





  tryError(error){
    const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.key === '') {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

  }


  async save() {

    var cmd: UpdateSignerFieldsCommand = new UpdateSignerFieldsCommand();

    cmd.documentId = this.documentData.id;
    cmd.fields = [];

    this.pages.forEach(page => {

      page.signRectangles.forEach(element => {

        var field = new FieldInfo();

        field.id = element.id;
        field.page = page.pageNumer;
        field.method = element.signModel.method;
        field.signatureId = element.signModel.id;

        field.x = element.position.x / this.scale;
        field.y = element.position.y / this.scale;

        field.width = element.size.width / this.scale;
        field.height = element.size.height / this.scale;

        field.addDoc = element.addDoc;
        field.addName = element.addName;
        field.addTitle = element.addTitle;

        field.imgData = element.image;

        cmd.fields.push(field);




      });

    });





    this.dataService.updateSignerFields(cmd).subscribe(resp => {
      if (resp.success) {
        this.currentMenu = this.signatureTemplate;
        this.step = 2;
        this.loadData();
      }
    });

  }


  publishDocument() {
    this.dataService.publishDocumentOnLine(this.documentData.id).subscribe(ret => {
      if (ret.success === true) {
        this.loadData();

      }
    }, error => {
      this.tryError(error);
    });
  }

  sendEmailDoc(sendDocumentDialog) {
    this.dialog.open(sendDocumentDialog);
  }

  onSendDocumentSigned(event) {
    Swal.fire('Pronto...', 'E-mail enviado com sucesso!', 'success');
    this.dialog.closeAll();
  }

  sendRequestSignatures() {

    this.dataService.requestSignatureFromEmail(this.documentData.id, null, '', 'Assinar Documento', '').subscribe(ret => {

      if (ret.success === true) {
        Swal.fire('Feito', 'As solicitações foram enviadas por E-mail<BR>', 'success');
      }

    }, error => {
      this.tryError(error);
    });

  }

}
