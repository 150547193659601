
import { Component, Output, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';

import { DocumentService } from 'src/app/dashboard-sign/views/document/document.service';
import { DomainNotification } from '../../../../models/domainNotification';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { switchMap, debounceTime, tap, finalize, distinctUntilChanged, catchError, map, startWith } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { fail } from 'assert';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-sign-send-doc',
  templateUrl: './sign-send-doc.component.html',
  styleUrls: ['./sign-send-doc.component.scss']
})
export class SignSendDocComponent implements OnInit {


  @Output()
  public onCancelClick = new EventEmitter<any>();
  @Output()
  public onSended = new EventEmitter<any>();

  @Input()
  document: any;

  isLoading = false;

  formErrors: DomainNotification[] = [];


  subjectUsers: Subject<any> = new Subject();
  isLoadingUsers = false;

  emailsHistory: any[] = [];
  filteredOptions: any[] = [];

  mailTo: string;
  subject: string = '';
  message: string = 'Conforme combinado, Segue o documento assinado';

  constructor(private http: HttpClient, public dataService: DocumentService, private formBuilder: FormBuilder) {


  }

  ngOnInit(): void {
    this.subject = this.document.name;
    var emHist = localStorage.getItem('dnErp.emailsHistory');
    if (emHist) {
      this.emailsHistory = JSON.parse(emHist);
    }
    

    this.subjectUsers
    .pipe(debounceTime(800))
    .subscribe((value) => {
      if (value.length >= 2) {
        this.isLoadingUsers = true;
        this.filteredOptions = this._filter(value);
        this.isLoadingUsers = false;
      }
    });

    
    

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.emailsHistory.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  getUsers(event: any) {
    this.subjectUsers.next(event.target.value);
    // var inp = String.fromCharCode(event.keyCode);
    // if (/[a-zA-Z0-9-_ ]/.test(inp)) {
    //   let searchTerm = '';
    //   searchTerm += event.target.value;
    //   this.subjectUsers.next(searchTerm);
    // }
  }


  userSelected(event) {
    //this.data.personId = event.option.value.id;
  }


  onSelectedEmail(event) {

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }


  submit() {

    this.isLoading = true;
    this.dataService.sendDocumentFromEmail(this.document.id, this.mailTo, this.subject, this.message).subscribe(ret => {
      this.isLoading = false;
      if (ret.success === true) {

        this.onSended.emit(null);

        if(this.emailsHistory.find(i=>i == this.mailTo) == null){
          this.emailsHistory.push(this.mailTo);
          localStorage.setItem('dnErp.emailsHistory', JSON.stringify(this.emailsHistory));
        }
        
        
      }

    }, fail => {
      this.isLoading = false;
      if (fail.error.errors) {
        this.formErrors = fail.error.errors;
      }
    });


  }


}
