import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { DomainNotification } from 'src/app/models/domainNotification';
import { AdComponent } from 'src/app/models/ad.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { Person } from '../person-model';
import { PersonDataService } from './person.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Address, AddressType } from '../../city/adress-model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CityDataService } from '../../city/city.service';
import { City } from '../../city/city-model';

const citiesList:[City] = require("../../../../../data/cities.json");

@Component({
  selector: 'app-person-add-edit',
  templateUrl: './person-add-edit.component.html',
  styleUrls: ['./person-add-edit.component.scss'],
  animations: [SharedAnimations]
})
export class PersonAddEditComponent implements AfterViewInit, OnInit, AdComponent {

  title: string = '';

  @Output() public onCancelClick = new EventEmitter<any>();
  @Output() public onSaved = new EventEmitter<any>();
  @Input() idForEdit: string;
  @Input() tableName: string;
  @Input() data: Person;

  editingAddress: boolean = false;
  selectedAddress: Address;
  principalAddress: Address;

  @ViewChild('btnOk') btnOk;
  @ViewChild('txtName') txtName;

  loading = false;
  formErrors: DomainNotification[] = [];


  constructor(
    private dataService: PersonDataService,
    public deviceService: DeviceDetectorService,
    public cityDataService: CityDataService,
    public sharedInfo: SharedInfo) {
  }


  ngOnInit() {

    // citiesList.forEach(element => {
    //   element.code = Number.parseInt(element.code.toString());
    //   element.siaf = Number.parseInt(element.siaf.toString());
    //   element.st = Number.parseInt(element.st.toString());
    //   element.region = Number.parseInt(element.region.toString());
    // });

    console.log(JSON.stringify(citiesList));

    if (this.data == null) {
      this.data = new Person();
      this.title = 'Adicionar Pessoa';
    }
    else {
      this.title = 'Editar Pessoa';
    }

    this.sharedInfo.onChangePopUpTitle.emit(this.title);

    setTimeout(() => {
      if (this.deviceService.isMobile() == false) {
        //this.txtName.focus();
      }
    }, 150);

    if(this.data.adresses == null){
      this.data.adresses = [];
    }

    if (this.data.adresses.length > 0) {
      var mainAdresses = this.data.adresses.filter(i => i.streetType == 0);
      if (mainAdresses) {
        this.principalAddress = mainAdresses[0];
      }
      this.data.adresses.forEach(element => {
        element.city = this.citiesList.find(i=>i.code == element.cityCode);
      });
    }

    if (this.principalAddress == null) {
      this.principalAddress = new Address();
      this.principalAddress.streetType = 0;
      this.data.adresses.push(this.principalAddress);
    }

    this.initializeSearchCities();
  }

  ngAfterViewInit(): void {
    console.log('idForEdit', this.idForEdit);
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  addAddress(){
    this.selectedAddress = new Address();
    this.selectedAddress.streetType = null;
    this.editingAddress = true;
  }

  removeAddress(item: Address){
    item.removed = true;
  }
  includeAddress(item: Address){
    item.removed = false;
  }



  ///////////////////////////////////////////////////////////////
  ////// BUSCA DE CIDADES  //////////////////////////////////////
  citiesList = citiesList;
  filteredCities: any[] = [];
  subjectCities: Subject<any> = new Subject();
  isLoadingCities: boolean = false;

  initializeSearchCities(){
    this.subjectCities
      .pipe(debounceTime(800))
      .subscribe((value) => {
        if (value.length >= 2) {
          this.isLoadingCities = true;

          value = value.toString().toLowerCase();
          
          this.filteredCities = this.citiesList.filter(i=>i.name.toLowerCase().indexOf(value) > -1);
          this.isLoadingCities = false;


          // this.cityDataService.findData(value).subscribe(ret => {
          //   this.isLoadingCities = false;
          //   this.filteredCities = ret.data.itens
          // }, err => { this.isLoadingCities = false });
          
        }
      });
  }

  getFirstCityFromName(cityibge, uf, address: Address): Promise<any> {

    return new Promise((resolve, reject) => {

      this.filteredCities = this.citiesList.filter(i=>i.code == cityibge);

      if (this.filteredCities.length) {
        address.city = this.filteredCities[0];
        address.cityCode = Number.parseInt(cityibge);
      }

      resolve(this.filteredCities);

      // this.isLoadingCities = true;
      // this.cityDataService.findByNameAndUF(cityname, uf).subscribe(ret => {
      //   this.isLoadingCities = false;
      //   this.filteredCities = ret.data.itens
      //   if (this.filteredCities.length) {
      //     address.city = this.filteredCities[0];
      //     address.cityCode = address.city.ibgeCode;
      //     resolve(ret.data.itens);
      //   } else {
      //     address.city = null;
      //     address.cityCode = null;
      //     resolve({});
      //   }


      // }, err => { this.isLoadingCities = false; reject()});

    });


  }

  getCities(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      let searchTerm = '';
      searchTerm += event.target.value;
      this.subjectCities.next(searchTerm);
    }
  }

  cityDisplayFn(item: City) {
    if (item) { return item.name + ' - ' + item.uf; }
  }

  citySelected(event, address: Address) {
    console.log('address', address);
    address.cityCode = Number.parseInt(address.city.code.toString());
  }


  checkAndFixZipCode(address: Address, addessNumberInput) {
    this.loading = true;
    this.cityDataService.checkZipCode(address.zipCode).subscribe(ret => {
      this.loading = false;
      if (ret.erro == true) {
        console.log('CEP Inválido!');
      } else {
        console.log('CEP Encontrado!');

        this.getFirstCityFromName(ret.ibge, ret.uf, address).then(resolve =>{

          address.village = ret.bairro;
          address.street = (ret.logradouro ? ret.logradouro : address.street);
          if(addessNumberInput){
            if(address.city != null && address.village != null && address.street != null){
              addessNumberInput.focus();
            }
          }

        });

       
      }

    }, err => {
      this.loading = false;
    })
  }

  cepFocusOut(address: Address, addessNumberInput){
    console.log('addessNumberInput', addessNumberInput);
    if(address.city == null && address.zipCode && address.zipCode.length >= 8){
      this.checkAndFixZipCode(address, addessNumberInput);
    }
  }
  
  ///////////////////////////////////////////////////////////////

  submit() {
    this.formErrors = [];

    // if (this.data.groupId == null) {
    //   this.sharedInfo.onNotifierError.emit({ type: "warn", msg: 'Informe a Categoria!' })
    //   return;
    // }
    this.data.adresses = this.data.adresses.filter(i=>i.removed == false || i.removed == null || i.id);

    this.loading = true;
    this.dataService.insertUpdateItem(this.data).subscribe(ret => {
      this.loading = false;
      if (ret.success === true) {
        this.onSaved.emit({ id: this.idForEdit, data: ret.data });
      }
    }, error => {
      this.loading = false;
      const ret = error.error;
      if (ret.errors) {
        this.formErrors = ret.errors;
      }
    });
  }

}
