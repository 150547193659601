import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as moment from 'moment';

//Services
import { UserService } from './services/user-service'
import { LoginDataService } from './services/login-service'
import { TranslateService } from '@ngx-translate/core';
import { SharedInfo } from './services/shared-info';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { NotifierMessage } from './models/notifier-message';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private userService: UserService,
    private dataService: LoginDataService,
    public translate: TranslateService,
    public sharedInfo: SharedInfo,
    private _notifications: NotificationsService) {

  

    userService.loadUser();


    this.userService.onApiCalled.subscribe(o => {

      if (this.router.url.indexOf('auth/mail-confirm') < 0) {
        var refreshToken = localStorage.getItem("dnErp.refreshToken");
        var expiration = localStorage.getItem("dnErp.refreshTokenIn");
        if (refreshToken && expiration) {

          var dtNow = moment();
          var dtExp = moment(expiration).add();

          if (dtNow > dtExp) {
            //Atualizar token sem pedir login
            this.refreshToken(refreshToken);
          }
        }

      }

    });


    this.sharedInfo.onNotifierError.subscribe((data: NotifierMessage) => {
      // types = ['alert', 'error', 'info', 'warn', 'success'];
      // animationTypes = ['fromRight', 'fromLeft', 'scale', 'rotate'];
      var temp = {
        type: data.type,
        title: 'Atenção',
        content: data.msg,
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        preventLastDuplicates: true,
        animate: 'fromLeft'
      }
      this._notifications.create(temp.title, temp.content, <NotificationType>data.type, temp);
    });

  }

  
  ngOnInit() {

    setTimeout(() => {
      var url = this.router.url.toString();
      if (this.userService.tokenData == null) {
        if ((url.indexOf('mail-confirm') < 0)
          && url.indexOf('forgot') < 0) {
          console.log('To Login, From App');
          //this.router.navigate(['auth/login']);
        }
      }
    }, 400);

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        window.scrollTo(0, 0);
      }
      else {
        return;
      }
    });
  }


  loadUserData() {
    this.dataService.getUserData('').subscribe(response => {
      if(response && response.success == true){
        this.userService.saveUser(response.data);
        this.userService.loadUser();
      }
    }, err=>{ });
  }

  refreshToken(refreshToken) {

    if ((this.router.url.indexOf('auth/mail-confirm') >= 0)
      || this.router.url.indexOf('auth/forgot') >= 0) {
      return;
    }

    this.dataService.authenticate({ email: '', password: '', rememberMe: false, refreshToken: refreshToken })
      .subscribe(ret => {
        console.log(ret);

        if (ret.success === true && ret.data) {
          this.userService.saveToken(ret.data.accessToken, ret.data.refreshToken, ret.data.expiration);
          //this.loadUserData();

        } else {
        }
      },
        respError => {
          console.log(respError);

          localStorage.removeItem("dnErp.refreshToken");
          localStorage.removeItem("dnErp.refreshTokenIn");

          if (this.router.url.indexOf('auth/login') < 0) {
            this.router.navigate(['auth/login'], { queryParams: { reqPage: this.router.url } });
            
          }
        });
  }

}
