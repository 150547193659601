import { TranslateService } from "@ngx-translate/core";
import { Person } from "../../catalog/person/person-model";

export class TaskProcessResumeViewModel {
    id: string;
    personId: string | null;
    insertedDate: Date;
    firstDateOfTask: Date;
    lastExecutedDate: Date | null;
    repetitionPeriod: RepetitionPeriod;
    endDate: Date | null;
    repetitionValue: number | null;
    name: string;
    description: string;
    active: boolean;
    personName: string;
    personResume: Person;
}

export enum RepetitionPeriod {
    NoRepeat = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Custom = 99
}


export class EnumLists {

    constructor(public translate : TranslateService){

    }

     get RepetitionPeriod():any[] {

        var ret = [];

        for (const value in Object.keys(RepetitionPeriod)) {
            //if (typeof RepetitionPeriod[value] !== "string") {
              //  continue;
           // }

            ret.push({
                Value: value,
                Name: this.translate.instant('RepetitionPeriod.' + RepetitionPeriod[value] )
            });
        
           
        }

        return ret;
     }
}
