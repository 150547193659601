export enum FinOperationType {
    Debit = 0,
    Credit = 1
}

export enum InvoiceOrigin {
    None = 0,
    Orders = 1,
    Purchase = 2,
    Contract = 3
}

export enum InstalmentStatus {
    Pending = 0,
    PartialSettled = 1,
    Settled = 10,
    Canceled = 255,
    ReParceled = 555
}
