import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable } from 'rxjs';
import { PersonFilterModel } from '../person-filter';


@Injectable()
export class PersonDataService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('person', {}, sharedInfo, userService, http);

    this.routerTitle = 'Cadastro / Pessoas';
  }

  findPersonData(filterData: PersonFilterModel): Observable<any> {
    return this.http
      .put<any>(this.sharedInfo.serviceUrl + '/' + this.tableName + '/getdata', filterData);
  }

  getColums() {
    return [
      { columnDef: 'name', header: 'Nome', cell: (row) => `${row.name}` },
      { columnDef: 'cnpjcpf', header: 'CNPJ\CPF', cell: (row) => `${row.cnpjcpf}%` },
    ];;
  }

}
