import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardPdvPageRoutingModule } from './dashboard-routing.module';
import { DashboardPdvPage } from './dashboard.page';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared-modules';
import { PdvHomePageComponent } from './home-page/home-page.component';
import { PdvSidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';

import { PDVComponent } from './comp/pdv/pdv.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PdvHeaderComponent } from './header/header.component';

import { TimelineModule } from "angular2-timeline";
import { InvoiceDataService } from '../dashboard/views/financial/invoice/invoice.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from '../services/configs/customCurrencyMaskConfig';
import { LocalOrdersComponent } from './comp/local-orders/local-orders.component';

import { OrderPaymentModalComponent } from 'src/app/comp/order-payment-modal/order-payment-modal.component'

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  imports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    DashboardPdvPageRoutingModule,
    SharedModule,
    MaterialModule,
    TimelineModule,

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [
    DashboardPdvPage, 
    PdvHomePageComponent,
    PdvSidenavMenuComponent,

    PDVComponent,

    PdvHeaderComponent,

    LocalOrdersComponent,

    


  ],

  entryComponents: [
    OrderPaymentModalComponent
  ],

  providers:[
    InvoiceDataService,
  ]
})
export class DashboardPdvPageModule {}
