<div class="setStatusDialog">

    <div class="st-header">
        <span class="title">Lançar Pagamento</span>
        <span class="rem-value">Restante: {{ currentOrder.payRemainingValue | currency: 'BRL'}}</span>
    </div>

    
    <hr>


    <div class="row">

        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Forma de Pagamento</mat-label>
                <mat-select matInput [(ngModel)]="payCommand.payment.paymentType" (selectionChange)="onSelectPaymentType($event.value, payInst, payValue);">
                    <mat-option *ngFor="let payment of paymentsActives" [value]="payment.key">{{ payment.option }}  - {{ payment.key | paymentType }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>


    <div class="row" >
        <div class="col-md-5">
            <mat-form-field class="full-width" appearance="outline" [ngStyle]="{'display': payCommand.payment.paymentType && (payCommand.payment.paymentType != 1 && payCommand.payment.paymentType != 4) ? 'block' : 'none'}" >
                <mat-label>{{ 'order_add_edit.total_installments' | translate }}</mat-label>
                <input #payInst matInput type="number" min="1"
                    [(ngModel)]="payCommand.payment.totalInstallments" style="color: rgb(0, 37, 138);  font-size: 22px;" (keyup)="payInstValueKeyUp($event, payValue)">
            </mat-form-field>
        </div>

        <div class="col-md-7">
            <mat-form-field class="full-width" appearance="outline" [ngStyle]="{'display': payCommand.payment.paymentType && (payCommand.payment.paymentType != 1 && payCommand.payment.paymentType != 4) ? 'block' : 'none'}">
                <mat-label>{{ 'order_add_edit.firstDueDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="firstDueDate" [(ngModel)]="payCommand.payment.firstDueDate" style="color: rgb(0, 37, 138);  font-size: 22px;">
                <mat-datepicker-toggle matSuffix [for]="firstDueDate"></mat-datepicker-toggle>
                <mat-datepicker #firstDueDate></mat-datepicker>
            </mat-form-field>
        </div>
        
    </div>

    <div class="row">

       
        <div class="col-md-5">

        </div>

       

        <div class="col-md-7">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ 'value' | translate }}</mat-label>
                <input #payValue matInput type="text" currencyMask [(ngModel)]="payCommand.payment.value"
                    style="color: rgb(0, 37, 138); font-size: 22px;" (keyup)="paymentValueKeyUp($event)">
            </mat-form-field>
        </div>




    </div>

    <button mat-raised-button class="btn btn-rounded ladda-button m-1" color="primary" style="float: right;"
        [disabled]="payCommand.payment.value <= 0 || payCommand.payment.paymentType == null" (click)="sendPayment();">
        Salvar <i class="material-icons">save</i>
    </button>
</div>