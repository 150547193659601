<section class="app-overlay" mySearchOverlay>

    <mat-toolbar  class="app-header">
      <div class="app-header-inner">

        <div class="float-left">
           
            <button id="menuButton" mat-button class="md-button header-icon toggle-sidebar-btn" id="overlay-close" (click)="closeModal();" >  <!--  [@flip]="toggledMenu" -->
              <i class="material-icons">arrow_back</i>
            </button>
    
            <span >{{title}}</span>
          </div>
    
          <div class="top-nav-right">
            <ul class="list-unstyled float-right" *ngIf="currentForm">
              <li>
                <button mat-button class="md-button header-btn"  *ngFor="let item of currentForm.commands" (click)="executeCommand(item.cmd);">
                  <mat-icon mat-list-avatar>{{item.icon}}</mat-icon>
                </button>        
              </li>
            </ul>
    
    
            <ul class="list-unstyled float-right" *ngIf="currentForm">
              <li>
                <button  mat-button class="md-button header-btn" [matMenuTriggerFor]="menu" aria-label="Opções">
                  <mat-icon>more_vert</mat-icon>
                </button>    
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngFor="let item of currentForm.moreCommands" (click)="executeCommand(item.cmd);">
                    <mat-icon mat-list-avatar>{{item.icon}}</mat-icon>
                    <span>{{item.label}}</span>
                  </button>
                </mat-menu>  
              </li>
            </ul>
    
          </div>
          
      </div>
    
  

  

    </mat-toolbar>
  
    <div class="app-overlay-inner">
      <div class="overlay-header">
        <!-- <a href="javascript:;" id="overlay-close" class="overlay-close">
          <i class="material-icons">close</i>
        </a> -->
      </div>
      <div class="overlay-content">
        <!-- <input id="overlay-search-input" readonly
             class="overlay-search-input" 
             placeholder="{{title}}"
             > -->
  
        
        <div class="main-body">
  
          <ng-template #formcontainer form-container>
          </ng-template>
  
          
        </div>
  
        <div class="text-small text-muted">
          <span>Press <em>Esc</em> key to dismiss</span>
        </div>
  
        
  
  
  
      </div>
    </div>
  </section>
  
  
  