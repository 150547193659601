import { Component, OnInit, Output, EventEmitter, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { AdComponent } from 'src/app/models/ad.component';

import { SharedAnimations } from 'src/app/shared/animations/shared-animations';

import { UserService } from 'src/app/services/user-service';


import { Subscription } from 'rxjs';

import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-signature',
  templateUrl: './signature-page.component.html',
  styleUrls: ['./signature-page.component.scss'],
  animations: [SharedAnimations]
})
export class SignatureComponent implements AfterViewInit, OnInit, OnDestroy, AdComponent {

  title: string = '';
  data: any;
  loading = false;

  
  //@ViewChild('btnOk') btnOk;

  keyUpSubscription: Subscription;
  public onKeyUpEvent: EventEmitter<KeyboardEvent>;
  @Output() public onSaved = new EventEmitter<any>();


  constructor(
    private userService: UserService,
    private ngxLoader: NgxUiLoaderService) {

    this.title = 'Portal de assinaturas';

    this.onKeyUpEvent = new EventEmitter<KeyboardEvent>();
    this.keyUpSubscription = this.onKeyUpEvent.subscribe((event: KeyboardEvent) => {

      //console.log('event', event);
      if (this.loading == true) {
        return;
      }

      if (event.altKey == true) {
        if (event.code == 'F5') {
          event.preventDefault();
          //this.confirmCancel();
        }
      }

    });
  }
  

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    var loader = this.ngxLoader.getLoader();
    if (loader && loader.tasks && loader.tasks['fg-default']) {
      return;
    } else {
      //console.log(event)
      this.onKeyUpEvent.emit(event);
    }
  }

  ngOnDestroy() {
    this.keyUpSubscription.unsubscribe();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    //console.log('idForEdit', this.idForEdit);
  }


}
