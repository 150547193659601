<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}"
>
    <mat-card class="card-content">

        <div class="card-body">

            <div style="max-height: calc(100vh - 260px);  overflow: overlay;">
            
                <div *ngFor="let item of data.userRequirementData">

                    <mat-checkbox [(ngModel)]="item.active" (change)="setAll($event.checked, item)" color="primary">
                        {{ 'cl_module.' + item.module | translate }}
                    </mat-checkbox>
    
                   
    
    
                    <div *ngFor="let chItem of item.childs" style="margin-left: 20px;">
    
                        <mat-checkbox *ngIf="chItem.type != null" [(ngModel)]="chItem.active"
                            (change)="setChild($event.checked, item, chItem)" color="primary">
                            {{ 'cl_child.' + chItem.type | translate }}
                        </mat-checkbox>
    
    
                        <div *ngFor="let action of chItem.actions" style="margin-left: 20px;">
    
                            <mat-checkbox *ngIf="action.action != null" [(ngModel)]="action.active"
                                (change)="setAction($event.checked, item, chItem, action)" color="primary">
                                {{ 'cl_action.' + action.action | translate }}
                            </mat-checkbox>
        
        
                        </div>
    
    
                    </div>
    
                    <hr>
    
                </div>

            </div>


          

            <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false">
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                    (click)="submit();">Salvar</button>
            </div>


        </div>

    </mat-card>
</div>