import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { UrlOrign } from 'src/app/enums/urlorign';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeUserClientComponent } from '../change-userclient/change-userclient.component';


@Injectable()
export class UserPermissionService extends GenericDataService {


  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient,) {
    super('userPermission', {}, sharedInfo, userService, http,
      {
        add: false,
        fetch: true,
        timeLimeMode: false,
        tableMode: true,
        urlOrign: UrlOrign.Auth
      });
    this.routerTitle = 'Usuários';
  }

  getColums() {
    return [
      { columnDef: 'name', header: 'Nome', cell: (row) => `${row.name}` },
      { columnDef: 'email', header: 'E-mail', cell: (row) => `${row.name}` },
      { columnDef: 'clientName', header: 'Empresa', cell: (row) => `${row.name}` },
    ];;
  }

  updateClaims(data): Observable<any> {
    let roteUrl = '/' + this.tableName + '/update';
    return this.http.put<any>(this.url + roteUrl, data);
  }



}
