import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user-service';
import { MenuItem, MenuSubItem } from '../../models/menu-model';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedInfo } from '../../services/shared-info';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'signature-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})

export class SignatureSidenavMenuComponent implements OnInit {

  environment = environment;
  
  public AppConfig: any;
  menulist: MenuItem[] = [];

  @Input()
  isExpanded: boolean = false;

  @Input()
  navWidth: number = 220;

  @Output()
  public onOptionClicked = new EventEmitter<any>();

  linkColor: string = "#a1a1a1";
  linkColorActive: string = "#fafafa";

  backLinkColorActive: string = "rgba(0, 0, 0, 0.05)";

  backLinkColorActiveSubItem = "rgba(0, 0, 0, 0.08)";

  constructor(public userService: UserService,
    public translate: TranslateService,
    private router: Router,
    public sharedInfo: SharedInfo) {

      this.AppConfig = sharedInfo.AppConfig;

      this.router.events.subscribe(event => {

        if (event instanceof NavigationEnd) {
          this.AppConfig = this.sharedInfo.AppConfig;
          this.setColorItem(event.url);
        }
        else {
          return;
        }
  
        this.setColorlink();
      });
  }

  navigate(menu){
    if(menu.routerLink){
      this.router.navigate([menu.routerLink]);
      this.onOptionClicked.emit(menu);
    } else {
      this.toggleOptionMenu(menu);
    }
    
  }

  setColorlink(){
    if(['31','32','33','34','35','36'].indexOf(this.AppConfig.colorOption) > -1){
      this.linkColor = "rgba(0, 0, 0, 0.87)";
      this.linkColorActive = "#2196F3";
      this.backLinkColorActive = "rgba(0, 0, 0, 0.05)";
      this.backLinkColorActiveSubItem = "rgba(0, 0, 0, 0.08)";
    }
    else {
      this.linkColor = "#a1a1a1";
      this.linkColorActive = "#fafafa";
      this.backLinkColorActive = "#212529";
      this.backLinkColorActiveSubItem = "rgb(79 79 79 / 50%)";
    }
  }

  setColorItem(url: string) {

    setTimeout(() => {
      
      this.menulist.forEach(element => {

        element.activeRoute = (url == element.routerLink);
        element.selected = (url == element.routerLink);

        element.itens.forEach(subItem => {
          if (url == subItem.routerLink) {
            element.activeRoute = true;
            element.selected = true;
            subItem.activeRoute = true;
            subItem.selected = true;
          } else {
            subItem.activeRoute = false;
          }
        });
  
      });
    }, 200);

    
  }

  toggleOptionMenu(item){
    var selected = !item.selected;

    this.menulist.forEach(element => {
      element.selected = false;
    });

    if(item.itens.length > 0){
      item.selected = selected;
    } else {
      item.selected = true;
    }
  }

  ngOnInit(): void {
      setTimeout(() => {
      
      if (this.userService.userLoged) {

        var home = new MenuItem("home","view");
        home.itens = [];
        home.itens.push(this.setMenuItemRotes(new MenuSubItem("app", "exit")));

        this.menulist.push(this.setMenuRotes(home));

  
        console.log(this.menulist);

        this.setColorItem(this.router.url);

      }

    }, 200);
  }

  setMenuRotes(item: MenuItem) {
    var typeValue = item.type + '_' + item.value;

    switch (typeValue) {

      case 'home_view':
      {
        item.icon = 'exit_to_app';
        item.label = 'App';
        item.routerLink = '';
        break;
      }

    

      default:
        break;
    }

    return item;
  }


  setMenuItemRotes(item: MenuSubItem) {
    var typeValue = item.type + '_' + item.value;

    switch (typeValue) {

      case 'app_exit':
      {
        item.icon = '';
        item.label = 'Sair do signature'; // this.translate.instant('stock.products');
        item.routerLink = 'app/home';
        break;
      }


      default:
        break;
    }

    return item;
  }

}
