<section class="container-fluid with-maxwidth chapter">

  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <span class="active">{{ appService.routerTitle }}</span>
    </li>
  </ul>

  <div class="webcomands row">
    <div class="col-md-8">


      <div class="row">

        <div class="col-xs-12 col-md-4">
          <mat-form-field class="full-width">
            <mat-icon matSuffix class="material-icons">search</mat-icon>
            <input matInput #strFindControl placeholder="Buscar" [(ngModel)]="strFind" ngDefaultControl
              (ngModelChange)='updateFilter($event)'>
          </mat-form-field>
        </div>


        <div class="col-xs-12 col-md-3">
          <mat-form-field *ngIf="appService?.filterData?.startDate != null" class="period-fields">
            <mat-label>Data Inicial</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.startDate"
              (dateChange)="fetch(null)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field *ngIf="appService?.filterData?.endDate != null" class="period-fields">
            <mat-label>Data Final</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="appService.filterData.endDate"
              (dateChange)="fetch(null)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>






      </div>


      <a data-toggle="tooltip" title="Busca Avançada" class="search-icon  m-r-15"
        (click)="openModal(filterModal, 'md')">
        <i class="nav-icon i-Magnifi-Glass1"></i>
      </a>
    </div>

    <div class="col-md-4">
      <div class="webcomands_buttons">

        <a mat-raised-button (click)='fetch()' *ngIf="appService.options?.fetch == true">
          <i class="cmd-icon nav-icon i-Reload"></i> Atualizar
        </a>

        <a mat-raised-button color="primary" (click)='addItem(addFileDialog)' style="margin-left: 5px;"
          *ngIf="appService.options?.add == true">
          <i class="cmd-icon nav-icon i-Add"></i> Enviar Arquivo
        </a>

     

      </div>

    </div>

  </div>


  <div class="desktop-table-container">

    <mat-card class="card-data-content"
      [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">

      <div class="card-table-web">

        <table class="data-table" [dataSource]="data" mat-table matSort matSortActive="dateUploaded"
          matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="dateUploaded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data de Envio </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.dateUploaded | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="datePublish">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Data de Publicação </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.datePublish | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nome do Arquivo </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]> {{row.name}} </td>
          </ng-container>





          <ng-container matColumnDef="cmd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> </th>
            <td mat-cell *matCellDef="let row" [@myfadeIn]>
              <div style="display: flex; float: right;">

                <button mat-raised-button class="btn-sm btn_cmd"  style="margin-right: 5px; color: red;" *ngIf="(row.datePublish === null)"
                  (click)="delete(row); $event.stopPropagation();">
                  <i class="material-icons">delete</i></button>

                <a mat-raised-button class="btn-sm btn_cmd" [routerLink]="['/signature/document/edit/', row.id]"
                  (click)="$event.stopPropagation();">
                  <i class="material-icons">edit</i></a>

                

              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="selectedItem = row;"
            [ngStyle]="{'background-color': selectedItem && row.id == selectedItem.id ? 'rgb(138, 213, 248)' : ''}">
          </tr>
        </table>


      </div>




    </mat-card>

    <mat-card class="card-bottom-data-table">
      <div>

        <a mat-raised-button  (click)="openSignAll(onSetUserForSignTemplate)" style="margin-left: 5px;">
          <i class="cmd-icon nav-icon i-Add"></i> Assinatura em Lote
        </a>

        <mat-paginator class="tbPaginator" #tbPaginator [length]="resultsLength" [pageSize]="25" style="float: right;"
          [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>

      </div>
    </mat-card>



  </div>


</section>


<ng-template #addFileDialog>
  <app-add-edit-document (onSaved)="onSendFile($event);"></app-add-edit-document>
 
</ng-template>


<ng-template #onSetUserForSignTemplate>
  <div class="md-content-modal">

      <span class="title">Selecionar Signatário</span>
      <hr>

      <app-signer-add-edit (onSaved)="onSetUserForSign($event);" (onCancelClick)="dialog.closeAll()"></app-signer-add-edit>
      
  </div>
</ng-template>