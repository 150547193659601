<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="v-center" *ngIf="initializing == true" style="height: calc(100vh - 80px);">

    <div class="card" style="width: 430px; display: flex; padding: 15px; background-color: rgb(245, 203, 140);">

        <div *ngIf="sharedInfo.AppConfig.offLineMode == false">
            <label  style="color: rgb(202, 76, 76);">
                O PDV só funciona em modo OFF-Line !
            </label>
            <p>
                Certifique-se de que o plugin local está em execução no seu computador, e ative o modo OFF-Line.
            </p>

            <button (click)="offLineMode();" mat-raised-button color="primary" style="width: 100%;">
                Ativar Modo OFF-Line
            </button>
        </div>
        

        <label *ngIf="plugInFailure" style="color: rgb(202, 76, 76);">
            Falha na conexão com o plugin local
        </label>

        <label [innerHTML]="info"></label>

        <p *ngIf="plugInFailure">
            Certifique-se de que o plugin local está em execução no seu computador, e tente conectar novamente.
        </p>

        <button *ngIf="plugInFailure" (click)="connectPlugin();" mat-raised-button color="primary">
            Tentar Novamente
        </button>

        <div style="float: right; position: absolute; right: 15px; top: 10px;">
            <mat-spinner *ngIf="loading" [strokeWidth]="4" [diameter]=20></mat-spinner>
            <mat-icon *ngIf="plugInState == 1" style="font-size: 34px; color: rgb(0, 141, 47);">done_all</mat-icon>
        </div>


    </div>
</div>

<div *ngIf="initializing == false">


    <div style="z-index: 100; width: 100%;">


        <div class="row" (click)="setCodeFocus();">

            <div class="col-7" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                <mat-card class="card-content" style="margin: 20px; margin-right: 0px;">

                    <div class="left-icon v-center">
                        <mat-icon matSuffix class="material-icons">qr_code_scanner</mat-icon>
                    </div>

                    <div class="row">

                        <div class="col-md-9">
                            <mat-form-field class="full-width">
                                <input matInput #txtFindProd [disabled]="data.mainData.totalPayments > 0"
                                    placeholder="{{ 'order_add_edit.product_find' | translate }}"
                                    [matAutocomplete]="acbProd" [(ngModel)]="productFind" (keyup)="getProducts($event)"
                                    style="font-size: 18px;">
                            </mat-form-field>

                            <mat-autocomplete #acbProd="matAutocomplete" [displayWith]="itemFindDisplayFn"
                                (optionSelected)="productSelected($event)">
                                <mat-option *ngIf="isLoadingProd" class="is-loading">
                                    <mat-spinner diameter="30"></mat-spinner>
                                </mat-option>
                                <ng-container *ngIf="!isLoadingProd">
                                    <mat-option *ngFor="let item of filteredProducts" [value]="item">
                                        <span>{{ item.code }}</span>
                                        <span> | {{ item.name }}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'order_add_edit.quantity_insert' | translate }}</mat-label>
                                <input matInput type="numeric" #txtQuantity (keydown)="onQuantityKeyDown($event)" [disabled]="data.mainData.totalPayments > 0"
                                    currencyMask min="00" [(ngModel)]="quantityInsert" style="font-size: 18px;">
                            </mat-form-field>
                        </div>



                    </div>

                </mat-card>
            </div>

            <div class="col-5"
                [@animate]="{value:'*',params:{y:'350px',opacity:'0',delay:'100ms', duration: '1000ms'}}">

                <div style="margin-right: 40px; ">

                    <mat-card class="card-content" style="height: calc(100vh - 270px);  margin-top: 20px; ">

                        <div class="card card-white card-itens" #tableItens style="height: 100%">

                            <table class="table-itens" [dataSource]="itensDataSource" mat-table>

                                <ng-container matColumnDef="productCode">
                                    <th mat-header-cell *matHeaderCellDef disableClear> Código </th>
                                    <td mat-cell *matCellDef="let row" [@myfadeIn] [ngStyle]="{'background-color': row.removed == true ? 'rgba(255, 99, 71, 0.1)' : ''}">
                                        <span>{{row.productCode}}<br><b>{{row.number}}</b></span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="productDescription">
                                    <th mat-header-cell *matHeaderCellDef disableClear> Descrição do Produto </th>
                                    <td mat-cell *matCellDef="let row" [@myfadeIn] [ngStyle]="{'background-color': row.removed == true ? 'rgba(255, 99, 71, 0.1)' : ''}">
                                        {{row.productDescription}}<br>
                                        <span style="float: right;"  [ngStyle]="{'text-decoration': row.removed == true ? 'line-through' : 'none' }">
                                            {{row.quantity}} X {{row.unitValue | currency: 'BRL'}} =
                                            {{row.totalValue | currency: 'BRL'}}
                                        </span>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="cmd">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let row" [ngStyle]="{'background-color': row.removed == true ? 'rgba(255, 99, 71, 0.1)' : ''}">
                                        <a *ngIf="(!data.mainData.statusDelivery || data.mainData.statusDelivery < 10) && !row.removed && data.mainData.totalPayments <= 0" [@myfadeIn]
                                            (click)="$event.stopPropagation(); confirmDeleteItem(row);" class="btn_del_item">
                                            <i class="material-icons">delete_forever</i>
                                        </a>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="itensColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: itensColumns;" class="element-row" [ngStyle]="{'text-decoration': row.removed == true ? 'line-through' : 'none' }">
                                </tr>

                            </table>

                        </div>

                    </mat-card>

                    <mat-card class="card-content" style="height: 130px; margin-top: 20px;" *ngIf="data != null">

                        <div class="left-icon v-center" style="background-color:rgb(247, 185, 92)">
                            <mat-icon matSuffix class="material-icons">calculate</mat-icon>
                        </div>

                        <label class="subTotalLabel">
                            Sub. Total<br>
                            <b>{{ data?.mainData?.subTotalValue | currency: 'BRL' }}</b>
                        </label>

                        <div style="float: right;">
                            <label class="discountLabel">
                                Desconto ( CTRL + D )<br>
                                <b>{{ data?.mainData?.discountValue | currency: 'BRL' }}</b>
                            </label>
                            <br>
                            <label class="totalLabel">
                                {{ data?.mainData?.totalValue | currency: 'BRL' }}
                            </label>
                        </div>

                    </mat-card>

                </div>

            </div>
        </div>


    </div>


    <div [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}" style="height: calc(100vh - 220px); 
width: 100%;
position:absolute;
top: 200px;
right: 20px;
z-index: -1;">


        <mat-card class="card-content" style="height: 100%; margin-left: 40px;">

            <!-- images -->

            <div class="row" style="height: 100%; padding-bottom: 80px">
                <div class="col-7" *ngIf="data.mainData.totalPayments <= 0">
                    <img src="assets/img/pdv/02.jpg" alt="" style="width: 100%; height: calc(100vh - 310px)">
                </div>

                <div class="col-7" *ngIf="data.mainData.totalPayments > 0" style="padding-top: 20px;" >

                    <div style="display: grid;">

                        <div *ngFor="let item of data.payments" class="payment-item">
                    
                            <div style="display: flex;">
                                <mat-icon matSuffix class="material-icons icon-payment">
                                    {{ item.paymentType | paymentTypeIcon }}
                                </mat-icon>
                                <div style="padding: 4px 0;">
                                    <span class="totalPayedLabel">{{item.paymentType | paymentType }}</span>
                                </div>
                            </div>


                            <span class="valuelabel">{{ item.value | currency: 'BRL' }}</span>
                        
                        </div>


                        <div class="payment-item" style=" border-bottom: 0px;">
                            <div></div>
                            <span class="valuelabel" style="font-size: 22px;">
                                <span style="font-size: 16px; font-weight: normal; margin-right: 10px;">Valor Pago:</span>
                                {{ data.mainData.totalPayments | currency: 'BRL' }}
                            </span>
                        </div>

                        <div class="payment-item" style=" border-bottom: 0px;" *ngIf="data.mainData.payDevolutionValue > 0">
                            <div></div>
                            <span class="valuelabel" style="font-size: 22px;  color: brown;">
                                <span style="font-size: 16px; font-weight: normal; margin-right: 10px; color: brown;">Valor do Troco:</span>
                                {{ data.mainData.payDevolutionValue | currency: 'BRL' }}
                            </span>
                        </div>



                    </div>


                </div>
            </div>


            

            <div  class="card-info" >
                <div class="col-7" style="text-align: center; display: grid;" >
                    <label class="infoLabel">{{ info }}</label>
                    <label *ngIf="data.mainData.payed == true">* TECLAR ENTER PARA FECHAR A VENDA</label>
                </div>  
            </div>

        </mat-card>

    </div>


</div>








<ng-template #setInvoiceDialog>
    <div class="setStatusDialog">

        <span class="title">Fechar Venda</span>
        <hr>

        <div class="row">

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'order_add_edit.person_billed' | translate }}</mat-label>
                    <input matInput required #txtFindClient [matAutocomplete]="auto" [(ngModel)]="personBilled"
                        (keyup)="getClients($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn"
                    (optionSelected)="personBilledSelected($event)">
                    <mat-option *ngIf="isLoadingClients" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let item of filteredClients" [value]="item">
                            <span>{{ item.cnpjcpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>


            <div class="col-md-5">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'order_add_edit.total_installments' | translate }}</mat-label>
                    <input matInput type="text" currencyMask min="1" [options]="{ align: 'left', precision: 0 }"
                        [(ngModel)]="data.invoideCmd.totalInstallments" style="color: rgb(0, 37, 138);">
                </mat-form-field>
            </div>


            <div class="col-md-7">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'order_add_edit.firstDueDate' | translate }}</mat-label>
                    <input matInput [matDatepicker]="firstDueDate" [(ngModel)]="data.invoideCmd.firstDueDate">
                    <mat-datepicker-toggle matSuffix [for]="firstDueDate"></mat-datepicker-toggle>
                    <mat-datepicker #firstDueDate></mat-datepicker>
                </mat-form-field>
            </div>

        </div>



        <button mat-raised-button class="btn btn-rounded ladda-button m-1" color="accent" style="float: right;"
            [disabled]="loading || data.mainData.statusDelivery > 10" (click)="saveOrderLocal()">
            Faturar <i class="material-icons">save</i>
        </button>
    </div>
</ng-template>