<div class="app-header">

  <!-- <div class="top-nav-left d-none d-lg-inline-block d-xl-inline-block">
    <ul class="list-unstyled list-inline">
      <li class="list-inline-item"><button mat-button class="md-button header-btn" myOpenSearchOverlay><i class="material-icons">search</i></button></li>
      <li class="list-inline-item">
        <button mat-button class="md-button header-btn" [matMenuTriggerFor]="appNotification">
          <i class="material-icons">notifications_none</i>
          <span class="badge">3</span>
        </button>
        <mat-menu #appNotification="matMenu" x-position="after" y-position="below" overlapTrigger="false">
          <mat-list dense>
            <mat-list-item>
              <mat-icon mat-list-avatar>mail_outline</mat-icon>
              <h4 mat-line>New mail from David</h4>
              <p mat-line class="text-muted">5 minutes ago</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-avatar>chat_bubble_outline</mat-icon>
              <h4 mat-line>Message from Jane</h4>
              <p mat-line class="text-muted">15 minutes ago</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-avatar>person_outline</mat-icon>
              <h4 mat-line>New member John joined</h4>
              <p mat-line class="text-muted">1 hour ago</p>
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </li>
    </ul>
  </div> -->

  <div class="top-nav-right">
    <ul class="list-unstyled float-right">

      <!-- <li class="list-inline-item v-center">
        <mat-form-field class="full-width" style="height: 60px;" appearance="none">
          <mat-select [(ngModel)]="selectedUnitId" (selectionChange)="selectUnity($event)" style=" font-size: 14px;">
            <mat-option *ngFor="let item of unities" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li> -->

      <li class="list-inline-item v-center">
        <button mat-icon-button [matMenuTriggerFor]="appUserMenu" *ngIf="userService.userLoged">
          <mat-icon *ngIf="userService.userLoged?.avatarUrl == '' || userService.userLoged?.avatarUrl == null"
            mat-list-avatar>person</mat-icon>
          <img *ngIf="userService.userLoged?.avatarUrl !== ''" src="{{userService.userLoged?.avatarUrl}}" alt=""
            class="rounded img30_30">
        </button>

        <mat-menu #appUserMenu="matMenu" x-position="after" y-position="below" overlapTrigger="false">
          <mat-list dense style="padding-right: 20px;">

            <mat-list-item>
              <mat-icon mat-list-avatar>person_outline</mat-icon>
              <h4 mat-line>{{ userService.userLoged?.name }}</h4>
              <p mat-line class="text-muted">Meu Perfil</p>
            </mat-list-item>

            <mat-list-item (click)="signout()">
              <mat-icon mat-list-avatar>exit_to_app</mat-icon>
              <h4 mat-line>Sair</h4>
            </mat-list-item>


          </mat-list>
        </mat-menu>
      </li>

      <!-- <li><button mat-button class="md-button header-btn"><i class="material-icons">more_vert</i></button></li> -->
    </ul>
  </div>

</div>