<!-- <h2 class="addEdit-title ">{{title}}</h2> -->

<div class="card card-white" [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <mat-card class="card-content">

        <div class="card-body">

            <div class="col-md-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{ 'taskprocess_add_edit.client' | translate }}</mat-label>
                    <input matInput required #txtFindClient [matAutocomplete]="auto" [(ngModel)]="data.personResume"
                        (keyup)="getClients($event)">
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn"
                    (optionSelected)="clientSelected($event)">
                    <mat-option *ngIf="isLoadingClients" class="is-loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let item of filteredClients" [value]="item">
                            <span>{{ item.cnpjcpf }}</span>
                            <span> | {{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </div>


            <div class="col-md-6">
                <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>{{ 'taskprocess_add_edit.title' | translate }}</mat-label>
                    <input required matInput type="text" [(ngModel)]="data.name" [errorStateMatcher]="MatcherName">
                    <mat-error>
                        {{ formErrors | findErrors: 'name'}}
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'taskprocess_add_edit.firstDateOfTask' | translate }}</mat-label>

                    <input matInput [ngxMatDatetimePicker]="picker"  [(ngModel)]="data.firstDateOfTask" [errorStateMatcher]="MatcherFirstDateOfTask">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker>
                    </ngx-mat-datetime-picker>
                    <mat-error>
                        {{ formErrors | findErrors: 'firstDateOfTask'}}
                    </mat-error>
                  </mat-form-field>
            </div>

            <div class="app-modal-footer text-right" *ngIf="deviceService.isMobile() == false">
                <button type="button" class="btn btn-default btn-rounded m-1 " (click)="close()">Cancelar</button>
                <button type="button" class="btn btn-primary btn-rounded ladda-button m-1" #btnOk [disabled]="loading"
                    (click)="submit();">Salvar</button>
            </div>


        </div>

    </mat-card>
</div>